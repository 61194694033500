@import './mixins/global-mixins';

.notification-trigger {
    display: inline-block;
}

.notification-tray * {
    color: rgb(88, 88, 88);
}

.notification-tray .nft-top {
    padding: 0.5rem 0.9em;
}
.notification-tray .mb-0 {
    color: #48494abe;
    font-weight: 600;
    font-size: 1.1rem;
}
.notification-tray .nft-count {
    display: inline-block;
    padding: 10px 5.5px;
    min-width: 10px;
    height: 19px;
    background-color: #b6c2ccad;
    border-radius: 12px;
    line-height: 0rem;
    font-size: 0.9rem;
}
.notification-tray.extended-dropdown {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    width: 100vw;
    max-width: 370px;
    padding: 0rem;
    background-color: #ffffffd6;
    overflow: hidden;
}
.notification-tray.extended-dropdown .extended-dropdown__section--list {
    padding: 0rem 0.4rem;
}
.notification-tray .notification-list {
    box-shadow: none;
    flex: 1 1 auto;
    max-height: 350px;
    padding: 0;
    overflow-y: auto;
    
}
.notification-tray .notification-list .notification {
    border: 1px solid #f2f2f3;
    border-radius: 0.6rem;
    margin: 0.2rem auto;
    background-color: #f9f9f9f0;
    padding: 0.9rem 0.8rem;
    position: relative;
    @include disable-user-select;
}
.notification-tray .ntf-empty {
    text-align: center;
    padding: 4rem 2rem;
    pointer-events: none;
    @include disable-user-select;
}
.notification-tray .ntf-empty .icon {
    display: inline-block;
    width: 4rem;
    pointer-events: none;
}
.notification-tray .ntf-empty p {
    color: #696b6e7a;
    font-size: 1.3rem;
}

.notification-tray .notification-list .notification .dismiss-ovrly {
    position: absolute;
    height: 100%;
    top: 0px;
    bottom: 0px;
    right: 0px;
    font-weight: 500;
    text-align: center;
    display: block;
    font-size: 0.9rem;
    padding: 0rem 0.8rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}
.notification-tray .notification-list .notification .dismiss-ovrly a{
    position: absolute;
    bottom: 8px;
    right: 0.8rem;
    color: #e20000;
}
.notification-tray .notification-list .notification .dismiss-ovrly a:active{
    color: #e2000086;
}
.notification-tray .notification-list .notification:hover .dismiss-ovrly {
    visibility: visible;
    opacity: 1;
}

.notification-tray .notification-list .notification:hover {
    background-color: #f3f5f7;
}
.notification-tray .notification-list .notification-cnt .content{
    padding-bottom: 0.4rem;
    transition: padding-bottom 0.2s linear;
    -o-transition: padding-bottom 0.2s linear;
    -moz-transition: padding-bottom 0.2s linear;
    -webkit-transition: padding-bottom 0.2s linear;
}
.notification-tray .notification-list .notification.expd:hover  .content{
    padding-bottom: 1.3rem;
}
.notification-tray .notification-list .notification-cnt .top{
    margin-bottom: 0.2rem;
}
.notification-tray .notification-list .notification-cnt .top .time-elapse {
    font-size: 0.9rem;
    color: #949494;
}
.notification-tray .notification-list .notification-cnt .top .typ{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    color: #b5bac2;
}
.notification-tray .notification-list .notification-cnt .title{
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0rem !important;
}
.notification-tray .notification-list .notification-cnt .ntf-text{
    color: #6a6d72;
}

.notification-tray .tray-footer {
    padding: 0.5rem 0.5rem;
    color: #949494;
    position: relative;
    background: linear-gradient(0deg, #fff, #fff, #ffffff00);
}
.notification-tray .tray-footer .clr-all-icon{
    display: inline-block;
    width: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    opacity: 0.4;
}
.notification-tray .tray-footer .clr-all-icon:hover {
    opacity: 0.7;
}