.progress-line {
  position: absolute;
  width:100%;
  height:5px;
  overflow-x: hidden;
  z-index: 6;
  opacity: 1;
  
  .progress-line.--hiden {
    opacity: 0;
    transition: all ease 0.2s;
  }
}

.line {
  position:absolute;
  opacity: 0.4;
  background:#4a8df8;
  width:150%;
  height:5px;
}
.progress-line.white-bar .subline{
  background:#ffffff79;
}
.progress-line.white-bar .line{
  background:#ffffff6b;
}
.subline{
  position:absolute;
  background:#4a8df8;
  height:5px; 
}
.inc{
  animation: increase 2s infinite;
}
.dec{
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
   from { left: -5%; width: 5%; }
   to { left: 130%; width: 100%;}
}
@keyframes decrease {
   from { left: -80%; width: 80%; }
   to { left: 110%; width: 10%;}
}

.loader-wrap2 {
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  text-align: center;
  background: linear-gradient(#ffe5b000, #ffe5b086, #ffe5b0);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in;
}
.loader-wrap.--visible {
  visibility: visible;
  opacity:  1;
}

.loader-wrap {
  display: flex;
  padding: 0rem 0rem;
  align-items: center;
  justify-items: center;
  width: fit-content;
  margin: 0.5rem auto 0.5rem auto;
  visibility: hidden;
  opacity: 0;
}

.loader-wrap .text {
  color: #535353;
  font-weight: 500;
  line-height: normal;
  font-size: 1rem;
  margin-top: 0.4rem;
  margin-left: 0.5rem;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
  -o-transform: scale(0.4);
  -moz-transform: scale(0.4);
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #fa8b0c;
  margin: -6px 0 0 -6px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
