.select-container {
  position: relative;
  width: auto;
  font-size: 1.2rem;
  user-select: none;

  .select-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 3px;
    background-color: white;
    line-height: 38px;
    cursor: default;
    cursor: pointer;

    span.icon {
      display: flex;
      margin: 0;
      padding: 4px 16px; } }

  .select-placeholder {
    width: 100%; }

  .selected-option.--default {
    width: 100%;
    padding-left: 1rem;
    color: #6f6f6f;
    padding: 0.3rem 1rem; }

  .select-placeholder-title {
    margin: 0px 20px;
    margin-right: 30px;
    font-weight: 400;
    color: #6f6f6f;
    padding: 0.3rem 0rem; }

  .angle-down {
    margin-right: 20px;
    color: black; }

  .options--default {
    position: absolute;
    z-index: 10;
    width: 100%;
    max-height: 19rem;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    background-color: white;
    font-weight: 400;
    text-align: left;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .select_options--default {
      overflow-y: auto;
      padding: 0px 0;

      .template-option {
        display: block;
        overflow: hidden;
        width: 100%;
        padding: 8px 10px;
        font-size: 1.2rem;
        line-height: 1.6rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: default;
        cursor: pointer;
        margin: 0rem;
        position: relative;
        color: #6f6f6f;

        &.no-result {
          font-weight: normal;
          cursor: default;
          padding-left: 1rem !important;
          padding-bottom: 1rem !important;
          color: #545454;

          &:hover {
            background-color: transparent;
            color: black; } }

        &:hover {
          background-color: #f1f1f1;
          color: #000;

          & > span > svg > path {
            fill: white; } }

        .selected-marker {
          position: absolute;
          right: 6px;
          top: 1px;
          height: 100%;

          svg {
            opacity: 0.3;
            height: inherit;
            vertical-align: middle; } } } }


    .search-component {
      padding: 0.2rem 0.5rem;
      width: 100%;
      left: 0;
      top: 0;
      right: 0;
      display: block;
      position: sticky;
      z-index: 2; }

    .search-filter-field {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      border: none;
      margin: 0.2rem auto;
      border-bottom: 1px solid #DFDFDF;
      font-size: 1.1rem;

      &::placeholder {
        color: rgb(200, 200, 200); } }

    &.searchable {
      overflow-y: scroll;
      padding: 0;

      .select_options--default {
        padding: 2px 0; } } } }

.select-container.--multi {
  display: block;
  border: 1px solid #DFDFE1;
  border-radius: 0.6rem;

  .select-control {
    border: none;
    width: 100%;
    border-radius: 0.6rem;
    padding: 0.1rem 0.5rem;
    text-align: left; }

  .options--default {
    overflow: hidden;
    border-radius: 0.5rem;
    border: 1px solid #DFDFE1;

    .default-option {
      display: block;
      width: 100%;
      text-align: left;
      padding: 0.4rem 0.8rem;
      background: transparent;
      position: relative;
      border-radius: 0.2rem;

      .selected-marker {
        position: absolute;
        top: 0.3rem;
        right: 1rem;
        opacity: 0.6; } }

    .default-option.--select {
      background: #f9f9f9; }

    .default-option:hover {
      background: #f5f5f5; } } }


