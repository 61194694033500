@import "./../../assets/styles/mixins/global-mixins";
.roles-inner {
    margin-top: 1rem;
    padding: 0rem 1rem;
}
.roles-section .title{
    color: #8b8b8b;
    width: 10rem;
    margin: auto;
    margin-top: 2rem;
}
.role-create button {
    border: 1px solid rgb(231, 231, 231) !important;
    padding: 2rem 3.7rem;
    border-radius: 2rem;
    background-color: #e9e9e9;
    color: #8b8b8b !important;
    margin: 0rem auto;
    display: block;
}
.role-create button .icon{
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0.4rem auto;
    opacity: 0.4;
}
.role-create button:hover {
    background-color: #e2e2e2;
}
.role-create button:focus {
    background: #dddddd !important;
}
.roles-wrapper {
    margin-top: 2rem;
}
.roles-wrapper .roles-list{
    list-style: none;
    margin: 0rem;
    padding: 0rem;
}
.roles-wrapper .role-item{
    border: 1px solid rgb(221, 221, 221);
    margin: 0.8rem auto;
    
    border-radius: 1rem;
    width: 13rem;
    position: relative;
    @include disable-user-select()
}
.roles-wrapper .role-item * {
    display: block;
}
.roles-wrapper .role-item .interactor {
    padding: 1.3rem 0.4rem;
}
.roles-wrapper .role-item:hover,
.roles-wrapper .role-item.super-role:hover,
.roles-wrapper .role-item.selected,
.roles-wrapper .role-item.super-role.selected {
    border: 1px solid #39aaff;
    background: #e3f5ff;
}

.roles-wrapper .role-item:hover,
.roles-wrapper .role-item.super-role:hover{
    border: 1px solid #e3f5ff;
    background: #eaf8ff;
}

.roles-wrapper .role-item .icon{
    width: 2.2rem;
    height: 2.2rem;
    display: block;
    margin: 0rem auto 0.5rem auto;
    opacity: 0.4;
    pointer-events: none;
}
.roles-content::before {
    width: 1px;
    height: 100%;
    content: "";
    position: absolute;
    background-color: #dddddd;
    display: inline-block;
    left: -2.5rem; 
}
.roles-content .lock-icon {
    width: 1.5rem;
    position: absolute;
    right: 0;
    top: 2rem;
    pointer-events: none;
    opacity: 0.3;
}
.roles-wrapper .role-item.super-role  { 
    background: #f3f3f3;
    border: 1px solid rgb(233, 233, 233);
}
.roles-wrapper .role-item.super-role .key { 
    width: 1.6rem;
    display: inline-block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    opacity: 0.5;
}
.roles-wrapper .role-item .label, .roles-wrapper .role-item .type{ 
   text-align: center;
}
.roles-wrapper .role-item .label{ 
    font-size: 1rem;
    text-overflow: ellipsis;
 }
.roles-wrapper .role-item .type{ 
    font-size: 0.9rem;
    color: #8b8b8b;
    font-weight: 500;
}
.roles-content {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.roles-content form {
    padding-top: 2rem;
}
.roles-content form #role-name {
    font-size: 3.5rem;
    color: #6e6e6e;
    width: auto !important;
    border:none;
    border-bottom: 2px solid transparent;
    outline: none;
    background: transparent;
    display: inline-block;
    cursor: text !important;
    min-width: 0.5rem;
}
.roles-content form #role-name:focus {
    border-bottom: 2px solid #54a4ff;
    color: #494949;
}
.roles-content form .form-group-label {
    color: #6B6B6B;
    font-size: 1.5rem;
    margin-top: 1rem;
}

/* Role Type Selection View*/
.role-chooser-wrap {
    margin-top: 0rem;
    position: relative;
}
.role-chooser-wrap .role-chooser-content {
    margin-top: 4rem;
}
.role-chooser {
    padding: 3rem;
}
.role-chooser-content .title {
    text-align: center;
}
.role-chooser .roles-type-selection {
    text-align: center;
    margin-bottom: 2rem;
}
.role-chooser .roles-type-selection li {
    display: inline-block;
    margin: 0.5rem 0.5rem;
}
.role-chooser .roles-type-selection li .trigger {
    border-radius: 1rem;
    display: block;
    border: 1px solid rgb(219, 219, 219);
    padding: 2rem 1rem;
    width: 8.9rem;
    color: #3b4a57;
    cursor: pointer;
    @include disable-user-select()

}
.role-chooser .roles-type-selection li .trigger:hover {
    border: 1px solid #39aaff;
    background: #e3f5ff;
    
}
.role-chooser .roles-type-selection li .trigger .icon-holder{
   display: block;
   text-align: center;
}
.role-chooser .roles-type-selection li .trigger .icon{
    width: 3rem;
    height: 3rem;
    display: block; 
    margin: 0rem auto;
    pointer-events: none;
}
.role-chooser .roles-type-selection li  .or-text{
    color: #6e6e6e;
    position: relative;
    top: -1.2rem;
 }
.role-chooser  .info-well * {
    text-align: center;
}

.permission-section {
    overflow: hidden;
    white-space: nowrap;
}
.permission-selector-widget .selector {
    width: 380px;
    border-radius: 1rem;
    float: left;
}
.permission-selector-widget .selector .head h2{
    font-size: 1rem;
    padding: 0.6rem 1.5rem;
    margin: 0;
    font-weight: 400;
    color: #6B6B6B;
    text-align: center;
}
.permission-selector-widget .selector .head{
    background: #F9F9F9;
    border-radius: 1rem 1rem 0rem 0rem;
    border: 1px solid #dddddd;
    border-bottom: none;
}
.permission-selector-widget .selector-chosen .head {
    border-color:#3F97EF;
    background : #3F97EF;
}
.permission-selector-widget .selector-chosen .head h2 {
    color: #fff;
}
.permission-selector-widget .selector-filter {
    border-bottom: 1px solid #dddddd;
}
.permission-selector-widget .selector-wrapper {
    border: 1px solid #dddddd;
    border-radius: 0rem 0rem 1rem 1rem;
}
.permission-selector-widget .selector-filter .form-group {
    margin: 0.5rem 0rem;
    position: relative;
    padding: 0rem 0.4rem
}
.selector-filter .filter-group .icon {
    width: 1rem;
    display: inline-block;
    position: absolute;
    left: 1.2rem;
    top: 0.3rem;
    opacity: 0.4;
    pointer-events: none;
}
.selector-filter .filter-group .icon:after {
    width: 1px;
    height: 1rem;
    content: "";
    display: block;
    position: absolute;
    right: -0.4rem;
    top: 0.3rem;
    opacity: 1;
    background-color: #868686;
}
select option  {
    padding: 0.2rem .4rem;
    border-radius: 4px;
}
.permission-selector-widget .selector-filter .form-group input.filter {
    margin: 0.5rem 0rem;
    padding: 0.3rem 1rem 0.3rem 2.6rem;
}
.permission-selector-widget .selector select {
    width: 380px;
    height: 18em;
    margin: 0px;
    border-radius: 0 0 4px 4px;
    padding: 7px 10px 0px 10px;
    background-color: transparent;
    display: block;
    border: 1px solid transparent;
    outline-color:  rgba(69, 170, 252, 0.541);
    outline-width: thin;
    outline: none;
}
.permission-selector-widget .selector select:active {
    border-radius: 1rem;
}
.permission-selector-widget .selector select option {
    overflow: hidden;
    text-overflow: ellipsis;
}
.permission-selector-widget .selector-chosen select {
    height: calc(55px + 18em) !important;
}
.permission-selector-widget ul.selector-chooser {
    float: left;
    width: 27px;
    height: fit-content;
    background-color: #eee;
    border-radius: 18px;
    margin: 10.5em 5px 0 5px;
    padding: 0;
    list-style: none;
}
.permission-selector-widget ul li {
    padding: 0.3rem 0rem;
}
.permission-section .selector-button {
   width: 1.2rem;
   height: 1.2rem; 
   padding: 0.2rem;
   border-radius: 50%;
   line-height: 0rem;
   margin: 0rem auto;
   display: block;
}

.permission-section .selector-button img {
    pointer-events: none;
}

.permission-section .selector-button {
    background-color: rgb(180, 180, 180);
}

.permission-section .selector-button.remove {
    transform: rotate(180deg);
}
.permission-selector-widget .action-links {
    text-align: center;
    border: none;
}
.permission-selector-widget .nav-link {
    line-height: 0rem;
    text-align: center;
    margin-top: 0.6rem;
    font-weight: 500;
    display: inline-block;
    width: fit-content;
    padding:0.2rem 0.2rem ;
    margin: 0.6rem auto 0rem auto;
    @include disable-user-select();
}
.permission-selector-widget .nav-link[disabled] {
    opacity: 0.5;
}
.permission-selector-widget .nav-link .icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50%;
    background-color: #7091B1;
    padding: 0.2rem;
    margin-left: 0.5rem;
}
.permission-selector-widget .nav-link.remove-all .icon {
    transform: rotate(180deg);
}
.roles-content .ip-checkbox {
    font-size: 1.1rem;
}
.info-text {
    font-size: .8rem;
    color: #8b8b8b;
}
@media (max-width: 1385px) {
    .permission-section {
        width: 100%;
        overflow: unset;
    }
    .permission-selector-widget {
        display: flex;
        width: 100%;
    }
    .permission-selector-widget .selector {
        width: 50%;
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
    }
    .permission-selector-widget .selector select {
        width: 100%;
        flex: 1 0 auto;
        margin-bottom: 0px;
    }
}

@media (max-width: 991px) {
    .roles-inner {
        display: flex;
        justify-items: flex-start;
        align-items: flex-start;
    } 
    .role-create {
        margin-right: 1rem;
    }
    .role-create button {
        margin: 0rem;
    }
    .roles-wrapper .role-item {
        margin: 0rem 0.4rem;
        float: left;
    }
    .roles-section .title {
        margin: 1rem 0rem 0rem 0rem;
        display: block;
    }
    .roles-wrapper {
        margin-top: 0rem;
    }
    .roles-content::before {
        width: 100%;
        height: 1px;
        left: 0rem;
    }
}