.content-layout-wrap.master-detail.account .aside-list {
    flex: 0 0 calc(100% - 30rem);
    width: calc(100% - 30rem);
}
.content-layout-wrap.master-detail.account .detail-view {
    flex: 0 0 30em;
    overflow-y: scroll;
    padding-bottom: 0rem;
}
.acct-summary .title {
    font-weight: 500;
}
.accounts-table.table thead th:first-child {
    width: auto;
}
.accounts-table tbody .selection-cell {
    padding: 0.8rem 0.4rem !important;
}
.accounts-table.table thead th:not(th:first-child), 
.accounts-table.table tbody td:not(td:first-child) {
    width: 20%;
}
.accounts-table.table tbody td h3{
    font-size: 0.9rem;
}
.accounts-table .info{
    font-size: 0.9rem;
    color: #7a7a7a;
}
.accounts-table  .highlight{
    color: #3693fd;
}
.id-details-cell .highlight{
    font-weight: 500;
}
.account .cnt-inner {
    padding: 1rem 2rem;
}
.detail-view.acct-summary .section {
    margin-top: 1.5rem;
}
.detail-view.acct-summary .section h2 {
    text-transform: uppercase;
    font-size: 1rem;
}

.detail-view.acct-summary .sec-content .info-line  {
    border-bottom: 1px solid #f5f5f5;
    font-size: 1rem;
    margin-left: 0rem;
}
.detail-view.acct-summary .sec-content .info-line .label {
    flex: 0 0 50%;
}

.account .cnt-inner .close-act {
    margin-top: 5rem;
    font-size: 0.9rem;
    color: #888888 !important;
}

.account a.arrow-link.close-act .arrow-indicator {
    border-color: #888888 !important;
}
.account .cnt-inner .close-act {
    margin-top: 4rem;
    font-size: 0.9rem;
    color: #888888 !important;
    display: block;
}

.account a.arrow-link.close-act:hover .arrow-indicator,
.account .cnt-inner .close-act:hover {
    border-color: #f83a00 !important;
    color: #f83a00 !important;
    
}
.account.content-wrap {
    margin: 3rem auto 2rem auto;
    width: 80%;
}

.account.content-wrap  .loader-wrap {
    margin: 30% auto;
}
.account.content-wrap  .loader-wrap .lds-roller {
    

}