@import "./../../assets/styles/mixins/global-mixins";
.widget-section {
    padding-top: 1.2rem;
}
.widget-section .add-btn .icon {
    width: 1rem;
    display: inline-block;
    margin-right: 0.6rem;
    pointer-events: none;
    position: relative;
    bottom: 0.1rem;
}
.widget-section .add-btn .patient-icon {
    width: 0.8rem;
}
.widget-section .add-btn  {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: normal;
    display: inherit;
    margin: 0rem auto;
}
.content-section {
    margin-top: 1rem;
}
.users .content-section {
    margin-top: 1.5rem;
}
.tab-content.create-user{
    background-color: #fff;
    border-radius: 0px 4px 4px 4px;
    border: 1px solid #efefef;
    border-top: none;
    padding: 1rem 0rem;
    margin-bottom: 2rem;
}

.tab-content .section {
    padding: 0rem 0.2rem
}
.tab-content .section .section-body{
    padding: 0rem 0.5rem;
}
.tab-content .section .section-head {
    border-bottom: 1px solid #f1f1f1;
}

.tab-content .section .section-head.simple {
    border-bottom: none;
}
.tab-content .section .section-head.simple,
.tab-content .section .section-head.simple h3 {
    border: none;
}
.tab-content .section .section-head h3 {
    font-size: 1.3rem;
    font-weight: 400;
    display: inline-block;
    border-bottom: 2px solid #f1f1f1;
    margin: 0rem;
    padding-right: 2rem;
    padding-left: 0.6rem;
    padding-bottom: 0.2rem;
}
.nav-tabs .nav-item .nav-link {
    padding: 0.7rem 2rem;
}
.users .nav-tabs .nav-item .nav-link.active {
    //border-bottom: 2px solid #0786db;
}

.nav-tabs .nav-item .icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    float: left;
    position: relative;
    margin-right: 0.5rem;
    bottom: 0.2rem;
    opacity: 0.8;
    pointer-events: none;
}
.section-inner {
   padding: 0rem 1.4rem;  
}
.section-body {
    margin-top: 1.4rem;
}

.lock-icon {
    position: absolute;
    width: 1.5rem;
    right: 2rem;
    top: 1rem;
    opacity: 0.3;
    pointer-events: none;
}

/*User Details*/
.image-thumbnail-box {
    margin-top: 1rem;
}
.image-thumbnail-box  div {
    width: 10.6em;
    height: 10.6em;
    position: relative;
}
.image-thumbnail-box .image-selector {
    display: table-cell;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 50%;
    border: 1px solid #f3f3f3;
}
.image-thumbnail-box .image-selector:hover {
    background-color: #f1f1f1;
}
.image-thumbnail-box .image-selector * {
    border-radius: 50%;
}
.image-selector img {
    position: absolute;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    max-height: 10.6em;
    max-width: 10.6em;
    width: inherit;
    height: 100%;
    left: 0;
    right: 0;
    display: inline;
    text-align: center;
    vertical-align: middle;
    border-radius: 1px;
    object-fit: cover;
}

.image-thumbnail-box button {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    text-transform: none;
    letter-spacing: normal;
    color: #b3b3b3;
    font-size: 0.8em;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: transparent;
    background-size: 25%;
    background-position-y: 3.2em;
    padding-top: 2.6em;
    font-weight: 500;
    border: none;
}

.image-thumbnail-box button span {
    display: inline-block;
    border-radius: 0.2rem !important;
    padding: 0.1rem 0.5rem;
    line-height: 1rem;
    background-color: #ffffff36;
    position: relative;
    top: 3rem;
}
.image-thumbnail-box  input {
    border-width: 0;
    direction: ltr;
    cursor: pointer;
    width: inherit;
    height: inherit;
    opacity: 0;
    z-index: 9;
}

.image-thumbnail-box.signature .image-selector {
    display: table-cell;
    vertical-align: middle;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #464646;
    border-radius: 0.1rem;
    
}

.image-thumbnail-box.signature div {
    width: 20rem;   
}
 
.image-thumbnail-box.signature  .image-selector img {
    max-width: 20rem;
    min-width: 20rem;
    object-fit: contain;
    opacity: 0.6;
    -webkit-transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
}
.image-thumbnail-box.signature .image-selector:hover img{
    opacity: 1;
}

.image-thumbnail-box.signature .image-selector * {
    border-radius: 0px;
}
.username-field {
    position: relative;
}
.username-field span {
    position: absolute;
    top: 0rem;
    right: 2rem;
    bottom: 0rem;
    text-align: center;
    display: table-cell;
    font-size: 1rem;
    padding: 1rem 0rem;
    vertical-align: center;
    line-height: normal;
    color: #929292;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;     
}
.username-field span i {
    color: #d3d3d3;
    font-style: normal;
    margin-right: 0.5rem; 
}
#roles-pane .section-head h3 {
    padding-left: 2rem;
    font-size: 1.2rem;
    color: #a0a0a0;
}
.role-well {
    margin: 0rem 1rem 1rem 1rem;
}
.role-well .role-well-container{
    padding: 0.8rem;
    margin-bottom: 1rem;
}
.role-well .role-well-container .no-roles{
    text-align: center;
    color: #6e6e6e;
    font-size: 1.4rem;
    background-color: #f8f8f8;
    line-height: normal;
    padding: 1.5rem 1rem;
    border-radius: 0.6rem;
}
.role-well .role-well-container .no-roles p{
    margin: 0;
}
.role-well .role-well-container .nav-link{
    padding: 0rem;
}
.role-well .role-well-container.holder {
    border: 2px dashed rgb(233, 233, 233);
    padding: 0.8rem;
    border-radius: 1rem;
}
.role-well-container .role-item {
    padding: 0.8rem;
    background-color: #f3f3f3;
    border-radius: 0.5rem;
    margin: 0.5rem 0rem;
    display: flex;
    cursor: pointer;
    @include disable-user-select();
}
.role-well-container .role-item.selected {
    background-color: #ecf6fc;
}
.role-well-container.holder .unassign{
    display: block;
    text-align: center;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    padding: 0.5rem;
    color:#c5c5c5;
}
.role-well .role-well-container.holder.removed{
    border-color:#ff6464;
}

.role-well-container.holder .role-item {
    margin: 0rem; 
    position: relative;
}
.role-well-container .role-item .role-icon {
    display: inline-block;
    flex: 0px 0px 90%;
}
.role-well-container .role-item .role-icon img {
    display: inline-block;
    width: 2rem;
    margin-left: 1rem;
    pointer-events: none;
}
.role-well-container.holder .role-item .remove-button{
    display: none;
    position: absolute;
    right: 1rem;
    top: 0.9;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    line-height: none;
    padding: 0.2rem;
    background-color: transparent;
    opacity: 0.8;
}
.role-well-container.holder .role-item:hover .remove-button {
    display: block;
}
.role-well-container.holder .role-item .remove-button:hover{
    opacity: 1;
}

.role-well-container.holder .role-item .remove-button img{
    width: 1.2rem;
    height: 1.2rem;
    text-align: center;
    display: block;
    margin: 0rem auto;
    pointer-events: none;
}

.role-well-container .role-item .role-icon p {
    display: inline;
    margin: 0rem 1rem;
}
.role-well-container .role-item .role-info {
    display: inline-block;
    font-size: 0.9rem;
    color: #a0a0a0;
}
.role-well-container .role-item:hover {
    background-color: #f7f7f7;
}
.react-bootstrap-table .table-responsive {
    overflow: unset;
}
.react-bootstrap-table tbody {
    display: block;
    border-top: 1px solid #eeeeee;
}


.react-bootstrap-table tbody td.id-picture-cell {
    width: 4rem;
}
.react-bootstrap-table tbody td.id-user-cell {
    width: 35%;
}
.react-bootstrap-table tbody td.id-details-cell {
    width: 60%;
    flex: 1 1 auto;
    display: flex;
}
.react-bootstrap-table tbody .selection-cell {
    vertical-align: middle;
    opacity: 0.2;
    padding: 1rem 0.4rem;
}
.react-bootstrap-table .selection-cell-header .custom-checkbox{
  padding-left: 1.2rem;
}
.react-bootstrap-table .custom-control-label::before,
.react-bootstrap-table .custom-control-label::after {
    width: 1.2rem;
    height: 1.2rem;
}
.react-bootstrap-table .custom-control-label::before {
    border-width: 2px;
    border-radius: 3px;
}
.react-bootstrap-table .custom-control-label::after {
    background-size: 0.8rem;
    left: -1.23rem !important;
}
.react-bootstrap-table tbody tr:hover .selection-cell {
    opacity: 1;
}
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #494949;
    background-color: #494949;
}


.react-bootstrap-table tbody td .picture-holder {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background: #f1f1f1;
    display: table;
    overflow: hidden;
    
}
.react-bootstrap-table tbody td .picture-holder .picture-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-weight: 600;
    color: #bdbdbd;
    font-size: 1.2rem;
    overflow: hidden;
    text-transform: uppercase;
    min-width: 3.2rem;
    height: 3.2rem;
}

.react-bootstrap-table tbody td .picture-holder .picture-content .picture{
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
    display: block;
    border-radius: 50%;
    pointer-events: none;
    border: 1px solid #f8f8f8;
    
}

.react-bootstrap-table tbody td .user-info-content h3 {
    font-size: 1.1rem;
    margin-bottom: 0rem;
    color: #525151;
    font-weight: 400;
    text-overflow: ellipsis;
}
.react-bootstrap-table tbody td .user-info-content p {
    font-size: 0.9rem;
    margin-bottom: 0rem;
    color: #9fa1a3;
}
.react-bootstrap-table tbody td.id-details-cell .user-details {
    width: 100%;
    height: 100%;
    position: relative;
}

.react-bootstrap-table tbody td.id-details-cell .user-details-holder{
    background: #f9f9f9;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    color: #929292;
}
.snippet-set {
    display: inline-block;
}
.snippet-set li {
    display: inline-block;
    text-overflow: ellipsis;
}
.react-bootstrap-table tbody td.id-details-cell .auth-snippet{
    display: block;
}
.react-bootstrap-table tbody td.id-details-cell .auth-snippet.role {
    width: 10rem;
    height: 2rem;
}
.react-bootstrap-table tbody td.id-details-cell .auth-snippet p{
    margin: 0rem 0.2rem;
    display: inline-block;
}
.react-bootstrap-table tbody td.id-details-cell .auth-snippet .icon{
    width: 1.3rem;
    height: 2rem;
    display: inline-block;
    pointer-events: none;
}

/*Widgets*/

.users-widgets-list {
    margin-top: 2rem;
}

.users-widgets-list .user-widget-item {
    margin: 1rem auto;
}

.users-widgets-list .user-widget-item  .widget-content {
    width: 10rem;
    height: auto;
    margin: 0rem auto;
}

.users-widgets-list .user-widget-item  .widget-content * {
    display: block;
    text-align: center;
}

.users-widgets-list .user-widget-item  .widget-content label{
    margin-top: 0.6rem;
    border-radius: 0.3rem;
    background-color: #e8ecf0;
    display: inline-block;
    padding: 0.1rem 0.6rem;
    font-weight: 500;
    font-size: 0.9rem;
    color: #1f9aec;
}

.users-widgets-list .user-widget-item  .widget-content .widget-detail {
    width: 9rem;
    height: 9rem;
    margin: 0rem auto;
    border-radius: 50%;
    padding: 3rem .3rem;
    border: 1px solid #1f9aec;
    background-color: #f0f5fa; //#f6f9fc #67b3ff;
    color: #1f9aec;
    overflow: hidden;
}

.users-widgets-list .user-widget-item  .widget-content .widget-detail .count{
    font-size: 2em;
    line-height: 2rem;
    font-weight: 500;
    text-overflow: ellipsis;
}