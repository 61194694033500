
@import "./../../../assets/styles/mixins/global-mixins";

.view-layout-content .cabinet-lyr .spmt-title-d {
    position: sticky;
    top: 0px;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.932);
}
.cabinet-identifier {
    display: inline-block;
    background-color: #eeeeee;
    border-radius: 0.3rem;
    padding: 0.1rem 0.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    margin-left: 0.5rem;
    color: #666666;
    position: relative;
    top: -.1rem;
}
.tabs-lyr {
    position: sticky;
    top: 3rem;
    z-index: 5;
    background-color: #ffffffef;
}
.tabs-nav-warp {
    height: auto;
    width: 100%;
    position: absolute;
    background-color: #ffffffef;
    border-radius: 0.2rem;
    overflow-y: hidden;
}
.specimens-records-wrap {
    margin-top: 3rem;
}
.tabs-nav-warp .nav-tabs{
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0rem 0;
  overflow-x: scroll;
  scroll-padding-left: 1rem;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  overflow-y: hidden;
}

::-webkit-scrollbar {
    display: none;
}

.tabs-nav-warp .nav-tabs .nav-item{
    padding: 0rem;
    scroll-snap-align: start; /* or: center */
    scroll-snap-stop: always;
    @include disable-user-select();
}
.tabs-nav-warp .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 2rem;
}

.tabs-nav-warp .nav-tabs .nav-item:active {
    z-index: 9;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(68, 72, 77, 0.05);
    font-weight: 500;
    transform: scale(1.4);
    -o-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -webkit-transform: scale(1.4);
}

.tabs-nav-warp .nav-tabs .nav-item .nav-link.active {
    font-weight: 600;
}

.cbt-modal-wrap {
    position: fixed;
    display: flex;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.055);
    align-items: center;
    justify-content: center;
}

.cbt-modal-wrap .cbt-modal-content {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    min-width: 50%;
    height: 32rem;
    border-radius: 0.8rem;
    background-color: white;
    box-shadow: 0px 0px 18px #00000011;
    overflow-y: auto;
}

.cbt-modal-wrap .head-section {
    padding: 0.5rem 1rem;
    width: 100%;
    z-index: 9;
    top: 0;
    background-color: #fff;
    border: none;
}
.cbt-modal-wrap .head-section .title-box {
    margin: 0rem 0.5rem;
}
.cbt-modal-wrap .slide-pane__close {
    position: absolute;
    right: 0rem;
    top: 0rem;
}

.cbt-modal-wrap .cabinet__body-section {
    padding: 1rem 2rem;
    height: auto;
}
.cabinet__body-section  .content-area {
    width: 100%;
}
.cabinet__body-section  .section {
    padding: 0rem 2rem;
}
.cabinet__body-section .cabinet-lnk-wrap .cbt-box{
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}
.cabinet__body-section .cabinet-lnk-wrap .cbt-box .cbl-col{
    flex: 0 0 33.33%;
    padding: 0rem 0.2rem;
}
.folder-content-list .nav-link .icon {
    opacity: 0.9 !important;
}
.cabinet-label-warp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cabinet__body-section  .cabinet-hld {
    position: relative;
}

.block.select-client {
    margin-top: 2rem;
}
.cabinet__body-section  .cabinet-hld label {
    position: absolute;
    width: 100%;
    bottom: 0.2rem;
    font-size: 0.7rem;
    color: #fff;
    text-align: center;
    padding: 0rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cabinet-label-warp .ip-text-field{
    font-size: 2rem;
    color: #6e6e6e;
    width: auto !important;
    max-width: 30rem;
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    background: transparent;
    display: inline-block;
    cursor: text !important;
    min-width: 0.5rem;
}

.cabinet-label-warp .ip-text-field:focus {
    border-bottom: 2px solid #54a4ff;
    color: #494949;
}

.color-box {
    display: flex;
    flex-direction: row;
    margin: 1rem auto 2rem auto;
}
.color-box span {
    color: #a0a0a0;
}
.color-box .colors-swatches-wrap {
    flex: 1 0 0%;
}
.color-box .colors-swatches-wrap .color-swatches {
    display: block;
    text-align: center;
    list-style: none;
}

.color-box .colors-swatches-wrap .color-swatches .clr-swatch{
    display: inline-block;
    text-align: center;
    margin: 0rem 1rem;
}

.color-box .colors-swatches-wrap .color-swatches .clr-swatch .color{
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #6e6e6e;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.color-box .colors-swatches-wrap .color-swatches .clr-swatch.--selected .color::before {
    content: "";
    box-shadow: 0 0 0 2px rgb(214, 214, 214);
    display: block;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: -1;
}

.cabinet-lnk-wrap .cbl-col .lnk-icon,
.cabinet-lnk-wrap .cbl-col .btn-wrap,
.cabinet-lnk-wrap .cbl-col .cbt-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
}

.cabinet-lnk-wrap .cbl-col .cbt-icon svg{
    display: block;
    height: 100%;
    width: 100%;
}

.cabinet-lnk-wrap .cbl-col .btn-wrap button {
    width: 100%;
    height: 100%;
    border: 2px dashed #d6d6d6 !important;
    border-radius: 1.3rem;
    background-color: transparent;
    display: block;
    text-align: center;
    color: #494949 !important;
}
.cabinet-lnk-wrap .cbl-col .linked-client {
    width: 100%;
    height: 100%;
    border: 2px dashed #d6d6d6 !important;
    border-radius: 1.3rem;
    background-color: transparent;
    display: block;
    padding: 0.5rem;
}

.cabinet-lnk-wrap .cbl-col .linked-client .client-item {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background-color: #f3f3f3;
    display: flex;
    text-align: center;
    color: #494949 !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}


.cabinet-lnk-wrap .cbl-col .linked-client .client-item:hover {
    background-color: #f7f5f5;
}

.cabinet-lnk-wrap .cbl-col .linked-client .client-item .initials{
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: #c4c4c4;
}

.cabinet-lnk-wrap .cbl-col .linked-client .client-item label{
    margin-bottom: 0rem;
}


.cabinet-lnk-wrap .cbl-col .btn-wrap button:active {
    background-color: #f3f3f3 !important;
}
.cabinet-lnk-wrap .cbl-col .btn-wrap button .icon  {
    margin-bottom: 1rem;
    opacity: 0.4;
    display: inline-block;
}
.cabinet-lnk-wrap .cbl-col .btn-wrap button.--error {
    border-color: #f02a08 !important;
}
.cabinet-lnk-wrap .cbl-col .btn-wrap button .icon img {
    width: 3rem;
}
.cabinet-lnk-wrap .cbl-col .lnk-icon img{
    width: 5rem;
    opacity: 0.13;
    pointer-events: none;
    @include disable-user-select();
}

.content-area .desc {
    color: rgb(122, 122, 122);
    text-align: center;
}

.return-lnk {
    display: flex;
    justify-content: end;
    margin-top: 2rem;
}

.content-area .cabinet-action-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
}

.cabinet-action-wrapper .save-btn{
    margin-left: 1rem;
}

.cabinet-item-wrapper{
    position: relative;
}

.cabinet-item-wrapper .cabinet-item--edit{
    position: absolute;
    right: 0px;
    top: 0px;
    display: none;
}

.cabinet-item-wrapper:hover .cabinet-item--edit{
    display: block;
}

.cabinet-item--edit .nav-link{
    border-radius: 0.5rem !important;
    padding: 0rem !important;
    height: 1.8rem !important;
    width: 1.8rem !important;
    margin: 0.4rem 0.3rem 0.4rem 0rem;
    opacity: 0.3 !important;
}


.cabinet-item--edit .nav-link:active{
    opacity: 0.5 !important;
}

.cabinet-item--edit .nav-link .icon {
    height: inherit !important;
    width: inherit !important;
    margin: auto !important;
    padding: 0.3rem !important;
    pointer-events: none;
    @include disable-user-select();
}

.cabinet-item--edit .nav-link .icon img{
    margin: auto !important;
    width: 1.3rem !important;
    display: block;
    pointer-events: none;
}

.cabinet__body-section .content-area .del-btn{
    background-color: transparent !important;
    color: #f02a08 !important;
    margin-right: 1rem !important;
}
.cabinet__body-section .content-area .del-btn:active{
    background-color: #ffe5e1 !important;
}
.cabinet__body-section .content-area .del-btn .icon {
    margin-right: 0.4rem;
    display: inline-block;
    position: relative;
    top: -0.1rem;
}
.cabinet__body-section .content-area .del-btn .icon img{
    width: 1.1rem;
    pointer-events: none !important;
}

.cabinet__body-section  .save-btn:disabled {
    color: #ffffff !important;
}