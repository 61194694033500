
@import "./../../assets/styles/mixins/global-mixins";
/*@import "./../../assets/styles/mixins/fonts";*/

.result-sheet .slide-pane  {
    background-color: rgb(248, 248, 248);
}

.slide-pane__overlay {
    z-index: 999;
}
.slide-pane__close {
    position: absolute;
    right: 0;
    opacity: 1;
}
.slide-pane__close .close-btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0.3rem;
    line-height: 0.6rem;
    background-color: transparent;
}
.slide-pane__close .close-btn:focus {
    background-color: transparent !important;
}
.slide-pane__close .close-btn:active {
    background-color: transparent !important;
    opacity: 0.7;
}
.slide-pane__close .close-btn img {
    width: 80%;
    pointer-events: none;
}
.slide-pane__content {
    padding: 2rem 2rem 0rem 2rem;
    overflow-x: hidden;
}
.slide-pane__content .view-layout-content{
    padding-bottom: 0rem;
}
.slide-pane__header {
    background: linear-gradient(
180deg
, #f8f8f8, #f8f8f8 ,#f8f8f811);
    border: none;
    position: absolute;
    width: 100%;
    z-index: 9;
}
.apprv-wrap {
    position: relative;
}
.apprv-hldr {
    position: absolute;
    left: auto;
    right: 0;
    top: 0;
    text-align: right;
}
.apprv-hldr .approved  {
    display: flex;
    justify-items: center;
    align-items: center;
    
}
.apprv-hldr .approved  h3 {
    color: #8cc63f;
}
.apprv-hldr .approved img {
    width: 2rem;
    margin-left: 0.5rem;
    pointer-events: none;
}
.apprv-hldr .apprv-date {
    color: #575757;
    font-weight: 500;
    padding-right: 1rem;
}
.rslt-sht-lay {
    padding-top: 2rem;
    margin-bottom: 2rem;    
}

.rslt-sht-lay .rslt-sht-cont {
    border: 1px solid rgb(247, 247, 247);
    background-color: #fff;
    border-radius: 0.3rem;
}
.rslt-sht-brand {
   display: flex;
   justify-items: center;
   align-items: center;
}
.rslt-sht-brand .logo-hldr {
    display: inline-block;
}
.rslt-sht-brand .logo-hldr .logo{
    margin: 0rem;
    width: 8rem;
    opacity: 0.3;
}
.rslt-sht-brand .view-title {
    margin: 0.5rem 0rem 0rem 0.5rem;
    font-size: 1.6rem;
    color: rgb(61, 61, 61);
    display: inline-block;
    font-weight: 400;
}
.rslt-sht-cont {
    padding: 1.5rem 2rem;
}
.rslt-sht-cont .section {
    border-bottom: 2px solid #d4d8da;
    padding: 1.6rem 0rem;
}
.rslt-sht-cont .section .info-line{
    margin-top: 0.3rem;
    color:rgb(85, 85, 85);
}
.rslt-sht-cont .section .info-line .label{
    color:rgb(160, 160, 160);
    margin-right: 0.5rem;
}
.rslt-sht-cont .section .info-line .label.compact {
    margin-left: 1rem;
}
.rslt-sht-cont .section .spmt-entry {
    border: 1px solid rgb(231, 231, 231);
    border-radius: 2px;
    margin: 0.5rem 0rem;
}
.rslt-sht-cont .section .entry-cont {
    display: flex;
    padding: 0.8rem;
}
.vial-hldr {
    width: 5rem;
}
.spmt-details {
    width: 38%;
    height: inherit;
    margin-left: 2rem;
}
.code-sht-hldr {
    width: 58%;
    height: inherit;
    margin-left: 2rem;
}
.code-sht-hldr .select-container {
    margin: 1rem auto;
}
.code-sht-hldr .select-container .select-control.codeSheet {
    border: 1px dotted #e6e6e6;
}
.rslt-sht-cont .section .spmt-entry:hover {
    box-shadow: 0rem 1px 6px rgba(0, 0, 0, 0.103);
}
.rslt-sht-cont .section .entry-cont .vial-wrap .vial {
    width: 3.4rem;
    margin: auto;
}
.rslt-sht-cont .vial-wrap .vial .vial-bottle .blood .blood-wave svg {
    bottom: -0.4rem;
}
.code-sht-hldr .code-sht-well {
    border: 2px dashed rgb(218, 218, 218);
    border-radius: 0.1rem;
}
.rslt-sht-cont .section .entry-cont .cell-slide {
    width: 4.5rem;
    height: 11.5rem;
}
.rslt-sht-cont .section .entry-cont .cell-slide .cell-label {
    height: 3rem;
}
.rslt-sht-cont .section .entry-cont .cell-slide .cell-label .cell-text {
    line-height: 0.7rem;
    font-size: 0.6rem;
    margin: 0.5rem auto;
    letter-spacing: -0.01rem;
}
.code-sht-hldr .code-sht-well .add-btn {
    width: 100%;
    height: 100%;
    margin: 0rem;
    background-color: transparent;
    color: rgb(160, 160, 160);
    line-height: 2rem;
    font-weight: 400;
}
.code-sht-hldr .code-sht-well:hover {
    background-color: #f5fcff;
    border-color: #48c0f3 !important;
}
.code-sht-hldr .code-sht-well .add-btn span{
    display: block;
    font-size: 3rem;
}
.rslt-sht-cont .spmt-details h4 , .rslt-sht-cont .code-sht-details {
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.8rem;
}
.rslt-sht-cont .spmt-details h4 , .rslt-sht-cont .code-sht-details h4{
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.8rem;
}
.rslt-sht-cont .spmt-details .info-line {
    font-size: 1rem;
    line-height: 1.2rem;
}
.rslt-sht-cont .code-sht-details {
    height: 100%;
}
  
.code-sht-item {
    height: auto;
    border-radius: 0.1rem;
    padding: 0.5rem;
    margin-bottom: 0.8rem;
    border: 2px dashed transparent;
    position: relative;
}

.code-sht-item .count {
    position: absolute;
    top: 0.3rem;
    left: -1.3rem;
    color: #bbbbbb;
    font-size: 1rem;
}
.code-sht-item .code-entry {
    display: flex;
}
.code-sht-item.--editable:hover {
   border: 2px dashed #f5ca6c;
   cursor: cell;
   //cursor: url('./../../assets/images/lock-icon.svg');
   background-color: #fdf8ee;
}

.code-sht-item .code-sht-col{
    height: 100%;
}
.code-sht-item .code-sht-col.code{
    width: 15%;
}
.code-sht-item .code-sht-col.abrv{
    width: 25%;
}
.code-sht-item .code-sht-col.desc{
    width: 60%;
}
.code-sht-item .code-sht-col.desc p{
    text-transform: none !important;
    font-size: 0.91rem;
}
.code-sht-item .code-sht-col h5 {
    text-transform: uppercase;
    font-size: 1rem;
}
.code-sht-col p {
    text-transform: uppercase;
    font-size: 0.9rem;
    color: rgb(71, 71, 71);
}
.code-sht-item  .code-sht-findings {
    margin-top: 0.7rem;
    display: flex;
    position: relative;
}
.code-sht-item  .code-sht-findings span {
    margin-right: 0.6rem;
}

.code-sht-item  .code-sht-findings span.lble {
    font-size: 0.85rem;
    position: relative;
    top: 0.2rem;
    color: #474747;
}
.code-sht-item  .code-sht-findings span.vlu  {
    width: 100%;
    position: relative;
 }

.code-sht-item  .code-sht-findings span.nmly  {
    display: flex;   
    flex-wrap: nowrap;
}
.code-sht-item  .code-sht-findings .ip-radio-pill{
   margin: 0rem 0.3rem;
   line-height: 1rem;
   .radioselect {
        height: 22px;
        margin: 0rem;
   }
   .text {
        text-transform: capitalize;
        font-size: 0.8rem;
        padding: 0rem 0.5rem;
        display: inline-block;
        margin: 0rem;
   }
}
.code-sht-item  .code-sht-findings span.vlu .form-control {
   padding: 0.2rem 0.2rem 0rem 0.2rem;
   line-height: 0rem;
   height: auto;
   width: 100%;
   border-radius: 0;
   background: transparent;
   border: 0rem;
   font-weight: 600;
   text-transform: uppercase;
}
.code-sht-item.--editable .form-control{
    border-bottom: 1px solid #a7a8a8 !important;
}

.code-findings {
    border: 1px solid #d8d8d8;
    position: absolute;
    top: 1.4rem;
    right: 0rem;
    width: 100%;
    max-height: 12rem;
    background-color: #fff;
    border-radius: 0.1rem;
    box-shadow: 0 2px 4px #00000018;
    z-index: 2;
    overflow-y: scroll;
}
.code-findings .code-fnds-itm {
    padding: 0.4rem 0.6rem;
    text-transform: initial;
}
.code-findings .code-fnds-itm:hover {
    background-color: #f8f8f8;
}
.code-fnds-itm strong{
    text-transform: uppercase;
    font-weight: 500;
}
.select-container .options--default .select_options--default .template-option.codeSheet {
    font-size: 0.95rem;
    text-transform: initial;
}
.select-container.codeSheet .select-placeholder-title {
    text-transform: initial;
}
.actions-sec.section {
    position: relative;
    display: flex;
    border: none;
}
.actions-sec .btn-group {
    display: flex;
    margin-left: auto;
    margin-right: 0;
}
.actions-sec .btn-group .btn {
    margin: 0rem 1rem;
    width: auto;
    display: inline-block;
    line-height: normal;
    font-weight: 400;
    border-radius: 0.5rem  !important;
}
.actions-sec .btn-group .add-btn {
    color: #575757;
    background-color: rgb(219, 219, 219);
}
.actions-sec .btn-group .add-btn:hover {
    background-color:  rgb(199, 199, 199);
}
.actions-sec .btn-group .add-btn:active {
    background-color: #1083ee;
}
.actions-sec .btn-group .auth-btn {
    background-color: #8cc63f;
    color: #fff;
    font-weight: 600;
}
.actions-sec .btn-group .auth-btn[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
}
.actions-sec .btn-group .auth-btn:focus {
    background-color: #8cc63f;
}
.actions-sec .btn-group .auth-btn:active {
    background-color: #7eb834;
}
.actions-sec .btn-group .auth-btn .icon{
    margin-right: 0.2rem;
    line-height: normal;
    pointer-events: none;
}
.actions-sec .btn-group .auth-btn img{
    width: 1.5rem;
}
.sec-cont.report .report-item {
    margin-bottom: 1rem;
}
.sec-cont.report .report-item .info-bar * {
    display: inline-block;
    margin-bottom: 0.1rem;
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    color: #9b9b9b;
}
.sec-cont.report .report-item .info-bar .authorizer {
    font-weight: 500;
    color: #575757;
}
.sec-cont.report .report-item .info-bar .type {
    font-weight: 500;
    color: #acbfcc;
    margin-left: 1rem;
    text-transform: uppercase;
}
.sec-cont.report .report-item .report-body{
    border: 1px solid #dbdbdb;
    border-radius: 0.5rem;
    padding: 1rem;
    position: relative;
    
}
.sec-cont.report .report-item .report-body .discard{
    position: absolute;
    right: 0;
    top: 0;
    color: #f01d0e;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
.sec-cont.report .report-item:hover  .discard{
    visibility: visible;
    opacity: 1;
}
.sec-cont.report .report-item .report-body .label{
   color: #9b9b9b;
}
.sec-cont.report .report-item .report-body .ip-text-area{
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    color: #575757;
}
.sec-cont.report .report-item .report-body .ip-text-area.--editable{
    border: 2px dotted #e7e7e7;
}
.sec-cont.report .report-item .report-body .ip-text-area.--editable:hover{
    border: 2px solid #d7ecf8;
}
.sec-cont.report .report-item .report-body .ip-text-area.--editable:focus{
    outline-color: #6cc3f5;
}
.sec-cont.report .report-item .report-body .ip-text-area.--editable.error{
    border: 2px dotted #eb0202;
}
.sec-cont.report .report-item .report-body .signature-group{
   margin-top: 0.5rem;
   text-align: right;
   display: flex;
   align-items: center;
   justify-content: flex-end;
}
.sec-cont.report .report-item .report-body .date-updated {
    font-size: 0.8rem;
    color: #c5c5c5;
    font-weight: 400;
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
    word-spacing: -0.1rem
}
.sec-cont.report .report-item .report-body .signature-group span {
    vertical-align: bottom;
    padding: 0.5rem;
}
.sec-cont.report .report-item .report-body .signature-group .sig {
    width: 8rem;
    border-radius: 0.2rem;
    pointer-events: none;
    @include disable-user-select;
}
.report-well {
    background-color: #f5f5f5;
    text-align: center;
    padding: 0rem;
    border-radius: 0.4rem;
}
.report-well button{
    background-color: #f7f7f7;
    text-align: center;
    padding: 1.6rem;
    border-radius: 0.4rem;
    width: 100%;
    color: #9b9b9b;
    line-height: normal;
    border: 1px solid transparent !important;
}
.report-well button:hover{
    background-color: #f5fcff;
    border: 1px solid #48c0f3 !important;
}
.report-well button span{
    display: block;
    font-size: 3rem;
}
.rslt-sht-cont .vial-wrap .vial .vial-bottle .blood .blood-grp-text {
    font-size: 1.2rem;
}

.print-bar__action-list{
    display: flex;
    align-items: flex-end;
}

.print-bar__action-list .lds-roller{
    width: 36px;
    height: 36px;
}


#print-frame {
    display: none;
    height: 0px;
    width: 0px;
    padding: 0px;
    margin: 0px;
}

@media print {
    
    .result-sheet .printable-area {
        padding: 1rem;
    }
    .patient-details {
        margin-bottom: 0rem;
    }
    .rslt-sht-cont .section .info-line {
        font-weight: 500;
    }

    .report--signature{
        width: 8rem;
    }

    #print-frame {
        display: block;
        height: 100%;
        width: 100%;
    }

    #print-frame .print-container {
        width: 100%;
        padding: 0rem;
    }
    #print-frame .logo-td {
        vertical-align: top;
    }
    #print-frame .cmpy-logo {
        width: 10rem;
    }
    #print-frame .printable-area {
        width: inherit;
    }
    #print-frame .printable-area  address {
        font-weight: 400;
    }
    #print-frame .printable-area .section {
        margin-bottom: 0.6rem;
        padding: 0.5rem 0rem;
    }
    #print-frame .client-patient-sec,
    #print-frame .result-sec,
    #print-frame .report-footer{
        font-family:  'King', Arial, Helvetica, Geneva, Verdana, sans-serif;
    }
    #print-frame .printable-area .header-sec  {
        border-bottom: 4px solid black;
    }
    #print-frame .printable-area .row {
       display: flex;
    }
    #print-frame .specimen-line {
        display: flex;
        width: 100%;
    }
    #print-frame .specimen-line .label{
        flex: 0 0 19%;
    }
    #print-frame .specimen-line .value{
        padding-left: 1.5rem;
        flex: 0 0 50%;
    }
    #print-frame .printable-area .company-info {
        text-align: center;
    }
    #print-frame .printable-area .company-info .heading {
        font-size: 1.8rem;
        font-weight: 600;
    }
    #print-frame .printable-area .company-info .sub-heading {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
    }
    #print-frame .printable-area .company-info .heading-info {
        font-size: 1rem;
        font-weight: 500;
    }
    #print-frame .printable-area table {
        width: 100%;
        font-weight: 300;
    }
    #print-frame .printable-area .main-td {
        height: 3rem !important;
    }
    #print-frame .printable-area .strong {
        font-weight: 600;
    }
    #print-frame .printable-area .tables-ly {
        display: flex;
    } 
    #print-frame .printable-area .tables-ly {
        display: flex;
    } 
    #print-frame .printable-area .table-col1 {
        flex: 40%;
    }
    #print-frame .printable-area .table-col2 {
        flex: 60%;
    }
    #print-frame .printable-area .report-title {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #print-frame .printable-area .title {
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        text-align: center;
        display: inline-block;
        border-bottom: 2px solid black;
    }

    #print-frame .report-block {
        margin-bottom: 1rem;
    }
    #print-frame .report-block .block-title {
        font-size: 1rem;
        font-weight: 600;
    }
    #print-frame .report-block .block-title.uppercase {
        text-transform: uppercase;
        font-weight: 600;
    }

    #print-frame .report-block .items-block {
        display: block;
    }
    #print-frame .report-block .report-line {
        font-weight: 300;
        display: flex;
        letter-spacing: 0.1rem;
        position: relative;
        margin-bottom: 0.1rem;
    } 
    #print-frame .report-block .report-line .label{
        flex: 0 0 48%;
        margin-left: 1.5rem;
        position: relative;
    }
    #print-frame .report-block .diagnosis .report-line  {
        margin-bottom: 0.5rem;
    }
    #print-frame .report-block .diagnosis .report-line .label {
        flex: 0 0 80%;
    }

    #print-frame .report-block .report-line  .content {
        position: relative;
        margin-top: 0.5rem;
    }
    #print-frame .report-block.details .label{
        border-bottom: 1px dotted #929394;
    }
    #print-frame .report-block .report-line .mask{
        position: relative;
        width: auto;
        background-color: #fff;
        height: 1.5rem;
        left: 0;
        top: 0.5rem;
        padding-right: 0.4rem;
    }
    #print-frame .report-block .report-line .value{
        position: relative;
        top: 0.5rem;
        padding-left: 0.4rem;
    }
    #print-frame .report-block .diagnosis{
        padding: 1.2rem 0rem;
    } 
    #print-frame .report-block .diagnosis .report-line{
        font-size: 1.1rem;
    } 
    #print-frame .report-footer  {
        display: block;
    }
    #print-frame .report-footer p {
        text-align: center;
        font-size: 1rem;
        letter-spacing: 0.15rem;
    }

    #print-frame .margin--left{
        margin-left: 1rem;
    }
}