$placeholder-color: #d0e3f5;

.layout__navbar ::-webkit-input-placeholder {
    /* Edge */
    color: $placeholder-color;
}

.layout__navbar :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $placeholder-color;
}

.layout__navbar ::placeholder {
    color: $placeholder-color  !important;
}

.layout__navbar {
    background-color: #3F97EF;
    position: sticky;
    width: 100%;
    top: 0px;
    z-index: 99;
    transform: translateY(0);
}

.navbar-wrap * {
    color: #f8fcff;
}

.navbar-wrap {
    margin: 0.1rem auto 0.1rem auto;
    justify-content: space-between;
}

.ntf-stg-group {
    display: flex;
    align-items: center;
}

.layout__navbar .logo {
    width: 10rem;
    margin: 0;
    filter: invert(100%);
}

/* Search bar styles */
.search-main {
    position: relative;
    box-shadow: 0px 1px 5px #1741631c;
    border-radius: 0.7rem;
    background: #c2e5ff38;

    transition: background 0.5s ease;
    -o-transition: background 0.5s ease;
    -moz-transition: background 0.5s ease;
    -webkit-transition: background 0.5s ease;

    &:hover {
        background: #ffffff5a;
    }

    .search-box {
        position: relative;
        z-index: 2;
    }

    .search-box .search-field {
        background: transparent;
        padding: 0.5rem 0.5rem 0.5rem 2rem;
        color: #4d4f50;
        font-weight: 500;
        width: 25rem;
        margin: 0rem 0.5rem;
        border-bottom: 1px solid transparent;
    }

    .search-box .search-icon {
        margin: 0.4rem;
        display: inline-block;
        position: absolute;
        top: 0rem;
        bottom: 0rem;
        left: 0.2rem;
    }

    .search-box .search-icon .icon {
        width: 1.2rem;
        height: 1.2rem;
        pointer-events: none;
        opacity: 0.8;
        filter: invert(1);
    }

    .search-result-wrap {
        display: block;
        background-color: #fff;
        position: absolute;
        top: 2.5rem;
        width: 100%;
        border-radius: 0rem 0rem 0.7rem 0.7rem;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.096);
        z-index: 1;
        overflow: hidden;
        min-height: 4rem;
        max-height: 20rem;


        .search-result {
            position: relative;
            height: inherit;
            max-height: inherit;
        }

        .results-list {
            position: relative;
            z-index: 1;
            padding-top: 1.4rem;
        }

        .results-list .entry {
            display: flex;
        }

        .results-list .entry .content-wrap {
            overflow: hidden;
        }

        .results-list .entry .icon-box .icon {
            position: relative;
            top: 0.4rem;
            margin-right: 0.3rem;
        }

        .results-list .entry .icon-box .icon svg {
            display: block;
            width: 1.5rem;
            fill: rgb(207, 207, 207)
        }

        .results-list .entry .icon-box .icon img {
            display: block;
            width: 1.8rem;
            opacity: 0.35;
            position: relative;
            bottom: 0.1rem;
        }

        .results-list .entry .picture-holder {
            width: 32px;
            height: 32px;
            min-width: auto;
            margin-right: 0.4rem;
            position: relative;
            top: 0.2rem;
        }

        .results-list .entry .picture-holder .picture-content {
            font-size: 0.8rem;
        }

        .results-list .result-item {
            padding: 0.5rem 0.6rem;
            color: rgb(63, 63, 63) !important;
            cursor: pointer;
            font-weight: 500;
            border-radius: 0.4rem;
            white-space: nowrap;
            overflow: hidden;

            &:hover {
                background-color: #f7f7f8;
            }

            .entry .grp {
                white-space: nowrap;
                overflow: hidden;
                display: flex;
                line-height: normal;
            }
        }

        .results-list .no-result {
            color: rgb(158, 158, 158);
            padding: 0.4rem 1rem;
        }

        .results-list .result-item .prime-info {
            color: rgb(63, 63, 63) !important;
            margin-right: 0.5rem;
        }

        .results-list .result-item .prime-info {
            color: rgb(63, 63, 63) !important;
            margin-right: 0.5rem;
        }

        .results-list .result-item .status-indicator {
            position: relative;
            top: -0.01rem;
            padding: 0.0rem 0.5em;
            line-height: 1.1rem;
            font-size: 0.74rem;
            min-width: 4.1rem;
            border-radius: 0.3rem;
        }

        .results-list .result-item .sec-info {
            color: rgb(158, 158, 158);
            margin-right: 0.5rem;
        }

        .results-list .result-item .sec-info:last-child {

            margin-right: 0rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .results-list .result-item .sec-info.nline {
            display: block;
            line-height: 0.8rem;
            line-height: 1.3rem;
            font-weight: 400;
            font-size: 0.83rem;
        }

        .results-list .entry.bill .--paid {
            background-color: #c8ffe6;
            color: #16bd6f !important;
            text-transform: uppercase;
        }

        .results-list .entry.bill .--sent {
            background-color: #d6f6ff;
            color: #10a5d3 !important;
            text-transform: uppercase;
        }
    }
}

.results-cont {
    max-height: 20rem;
    overflow-y: scroll;
    padding: 0.2rem;
}

.context-tag {
    position: absolute;
    right: 0.5rem;
    top: 0.3rem;
    display: block;
    color: #c7c7c7;
    font-size: 0.8rem;
    z-index: 3;
    pointer-events: none;
}

.search-main.--expand {
    background: #fff;
    border-radius: 0.7rem 0.7rem 0rem 0rem;

    .search-box .search-field {
        border-bottom: 1px solid rgb(233, 233, 233);
    }

    .search-box .search-icon .icon {
        width: 1rem;
        height: 1rem;
        opacity: 0.8;
        filter: invert(0.7);
    }
}

/* Notification Block Styles*/
.notification-block {
    display: flex;
    align-items: center;
}

.notification-block .user-detail {
    margin: 0px 1rem;
}

.notification-block .user-detail span {
    display: inline-block;
    float: left;
}

.notification-block .user-detail span.proifle-picture {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 4px 8px #010b132a;
    margin-right: 0.6rem;
    border: 1px solid #1e83ca;
    background-color: #3f8edc;
    pointer-events: none;
}

.notification-block .user-detail span.proifle-picture .image {
    display: block;
    max-width: 3rem;
    max-height: 3rem;
    object-fit: cover;
    width: 100%;
    height: 100%;
    height: inherit;
    margin: 0rem auto;
    pointer-events: none;
}

.notification-block .user-detail .user-name {
    display: block;
    margin: .6rem 0rem;
    font-weight: 600;
}

.layout__navbar .icon-button {
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    background: transparent;
    outline: none;
    margin: 0rem 0.8rem;
    padding: 0rem;
    line-height: normal;
    border-radius: 0.3rem;
    position: relative;
}

.layout__navbar .icon-button img {
    pointer-events: none;
}

.notification-badge {
    position: absolute;
    left: 14px;
    top: -3px;
}

.message-btn .notification-badge {
    left: 16px;
}

.notification-badge .nf-badge {
    min-width: 19px;
    height: 19px;
    line-height: 0px;
    padding: 5px 2px;
    text-align: center;
    color: #fff;
    display: block;
    background-color: #d11010;
    border-radius: 30px;
    border: solid 1px #d11010;
}

.notification-badge .badge-count {
    line-height: 6px;
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    letter-spacing: -0.06em;
}

.horizontal-divider {
    width: 1px;
    height: 1.4rem;
    background-color: #deeaf7;
    opacity: 0.6;
    flex-shrink: 0;
    margin: 0rem 0.6rem;
    display: inline-block;
}

.menu-item-nav {
    padding-top: 0.1rem;
}

.navigation-bar {
    position: relative;
}

.navigation-bar .nav-item {
    margin-right: 0.1rem;
}

.navigation-bar .nav-item .nav-link {
    border-radius: 0.7rem;
    padding: 0.5rem 1rem;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
}

.navigation-bar .nav-item .nav-link:hover {
    background-color: #398DEA;
}

.navigation-bar .nav-item-icon {
    display: inline-block !important;
    margin-right: 0.4rem;
    pointer-events: none;
}

.navigation-bar .nav-item .nav-link.active {
    cursor: pointer;
    /* pointer-events: none; */
    background-color: #398DEA;
}

.navigation-bar .nav-item-icon img {
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block !important;
}

.navigation-bar .menu-item span {
    display: inline-block;
}

.navigation-bar .more-menu-trigger {
    position: relative;
    right: 0;
    top: 0;
    left: auto;
    display: inline-block;
    margin-left: 2rem;
}

.navigation-bar .more-menu-trigger button {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .6rem;
    background: transparent;
    border: none;
    padding: 0.3rem;
    line-height: 1rem;
}

.navigation-bar .more-menu-trigger button img {
    width: 1.3rem;
    height: 1.3rem;
    opacity: 0.85;
}

.navigation-bar .more-menu-trigger button:hover,
.navigation-bar .more-menu-trigger button:active {
    background-color: #398DEA !important;
}

.navigation-bar .more-menu-trigger button:hover img {
    opacity: 1;
}

.drop-down-trigger {
    border-radius: 8px !important;
    outline: none;
    padding: 0px;
    box-shadow: none;
    margin: 0rem;
    background: transparent;
    border: none;
}

.drop-down-trigger:hover {
    background: transparent;
    opacity: 0.85;
}

.drop-down-trigger:focus {
    background: transparent !important;
    opacity: 0.7;
    outline: none !important;
    box-shadow: none !important;
}

.drop-down-trigger+.dropdown-menu .dropdown-item {
    color: rgb(65, 65, 65);
}

.dropdown-menu .dropdown-item .icon {
    width: 1.3rem;
    height: 1.3rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.dropdown-menu .dropdown-item {
    padding: 0.5em 1.5rem 0.5rem 0.6rem;
}

.drop-down-trigger .icon-button {
    margin: 0rem 0.4rem;
}

.dropdown-menu {
    padding: 0.3rem;
    border-radius: 0.5rem;
    border: 1px solid rgb(238, 240, 241);
    box-shadow: 0px 3px 8px #00000013;
}

.dropdown-menu .dropdown-item {
    outline: none;
    border-radius: 6px
}

.dropdown-menu .dropdown-item:focus {
    background-color: #ebecec;
}

.options-trigger {
    padding: 0.2rem 1rem;
}

.more-menu-trigger.dropdown.show .options-trigger .btn {
    background-color: #398DEA;
}

.more-option-dropdown {
    flex-direction: row;
    padding-top: 0;
    padding-bottom: 0;
    width: 100vw;
    max-width: 300px;
    padding: 0.5rem;
    border-radius: 0.8rem;
}

.more-option-dropdown.dropdown-menu.show {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.more-option-dropdown .grid-group {
    height: auto;
    display: block;
    overflow-x: hidden;
    box-shadow: none;
}

.more-option-dropdown .grid-group li {
    display: inline-block;
    width: 33%;
    height: 6rem;
    padding: 0.2rem;
}

.more-option-dropdown .grid-group li .list-group-item-action {
    height: 100%;
    border-radius: 0.6rem;
    padding: 0.5rem 0.2rem;
    text-align: center;
    border: none;
}

.more-option-dropdown .grid-group li .list-group-item-action * {
    color: #000;
}

.more-option-dropdown .grid-group li .list-group-item-action .icon {
    display: block;
    width: 2.8rem;
    margin: 0.2rem auto;
    opacity: 0.8;
    pointer-events: none;
}

.more-option-dropdown .grid-group li .list-group-item-action .label {
    font-size: 0.85rem;
}

/* Profile drop down menu*/
.notification-block .profile-menu {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-bottom: 0.8rem;
    min-width: 15rem;
}

.notification-block .profile-menu .user-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-top: 0.32rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e9e9e9;
}

.notification-block .profile-menu .user-detail span.proifle-picture {
    margin: 0;
    width: 3.5rem;
    height: 3.5rem;
    border-color: #c9c9c9;
    background-color: #c7c7c7;
}

.notification-block .profile-menu .user-detail span.proifle-picture .image {
    max-width: 3.5rem;
    max-height: 3.5rem;
}

.notification-block .profile-menu .user-name {
    font-weight: 600;
    font-size: 0.94rem;
    color: #606060;
    margin-bottom: 0;
}

.notification-block .profile-menu .user-name__sub-text {
    font-size: 0.875rem;
    margin-top: -5px;
    color: #808080;
}

.notification-block .profile-menu .profile__options {
    display: flex;
    align-items: center;
    font-size: 0.87rem;
    color: black;
    margin-bottom: 0.3rem;
}




@import "./notification-tray";