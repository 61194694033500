.report-overview {
    width: 90%;
    margin: 0rem auto;
}

.report-overview .title {
    font-size: 1.5rem;
    font-weight: 400;
    color: rgb(54, 54, 54);
}

.report-catalogue-sec {
    background: #fff;
    padding: 1.2rem;
    border-radius: 0.2rem;
}

.quick-report-view {
    margin: 1rem 0rem 1rem 0rem;
}

.quick-report-view .quick-report-content {
    display: flex;
}

.quick-report-view .quick-report-content {
    display: flex;
}

.quick-report-view .quick-report-content .q-col {
    flex: 0 0 33.3%;
    padding: 0.5rem;
}

.quick-report-view .quick-report-content .q-col.fcl {
    flex: 0 0 25%;
}

.quick-report-view .quick-report-content .q-col.scl {
    flex: 0 0 25%;
}

.quick-report-view .quick-report-content .q-col.tcl {
    flex: 0 0 50%;
}

.quick-report-content .income .amount {
    font-size: 1.7rem;
    font-weight: 500;
    color: #61686e;
    margin: 0rem;
}

.quick-report-content label {
    font-size: 0.8rem;
    font-weight: 500;
    color: #6986a0;
    margin: 0rem;
    line-height: normal;
    position: relative;
    top: -0.2rem;
    text-transform: uppercase;
}

.specimen-vol>div {
    margin-bottom: 1rem;
    position: relative;
}

.specimen-vol>div .amount {
    font-size: 1.6rem;
    color: #61686e;
}

.specimen-vol>div .info {
    font-size: 0.9rem;
    color: #acacac;
    margin: 0rem 0.4rem;
}

.specimen-vol>div::before {
    width: 5px;
    height: 75%;
    content: "";
    background-color: #dadada;
    left: -0.8rem;
    top: 0.6rem;
    position: absolute;
}

.specimen-vol>div.sp-frm-ngly::before {
    background-color: #64c154;
}

.specimen-vol>div.sp-frm-gly::before {
    background-color: #40ceca;
}

.report-search {
    margin-bottom: 0.8rem;
}

.report-search .search-wrap {
    width: 21rem;
    position: relative;
}

.report-search .search-wrap .search-ipt {
    position: relative;
}

.report-search .search-wrap .icon {
    position: absolute;
    right: 0.5rem;
    top: 0.2rem;
}

.report-search .search-wrap .icon img {
    width: 1.1rem;
    opacity: 0.3;
    z-index: 1;
    pointer-events: none;
}

.search-ipt .form-control {
    border: 1px solid rgb(226, 226, 226);
    border-radius: 0rem;
    padding: 0.4rem 2rem 0.4rem 0.8rem;
    line-height: normal;
    height: auto;
    z-index: 2;
    outline: none !important;
    box-shadow: none;
    background: transparent;
}

.search-ipt .form-control:focus {
    border: 1px solid rgb(187, 219, 248);
    outline: none !important;
    box-shadow: none;
}

.report-search .search-wrap .reports-filter-list {
    width: 100%;
    position: absolute;
    top: 2rem;
    left: 0;
    background: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: 0px 2px 4px #3535351f;
    max-height: 13rem;
    overflow-y: scroll;
    z-index: 2;
    border-radius: 0rem 0rem 0.1rem 0.1rem;
}

.report-search .search-wrap .reports-filter-list .fltr-item {
    line-height: normal;
}

.report-search .search-wrap .reports-filter-list .fltr-item .nav-link {
    padding: 0.5rem 0.8rem;
    line-height: normal;
    color: #1286ce;
    font-weight: 500;
}

.report-search .search-wrap .reports-filter-list .fltr-item:hover {
    background-color: #f3f3f3;
}

.catalogue-content .nav-tabs {
    border-bottom: 5px solid rgb(241, 241, 241);
}

.catalogue-content .nav-item {
    margin-bottom: 0.05rem;
}

.catalogue-content .nav-item .nav-link {
    border: 0rem;
    background-color: transparent;
    font-size: 0.9rem;
    color: rgb(148, 148, 148);
    font-weight: 400;
    padding: 0.6rem 1rem;
}

.catalogue-content .nav-item .nav-link.active {
    border-bottom: 3px solid #2885bd;
    color: rgb(39, 39, 39);
}

.catalogue-content .tab-pane {
    padding: 1.5rem 0rem;
}

.catalogue-content .pane-title {
    font-size: 1rem;
    color: #61686e;
    font-size: 400;
}

.catalogue-content .pane-content {
    margin-top: 1.5rem;
}

.catalogue-content .report-list .report-item {
    display: flex;
    margin-bottom: 2rem;
}

.catalogue-content .report-item .icon {
    flex: 0 0 4.5rem;
    padding-right: 1rem;
}

.catalogue-content .report-item .icon img {
    width: 4.5rem;
    height: 5rem;
    border: 1px solid #d8d8d8;
    border-radius: 0.2rem;
    margin-top: 0.2rem;
}

.catalogue-content .details {
    display: block;
}

.catalogue-content .details .title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.2rem !important;
    color: #1286ce;
}

.catalogue-content .details .desc {
    font-size: 0.85rem;
    color: #696b6e;
    margin: 0rem;
}

.catalogue-content .details .nav-link {
    display: inline-block;
    color: #2885bd;
    margin-top: 0.2rem;
    padding: 0rem;
    font-weight: 500;
    font-size: 0.8rem;
    opacity: 0.85;
    text-transform: capitalize;
}

.catalogue-content .link-grp {
    position: relative;
}

.catalogue-content .details .vline {
    display: inline-block;
    width: 1px;
    height: 12px;
    background-color: #acacac;
    margin: 0rem 0.35rem;
    position: relative;
    top: 0.1rem;
}

//Reporting modal styles
.report-options-wrap {
    display: flex;
    margin-bottom: 1.5rem;
    align-items: flex-end;
    position: relative;
}

.report-options-wrap .filter-grp {
    flex: 0 0 70%;
}

.report-options-wrap .report-actions {
    padding: 0rem 1rem;
    position: relative;
}

.report-options {
    position: relative;
    z-index: 100;
}

.report-options::after {
    width: 1px;
    height: 60%;
    background-color: #e0e0e0;
    content: '';
    bottom: 0.1rem;
    right: 0rem;
    position: absolute;
}

.report-options-wrap .run-btn {
    background-color: #168fda;
    padding: 0.25rem 0.8rem;
    font-size: 0.9rem;
    border-radius: 0.16rem;
    font-weight: 500;
    color: #fff;
    border: 1px solid #168fda !important;
    text-transform: capitalize;
    margin: 0rem 0.2rem;
}

.report-options-wrap .rest-btn {
    background-color: transparent;
    padding: 0.25rem 0.5rem;
    font-size: 0.9rem;
    border-radius: 0.16rem;
    font-weight: 500;
    color: rgb(95, 96, 97);
    border: 1px solid #c9c9c9 !important;
    text-transform: capitalize;
    margin: 0rem 0.2rem;
}

.report-options-wrap .rest-btn .icon {
    margin-right: 0.2rem;
    pointer-events: none;
    position: relative;
    top: -0.12rem;
}

.report-options-wrap .rest-btn .icon img {
    width: 1.1rem;
    opacity: 0.6;
}

.report-options-wrap .no-warp {
    display: flex;

}

.report-options-wrap .run-btn:active {
    background-color: #0f7dc2 !important;
    border-color: #0f7dc2 !important;
}

.report-options-wrap .rest-btn:active {
    background-color: #dee0e0 !important;
    border-color: #dee0e0 !important;
    color: rgb(95, 96, 97) !important;
}

.slide-pane_from_bottom.reporting-modal {
    height: 100vh;
    margin-top: 0rem;
    background-color: #f7f8f9;
}

.reporting-wrap {
    margin-top: 2rem;
}

.reporting-wrap .date-field-group .ip-date {
    border-radius: 0.1rem;
    min-height: 2rem;
    height: 2rem;
    padding: 0rem 0.3rem;
    border-radius: 0.1rem;
}

.reporting-wrap .custom-options .ip-radio-pill .radioselect {
    height: 25px;
}

.report-options-wrap .date-inner-grp {
    display: flex;
}

.reporting-wrap .ip-date .date-picker input,
.reporting-wrap .react-datetime-picker__inputGroup__leadingZero {
    font-size: 1rem !important;
}

.reporting-wrap .react-datetime-picker__inputGroup__divider {
    top: 0rem;
}

.reporting-wrap .date-field-group .ip-date .icon {
    width: 1rem;
    height: 1rem;
    top: 0.2rem;
}

.report-viewer-wrap {
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.2rem;
}

.report-options-wrap .date-wrap {
    margin: 0rem 0.4rem;
    width: 13rem;
}

.report-viewer-wrap .ly-cont {
    width: 70%;
    display: block;
    margin: 0rem auto;
}

.report-viewer-wrap .ly-cont.full {
    width: 100%;
}

.report-viewer-wrap .ly-cont .print-bar {
    height: auto;
    background-color: #fbfbfb;
    border: 1px solid #ebecee;
    border-bottom: none;
}

.report-viewer-wrap .ly-cont .report-viewer {
    border: 1px solid #ebecee;
}

.report-type-box {
    margin-bottom: 0.8rem;
}

.report-type-box h3 {
    font-size: 1.1rem;
    font-weight: 500;
    color: #2885bd;
}

.report-options h4 {
    font-size: 1rem;
}

.reporting-wrap .custom-options .opts-wrap .form-group {
    flex: 0 0 25%;
    margin: 0rem;
    padding: 0rem 0.4rem;
}

.reporting-wrap .custom-options .opts-wrap .form-group.half {
    flex: 0 0 40%;

}

.reporting-wrap .custom-options .form-group .ip-radio-pill {
    margin: 0.15rem 0.3rem;
}

.reporting-wrap .custom-options .select-container .select-control span.icon {
    padding: 4px 8px;
}

.reporting-wrap .custom-options .select-container .select-control {
    background-color: #ffffff65;
}

.reporting-wrap .custom-options .select-container {
    max-width: 20rem;
}

.reporting-wrap .custom-options .form-group .ip-sml-select .select-placeholder-title,
.reporting-wrap .custom-options .ip-sml-select .selected-option.--default {
    line-height: normal;
    padding: 0.4rem 0rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0rem 0.5rem;
    font-size: 0.9rem;
    overflow: hidden;

}

.reporting-wrap .custom-options .select_options--default .template-option {
    padding: 4px 10px;
    font-size: 0.9rem;
    line-height: 1.3rem;

}

.reporting-wrap .custom-options .select-container .options--default .search-filter-field {
    line-height: normal;
    height: 30px;
    font-size: 0.9rem;
    border-radius: 0.1rem;
}

.reporting-wrap .custom-options,
.reporting-wrap .opts-wrap {
    display: flex;
    width: 100%;
}

.reporting-wrap .custom-options .form-group .ip-checkbox .checkmark.round {
    width: 20px;
    height: 20px;

    &::after {
        left: 7px;
        top: 3px;
        width: 7px;
        height: 12px;
    }
}

.reporting-wrap label {
    font-weight: 500;
    font-size: 0.9rem;
}

.print-area {
    padding: 3rem 2rem;
    position: relative;
}

.print-area .print-template {
    position: relative;
    width: 100%;
    page-break-after: always;
}

.header-notes {
    display: none;
}

//only show when printing
.print-area.req-report .footer__page-num {
    display: none;
}

@media print {
    @page {
        margin: 1in !important;
    }

    .print-area.req-report {
        padding: 0;
    }

    .header-notes {
        display: block;
    }

    .print-area.req-report .print-template {
        height: 100vh;
    }

    .print-area.req-report .print-template .page-footer {
        position: absolute;
        //100% would cause the footer to bleed over into the next page
        top: 99%;
        left: 50%;
        transform: translateX(-50%);
    }

    .print-area.req-report .footer__page-num {
        display: block;
        margin-top: 1.42rem;
        position: absolute;
        top: 99%;
        right: 0;
    }

}

.print-area .print-template .report-header {
    text-align: center;

}

.print-area .print-template .report-header .header-inner {
    margin: 0rem auto 0.8rem auto;
    position: relative;
    width: max-content;
}

.print-area .print-template .report-header:hover .edit-hdr {
    display: inline-block;
}

.print-area .print-template .report-header .edit-hdr img {
    width: 1.2rem;
    height: 1.2rem;
    opacity: 0.8;
}

.print-area .print-template .report-header .edit-hdr {
    position: absolute;
    display: none;
    padding: 0rem;
    right: -2rem;
    top: 3px
}

.print-area .print-template .report-header .report-title {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    font-weight: 600;
    padding: 0rem 0.4rem;
    display: inline-block;
    position: relative;
    line-height: 1.5rem;
}

.print-area .editable {
    border: 1px dashed transparent;
    border-radius: 0.2rem;
    display: inline-block;
    padding: 0.2rem 0.4rem;
}

.print-area .editable:focus,
.print-area .editable--edit:hover {
    border-color: #c9c7c7 !important;
    outline: none;
}

.print-area .print-template .report-header .sub-header {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2rem;
}

.print-area .print-template .report-header p {
    font-size: 0.9rem;
    color: #696b6e;
}

.print-area .print-template .report-body table {
    font-size: 0.9rem;
    color: #505152;
    width: 100%;
}

.print-area .print-template .report-body table.default {
    font-size: 0.9rem;
    color: #505152;
    width: 100%;
}

.print-area .print-template .report-body table th {
    color: #3a3b3b;
    border-bottom: 1px solid #6c6c6d;
    border-top: 1px solid #6c6c6d;
    text-transform: uppercase;
    padding: 0.6rem 0.3rem;
    font-weight: 600 !important;
    position: relative;
}

.print-area .print-template .report-body table th::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 80%;
    right: 0;
    top: 0.2rem;
    background-color: #f0f0f0;
}

.print-area .print-template .report-body table th:last-child::after {
    content: "";
    display: none;
}

.print-area .print-template .report-body table tr td {
    position: relative;
    padding: 0.4rem 0.3rem;
    color: #494a4b;
    vertical-align: text-top;
}

.print-area .print-template .report-body table tr td.number,
.print-area .print-template .report-body table th.number {
    text-align: right;
}

.print-area .print-template .report-body table td .info-line {
    border-bottom: 1px solid #e9eaeb;
    padding: 0.2rem 0rem;
}

.print-area .print-template .report-body table td .bl {
    display: block;
    text-align: center;
}

.print-area .print-template .report-body table td .bl.highlight,
.print-area .print-template .report-body table td .l.highlight {
    color: #1286ce;
    font-weight: 500;
}

.print-area .print-template .report-body table td .l {
    display: block;
}

.print-area .print-template .report-body table td .bl.bold,
.print-area .print-template .report-body table td .l.bold {
    font-weight: 500;
}

.print-area .print-template .report-body table tr td small {
    font-size: 0.85rem;
}

.print-area .print-template .report-body table td .info-line:last-child {
    border-bottom: none;
}

.print-area .print-template .report-body table tr.row-divider td {
    border-bottom: 1px solid #dedfe0;
}

.print-area .print-template .report-body table tr.collapsible-row td {
    font-weight: 600;
}

.print-area .print-template .report-body table tr.collapsible-row {
    position: relative;
    cursor: pointer;
    border-radius: 0.2rem;
}

.print-area .print-template .report-body table tr.collapsible-row:hover td {
    background-color: #f8f8f8;
    border-radius: 0.2rem;
}

.print-area .print-template .report-body table tr.collapsible-row td:first-child::before {
    content: "";
    position: absolute;
    left: -0.5rem;
    top: 0.9rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #5f5f5f transparent transparent transparent;
}

.print-area .print-template .report-body table tr.collapsible-row.--folded td:first-child::before {
    transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
}

.print-area .print-template .report-body table tr.collapsible-row~tr.smpl-row td:first-child {
    padding-left: 1rem;
}

.print-area .print-template .report-body table tr.collapsible-row~tr.smpl-row.--hide {
    display: none;
}

.print-area .notes-wrap {
    margin-top: 1rem;
    border-top: 1px solid #6c6c6d;
}

.print-area .notes-wrap label {
    margin-right: 0.5rem;
    margin-bottom: 0rem;
}

// updates begin here
.print-area .notes-wrap .notes,
.print-area .footer-wrap .report-footer {
    padding: 0.2rem 1.5rem 0.2rem 0.4rem;
    min-height: auto;
    border-radius: 0.1rem;
    border: 1px dashed transparent;
    color: #565657;
}

.print-area .footer-wrap .report-footer:focus {
    outline: none;
}

.print-area .notes-wrap .inner-content {
    position: relative;
    width: 100%;
}

.print-area .footer-wrap .inner-content {
    position: relative;
    width: 50%;
    margin: auto;
}

.print-area .notes-wrap .inner-content .icon,
.print-area .footer-wrap .inner-content .icon {
    width: 1rem;
    height: 1rem;
}

.print-area .notes-wrap .inner-content .nav-link,
.print-area .footer-wrap .inner-content .nav-link {
    position: absolute;
    right: 0rem;
    top: 0rem;
    padding: 0.1rem 0.4rem;
    display: none;
}

.print-area .notes-wrap:hover .inner-content .nav-link,
.print-area .footer-wrap:hover .inner-content .nav-link {
    display: block;
}

.print-area .notes-wrap label {
    padding-left: 0.5rem;
    color: #4e4e4e;
}

.print-area .notes-wrap .notes:hover,
.print-area .footer-wrap .report-footer:hover {
    border: 1px dashed #e3e3e4;
}

.print-area .page-footer {
    margin-top: 1rem;
}

.print-area .page-footer p,
.print-area .footer-wrap .report-footer {
    text-align: center;
    font-size: 0.8rem;
    padding: 0.4rem;
    color: #7a7a7a;
}

.print-area .loader-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0rem;
    left: 0rem;
    bottom: 0rem;
    right: 0rem;
    background-color: #fff;
    z-index: 2;
}

.print-area .loader-modal .loader-wrap {
    height: 100%;
}