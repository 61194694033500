.billing-table-coontainer {
    padding-left: 0.3rem;
}
.bill-content {
    position: relative;
    background-color: #fff;
}
.bill-content .bill-wrap{
    padding: 1.5rem;
}
.head-section {
    padding: 1.6rem 1.5rem;
    border-bottom: 1px solid #e9e8e8;  
}
.head-section a {
    text-decoration: none;
}

.head-section::before{
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0px;
    width: 1.5rem;
    height: 5px;
    background-color: white;
}

.head-section::after{
    content: '';
    position: absolute;
    bottom: -4px;
    right: 0px;
    width: 1.5rem;
    height: 5px;
    background-color: white;
}

.bill-create .head-section{
    border-bottom-width: 2px;
}

.bill-content .bill-wrap .section {
    padding: 1rem 0rem;
    border-bottom: 1px dashed #e2e2e2; 
}
.bill-content .bill-wrap .section:last-child {
    border-bottom: none; 
}
.bill-content .bill-wrap .section.total-section {
    border-bottom: 1px solid #e9e8e8; 
}
.title-box {
    display: flex;
}
.title-area .icon, .title-area h1  {
    display: inline-block;
}
.title-area .icon  {
   float: left;
   opacity: 0.25;
}
.title-area h1  {
    font-size: 1.8rem;
    font-weight: 400;
    color: rgb(51, 51, 51);
}
.title-area .icon img {
    width: 2rem;
}
.form-fields .form-group h3 {
    font-size: 1.05rem;
    margin-bottom: 0.8rem;
}
.form-fields .form-group label {
    font-size: 0.9rem;
    font-weight: 400;
    color: #696969;
}
.ip-sml-select .select-control {
    font-size: 1rem;
}
.ip-sml-select .select-placeholder-title {
    padding: 0rem;
}
.ip-sml-select  .selected-option.--default {
    padding: 0rem 1rem;
}
.ip-sml-select .search-filter-field  {
    border-radius: 0.3rem;
}
.ip-sml-select .select-control span.icon {
    opacity: 0.6;
}
.ip-sml-select .select-control span.icon svg{
    width: 12px;
}
.ip-sml-select .options--default .select_options--default .template-option {
    font-size: 1rem;
}
.bill-ref label {
    margin: 0rem;
}
.bill-ref p {
    font-size: 1.4rem;
    line-height: 1.2rem;
    font-weight: 500;
    color: #414141;
}
.billing--details {
    width: 85%;
    position: relative;
    margin: 0px 0px 0px auto;
}
.billed-to {
    width: 90%;
}
.ip-date.squ .date-picker input {
    font-size: 1rem;
    padding: 0rem;
}
.ip-date.squ .react-datetime-picker__inputGroup__leadingZero {
    font-size: 1rem  !important;
}
.ip-date.squ.date-field {
    padding: 0.4rem 1rem;
    border-radius: 0.2rem;
}
.ip-date.squ.date-field .react-datetime-picker {
    width: 100%;
}
.ip-date.squ.date-field .react-datetime-picker__button {
    opacity: 0.4;
}
.express-field {
    border: 1px solid transparent;
    border-radius:  0.2rem;
    outline: none;
    padding: 0.3rem;
    font-size: 1.4rem;
    line-height: 1.2rem;
    font-weight: 500;
    color: #414141;
}
.express-field:hover {
    border: 1px solid #e2e2e2;
}
.client-bill-info {
    border-radius: 0.3rem;
    background-color: #f7f7f7;
    padding: 1rem;
}
.info.tab-line {
    padding-left: 1rem;
    color: #6b6b6b;
}
.client-bill-info h4 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}
.client-bill-info .info {
    font-size: 0.85rem;
    color: #414141;
}

//*Billing Lisst

.bills-table.table thead th:first-child {
    width: auto;
}
.bills-table.table thead th:not(th:first-child), 
.bills-table.table tbody td:not(td:first-child) {
    width: 20%;
}
.bills-table.table tbody td h3{
    font-size: 0.9rem;
}
.bills-table .info{
    font-size: 0.9rem;
    color: #7a7a7a;
}

.bill-summary-overview {
    margin: 1rem auto;
}
.bill-summary {
    display: flex;
}
.quarter-badge {
    display: inline-block;
    margin-left: 0.5rem;
    background-color: #ebebeb;
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    position: relative;
    top: -0.1rem;
}
.bill-summary .activity {
    flex: 0 0 33%;
}
.bill-summary-overview.--client-view .bill-summary .activity {
    flex: 0 0 49.8%;
}
.bill-summary .activity label {
    font-size: 0.8rem;
    color: #7a7c80;
}
.bill-summary .activity.--unpaid {
    margin: 0rem 0.6%;
}
.bill-summary .activity .box {
    display: flex;
}
.bill-summary .activity .box .grp {
    flex: 0 0 50%;
    padding: 0.2rem 0.5rem;
    color: #fff;
    text-overflow: ellipsis;
}
.bill-summary .activity .box .grp .am {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
}
.bill-summary .activity .label-box{
    display: flex;
}
.bill-summary .activity .label-box .lbl{
    flex:  0 0 50%
}
.bill-summary .activity .box .grp .label {
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: uppercase;
    text-overflow: ellipsis;
}
.bill-summary .activity.--unbilled .estimate {
    background-color: #4ab5fc;
}
.bill-summary .activity.--unbilled .unbilled {
    background-color: #2d7dc9;
}
.bill-summary .activity.--unpaid .box .overdue {
    background-color: #ff9034;
}
.bill-summary .activity.--unpaid .box .openbills {
    background-color: #c5c5c5;
}
.bill-summary .activity.--paid .box .grp.paid {
    background-color: #84db20;
    flex: 0 0 100%;
}
.bills-table tbody .selection-cell {
    padding: 0.8rem 0.4rem !important;
}
.bills-table tbody .bill-details-content .highlight{
    font-weight: 600;
    color: #3693fd;
}
.bills-table tbody .bill-details-content .highlight.prev-balc {
    color: #ff9034;
}
.status-info-content .status {
    background-color: #edf0f1;
    padding: 0.2em 0.5rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    border-radius: 0.4rem;
    font-weight: 500;
    display: inline-block;
    width: 5.5rem;
    text-align: center;
    color: rgb(126, 133, 134);
}
.status-info-content .status.--overdue {
    background-color: #ffdbcd;
    color: #df4001;
}
.status-info-content .status.--viewed {
    background-color: #ffeec8;
    color: #df8805;
}
.status-info-content .status.--paid, 
.status-info-content .status.--approved  {
    background-color: #c8ffe6;
    color: #16bd6f;
}
.status-info-content .status.--sent {
    background-color: #d6f6ff;
    color: #10a5d3;
}
.add-btn-item {
    background-color: transparent !important;
    color: #2b2b2b !important;
    line-height: 0.9rem;
    font-weight: 600;
    text-decoration: none;
}
.add-btn-item .icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #797979;
    padding: 4px;
    margin-right: 0.4rem;     
}
.add-btn-item .icon img{
    opacity: 0.7;
}
.bill-line-btn {
    margin-top: 0.6rem;
}
.tax-line {
    display: flex;
}
.tax-line label{
    display: block;
}
.tax-line .form-l-grp {
    margin-right: 1rem;
}
.tax-line .form-l-grp input{
    border: 1px solid #dadbdb;
    outline: none;
    padding: 0.3rem 0.8rem;
    border-radius: 0.2rem;
}
.tax-line .form-l-grp input.tax-code {
    width: 5rem;
    text-transform: uppercase;
}  
table.bill-items-table {
    border-collapse: collapse;
    margin-bottom: 0.05rem;
    border: none;
    border: 1px solid #eaecee;
    border-radius: 0.3rem;
    width: 100%;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgb(235, 235, 235); /* this draws the table border  */ 
}
table.bill-items-table thead th:first-child {
    border-radius: 0.3rem 0rem 0rem 0rem;
}
table.bill-items-table thead th:last-child {
    border-radius: 0rem 0.3rem 0rem 0rem;
}
table.bill-items-table tbody tr:last-child  td:first-child {
    border-radius: 0rem 0rem 0rem 0.3rem;
}
table.bill-items-table tbody tr:last-child  td:last-child {
    border-radius: 0rem 0rem 0.3rem 0rem;
}
table.bill-items-table thead th {
    background-color: #f7f7f7;
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #414141;
    padding: 0.5rem;
}
table.bill-items-table tbody td {
    border-right: 1px solid #eaecee;
    width: 100%;
    padding: 0.4rem 0.6rem;
}
table.bill-items-table tbody td:last-child {
    border-right: none;
}
table.bill-items-table tbody tr:last-child td {
    border-bottom: none;
    border-radius: 0rem 0rem;
}

table.bill-items-table .th-number-cell, 
table.bill-items-table .id-number-cell{
    width: 50px;
}
table.bill-items-table .th-item-cell, 
table.bill-items-table .id-item-cell{
    width: 30%;
}
table.bill-items-table .id-item-cell,
table.bill-items-table .id-service-cell {
    padding: 0rem;
}
table.bill-items-table .ip-sml-select.items .select-placeholder  {
    padding: 0.5rem 0rem;
}
table.bill-items-table .ip-sml-select.items .selected-option.--default {
    padding: 0.5rem 0.5rem;
}
table.bill-items-table .ip-sml-select.items .selected-option,
table.bill-items-table .ip-sml-select.items .select-placeholder{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
table.bill-items-table .th-unitprice-cell, 
table.bill-items-table .id-unitprice-cell{
    width: 10%;
}
table.bill-items-table .th-service-cell, 
table.bill-items-table .id-service-cell{
    width: 21%;
}
table.bill-items-table .th-express-cell, 
table.bill-items-table .id-express-cell{
    width: 10%;
}
table.bill-items-table .th-fee-cell, 
table.bill-items-table .id-fee-cell{
    width: 10%;
}
table.bill-items-table .th-amount-cell, 
table.bill-items-table .id-amount-cell{
    width: 15%;
}

table .clear-ip-field {
    display: block;
    position: relative;
    width: calc(100%);
    border: 1px solid transparent;
    border-radius: 0rem;
    outline: none;
}
table .clear-ip-field:hover {
    
}
table td .due-info-content .--overdue{
    color: #d32d03;
}
table .ip-sml-select.item .select-control{
    border: none;
    background-color: transparent;
}
.bill-items-table tbody td .toolbar .action-menu .action-item .icon {
    opacity: 1;
}
.ip-sml-select.item .select-control span.icon {
    padding: 0.2rem 0.5rem;
}
.ip-sml-select.item .select-control {
    line-height: 1.6rem;
}
.ip-sml-select.item:hover .select-control {
    background-color: none;
}
.ip-sml-select.tax:hover .select-placeholder {
    background-color: none;
}
.ip-sml-select.item .select-placeholder-title {
    margin: 0rem 0.6rem;
}

.notes {
    resize: none;
    width: 100%;
    min-height: 7.5rem;
    border: 1px solid #ececec;
    padding: 0.8rem;
    font-size: 0.9rem;
    outline: none;
}

.total-box {
    width: 100%;
    min-height: 7.5rem;
    border: 1px solid #eeeeee;
    background-color: #f8f8f8;
    padding: 0.6rem;
    border-radius: 0.1rem;
    box-sizing: border-box;
}

.total-box .total-line {
    display: flex;
}

.total-box .total-line label, .total-box .total-line .value {
    width: 50%;
    font-size: 0.9rem;
    font-weight: 500;
    color: #585858;
    line-height: 0.9rem;
}

.total-box .total-line.tax label, .total-box .total-line.tax .value {
    font-size: 0.9rem;
    font-weight: 400;
    color: #929292;
}
.total-box .total-line .value {
    text-align: right;
    display: block;
    width: 50%;
}
.total-box .total-line.total {
    margin-top: 0.6rem;
}
.total-box .total-line.total label, .total-box .total-line.total .value {
    font-size: 1.25rem;
    font-weight: 700;
    color: #292929;
    text-transform: uppercase;
}
.buttons-section .btn-group{
    padding-bottom: 2rem;
    width: 100%;
    display: block;
    text-align: right;
}

.buttons-section .btn-group .btn{
    border: 1px solid;
    width: max-content;
    border-radius: 0.1rem;
    padding: 0.5rem 2rem;
    font-weight: 500;
    background-color: #86ca46;
    border-radius: 0.6rem;
}

.status-badge {
    display: block;
    padding: 0.3rem 0.8rem;
    background-color: #929292;
    position: absolute;
    top: 1rem;
    left: 0rem;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #fff;
}
.status-badge.--paid {
    background-color: #16bd6f;
}
.status-badge.--viewed {
    background-color: #929292;
}
.status-badge.--sent {
    background-color: #10a5d3;
}
.status-badge.--overdue {
    background-color: #df4001;
}

.bill-content .bill-view-wrap {
    padding: 2rem 0rem 1.5rem 0rem;
}               
.bill-content .print-area {
    padding-top: 1rem;
}
.bill-content .print-area .section {
    
    padding: 1rem 2rem;
}
.client-details-section {
    border-bottom: 1px solid rgb(240, 240, 240);
}
.bill-items-section  { }
.bill-info h3{
    margin-bottom: 1.3rem;
    font-size: 1.5rem;
    color: #2b2b2b;
}
.bill-segment label.title {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #acacac;
    font-weight: 700;
}
.bill-segment h3 {
    font-size: 1rem;
}
.bill-items-section .list-items .head  {
    background-color: #f5f6f8;
    padding: 0.9rem 0rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #7a7a7a;
}
.bill-items-section .list-items .head,
.bill-items-section .list-items .body  {
    display: flex;
}

.bill-items-section .list-items .head .col,
.bill-items-section .list-items .body .col { }
.bill-items-section .list-items .head .col.--count,
.bill-items-section .list-items .body .col.--count {
    flex:  0 0 100px;
}
.bill-items-section .list-items .head .col.--items,
.bill-items-section .list-items .body .col.--items { }
.bill-items-section .list-items .head .col:first-child,
.bill-items-section .list-items .body .col:first-child {
    padding-left: 2rem;
}
.bill-items-section .list-items .head .col:last-child,
.bill-items-section .list-items .body .col:last-child {
    padding-right: 2rem;
}
.bill-items-section .list-items .body .col:last-child {
    font-weight: 500;
    color: #414141;
}
.bill-view-wrap .info-group {
    margin-bottom: 1.4rem;
}
.bill-view-wrap .info-group label {
    font-size: 0.9rem;
    font-weight: 500;
    color: #acacac;
}
.bill-view-wrap .info-group p {
    font-size: 1.1rem;
    font-weight: 400;
    color: #353535;
    line-height: 0.9rem;
    margin-bottom: 0.5rem;
}
.compy-details h1 {
    color: #2285f7;
    font-size: 1.9rem;
}
.bill-view-wrap .info-group span.due-date {
    font-size: 0.8rem;
    color: #fd0000;
    line-height: 0.9rem;
    display: inline-block;
    border-radius: 0.3rem;
    margin-top: 1rem;
    font-weight: 500;
    padding: 0.4rem 0.6rem;
    text-transform: uppercase;
    background-color: #ffece9;
    word-spacing: -0.01rem;
}
.bill-view-wrap .info-group.--balance-due p {
    font-size: 1.5rem;
    font-weight: 600;
}

.bill-view-wrap .text-line {
    font-size: 0.9rem;
    font-weight: 700rem;
    color: #9c9c9c;
    line-height: 1.8rem;
}

.bill-items-section .list-items .body.--line {
    border-bottom: 1px solid rgb(245, 245, 245);
    padding: 0.5rem 0rem;
    color: #585858;
}
.bill-message {
    padding: 1rem 2rem;
    margin: 2rem 0rem 1rem 0rem;
    border-top: 1px dashed #e0e0e0;
}
.bill-message p {
    color: #585858;
    font-size: 0.9rem;
}
.bill-create input[disabled], .bill-create textarea[disabled] {
    border-color: transparent  !important;
    background: transparent;
}
.bill-create .select-container .select-control.--disabled  {
    border-color: transparent  !important;
    background: transparent;
    cursor: initial;
}
.bill-create .select-container:hover .select-control.--disabled .selected-option  {
    background: transparent !important;
}
.bill-create .select-container .select-control.--disabled .icon {
    visibility: hidden;
}

.bill-view-wrap .info-group p.--base-charge {
    font-size: 1.1rem;
    font-weight: 500;
}

.bill__loader-wrap{
    display: flex;
    height: 70%;
}


// View Payments Modal
.view-payments__title-wrapper{
    display: flex;
    justify-content: space-between;
}

.view-payments__title-wrapper .modal__close-wrapper{
    cursor: pointer;
    margin-left: 24px;
    position: relative;
    display: flex;
    align-items: center;
    opacity: 1;
    padding: 0;
}

.bill-payments{
    padding: 4.3rem 2rem 0;
}

.bill-payments__list{
    box-shadow: none;
}

.bill-payments__payment-wrapper{
    display: grid;
    grid-template-columns: 0.8fr 3fr 3fr 1.3fr;
    gap: 1.3rem;
}

.bill-payment--amount-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 2rem;
}

.bill-payments__list .bill-payments__payment-wrapper{
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 1.3rem;
}

.bill-payments__list .bill-payment--amount-wrapper.--unverified .payment-detail__primary-text, 
.bill-payments__list .bill-payment--amount-wrapper .payment-details__unverified{
    color: #f48700;
}


.bill-payments__list .bill-payments__payment-wrapper.list-group-item{
    border: 1px solid #ececec;
    margin-bottom: 0.2rem;
}

.bill-payment__icon-wrapper{
    width: 2.6rem;
    height: 2.6rem;
    margin-bottom: 0.3rem;
}

.bill-payment__icon-wrapper img{
    opacity: 0.25;
}

.bill-payment__status-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.payment-detail__sm-text,
.payment-detail__med-text {
    color: #727070;
}

.payment-detail__sm-text{
    font-size: 0.8rem;
}

.payment-detail__med-text{
    font-size: 1rem;
}

.payment-detail__title{
    font-weight: 600;
    margin-bottom: 0.7rem;
}

.bill-payments__list .payment-detail__client-name{
    font-size: 1.2rem;
}

.bill-payments__list .payment-detail__primary-text {
    margin: 0;
    font-size: 1.4rem;
}

.bill-payments__list .payment-detail__ref-text{
    font-weight: 500;
    margin: 0;
    font-size: 1.2rem;
}

.editable-list__wrapper{
    position: relative;
}

.editable-list{
    border: 1px solid #d8d8d8;
    position: absolute;
    top: 100%;
    right: 0rem;
    width: 100%;
    max-height: 12rem;
    background-color: #fff;
    border-radius: 0.2rem;
    box-shadow: 0 2px 4px #00000018;
    z-index: 9;
    overflow-y: scroll;
}

.editable-list .editable-list__option{
    padding: 0.4rem 0.6rem;
    text-transform: initial;
}

.editable-list .editable-list__option i {
    color: #858585;
    pointer-events: none;
    font-style: normal;
    margin-left: 0.1rem;
}

.editable-list .editable-list__option .tax-name {
    color: #797979;
    pointer-events: none;
    font-size: 0.9rem;
}

.editable-list .editable-list__option:hover{
    background-color: #f8f8f8;
}
.hide-list{
    display: none;
}

.tax-holder .bill__loader-wrap > div{
    margin-left: 0;
    margin-right: 0;
}