.content-layout-wrap.master-detail .aside-list {
    flex: 0 0 35%;
    width: 35%;
    position: relative;
    height: 100%;
}
.content-layout-wrap.master-detail .add-btn{
    margin: 0.6rem 0.5rem 1rem 1rem;
}
.aside-list .list-view {
   overflow-y: scroll;
   height: calc(100vh - 215px);
   padding: 0rem;
}
.view-layout-content.detail-view .tab-nav {
   width: 95%;
   margin: 0rem auto;
   background-color: #fff;
   position: sticky;
   top: 0px;
   background-color: #fff;
}
.action-section {
    padding: 0.55rem 0.5rem;
}
.action-section .add-btn .icon {
    width: 1.7rem;
    display: inline-block;
    margin-right: 0.4rem;
    pointer-events: none;
}
.action-section .add-btn {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: normal;
    display: inherit;
}
.detail-ly-ct {
    overflow-y: scroll;
    height: 100%;
    position: relative;
    border-left: 1px solid rgb(223, 223, 223);
}
.list-items .list-item{
    border-top:  1px solid #eeeeee;
    height: 4rem;
    padding: 0.4rem 1rem;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-out;
    overflow: hidden;
}
.list-items .list-item.--selected  {
    background-color:  #f2f9ff;
}
.list-items .item .list-item:hover {
    background-color:  #f7f7f7;
}
.employee-list .item-content{
    display: block;
}

.pay-period-group {
    padding-left: 1.5rem;
}
.pay-period-group .label {
    display: block;
}
.form-group .ip-checkbox.--fixed-salary {
    display: inline-block !important;
    font-size: 1.2rem;
}
.employee-list .item-content .employee {
    display: flex;
}

.employee-list .item-content .employee .picture{
    max-width: 3.2rem;
    max-height: 3.2rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 50%;
    pointer-events: none;
    border: 1px solid #f8f8f8;
} 


.employee-list .employee  .employee-snippet {
    flex: 0 0 45%;
}
.employee-list .employee  .employee-snippet,
.employee-list .employee  .details {
    padding-left: 1rem;
}
.employee-list .employee  .employee-snippet h3,
.employee-list .employee  .details h3{
    font-size: 1.1rem;
    color: #3a3a3a;
}
.employee-list .employee  .details h3{
    font-size: 1rem;
}
.employee-list .employee  .employee-snippet p,
.employee-list .employee  .details p{
    color: #949494;
    text-overflow: ellipsis;
    font-size: 0.9rem;
}
.employee-list .item.--terminated h3 {
    color: #e75c0c;
}
.employee-list  .employee  .employee-snippet p .label,
.employee-list .employee  .details p .label{
    margin-right: 0.5rem;
}
.emplye-overview .inner {
    width: 75%;
    margin: 0rem auto;
}
.emplye-overview .sec-content .info-line  {
    border-bottom: 1px solid #f5f5f5;
    margin-left: 0.4rem;
}
.emplye-overview .sec-content .info-line .label {
    flex: 0 0 30%;
}
.emplye-overview  .sec-content .info-line .value {
    width: 100%;
    text-align: right;
}

.emplye-overview .sumry-item .days{
        font-size: 2rem;
        font-weight: 300;
        color: #adadad;
}
.emplye-overview .sumry-item .days .subt-count{
    color: #2793f8;
    font-weight: 700;
}
.emplye-overview .sumry-item .days .count{
    color: #707070;
    font-weight: 700;
}
.quickaction {
    position: absolute;
    width: 10rem;
    height: 100%;
    top: 0rem;
    right: 0rem;
    background: linear-gradient(to right, rgba(243, 243, 243, 0), #f9f9f9bd, #f9f9f9);
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    display: none; 
}
.list-items .item:hover .quickaction {
    display: block; 
}
.quickaction .action-menu {
    display: flex;
    position: absolute;
    left: auto;
    right: 0.8rem;
    top: 0rem;
    bottom: 0rem;
    align-items: center;
}

.quickaction .action-menu .action-item {
    height: inherit;
    display: flex;
    flex-direction: row;
}

.quickaction .action-menu .action-item a {
    padding: 0.4rem;
    margin: 0.9rem 0.2rem;
    border-radius: 50%;
}
.quickaction .action-menu .action-item a:hover {
    background-color: #ececec;
}

.quickaction .action-menu .action-item .icon {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    pointer-events: none;
    opacity: 0.8;
}

.master-detail.employees .tab-content{
    padding-bottom: 9rem;
    padding-top: 1rem;
}
.master-detail.employees .react-bootstrap-table-pagination{
    position: relative;
    margin: 0rem;
}
.pay-advice-table .selection-cell-header {
    width: 2.5rem;
}
.pay-advice-table .th-payroll-cell,  
.pay-advice-table .id-payroll-cell {
    width: 18%;
}
.pay-advice-table .th-earnings-cell,  
.pay-advice-table .id-earnings-cell {
    width: 20%;
}

.pay-advice-table .th-deductions-cell,  
.pay-advice-table .id-deductions-cell {
    width: 20%;
}
.pay-advice-table .th-grosspay-cell,  
.pay-advice-table .id-grosspay-cell {
    width: 19%;
}
.pay-advice-table .th-netpay-cell,  
.pay-advice-table .id-netpay-cell {
    width: 19%;
}

.pay-advice-table .info .highlight  {
    color: #2793f8;
}
.pay-advice-table .info .label  {
    color: #8d8d8d;
    margin-right: 0.4rem;
}
.pay-advice-table .info .dedcution  {
    color: #f85127;
}
.pay-advice-table td *{
    font-weight: 500;
}

.no-selected {
    display: block;
    text-align: center;
    margin: 2rem;
    font-size: 1.5rem;
    color: #9c9c9c;
}