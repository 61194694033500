.pull-xs-right {
    float: right!important;
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: row;
}
.navbar-brand {
    font-family: titillium web,sans-serif;
    font-size: 1.5rem!important;
    padding-top: 0!important;
    margin-right: 2rem!important;
    color: #5cb85c!important;
}
.navbar-nav .nav-item {
    float: left;
    padding: 0.4rem 0rem;
    color: rgb(158, 158, 158);
}
.dashboard-content .navbar-nav{
    margin-top: 2rem;
    margin-right: 1rem;
}
.btn.logout {
   margin: 0rem;
   margin-left: 1rem;
   padding: 0.3rem 1rem; 
   line-height: 1.4rem;
}
.float-column > .card {
    flex: 1 1 auto;
    margin-bottom: 15px;
}
.dashboard-title {
    padding-left: 1rem;
    margin: 2rem 0rem 2rem 0rem;
    font-size: 1.8rem;
    font-weight: 500;
    color: #333435;
}   
.dashboard-content .card-header {
    font-size: 0.9rem !important;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0.6rem;
    color: #333435;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #FFF;
    background-clip: border-box;
    border: 1px solid rgba(31, 45, 61, 0.041);
    box-shadow: none;
    border-radius: 0.2rem;
    overflow: hidden;
}
.card .card-header .media-body,
.card .card-footer .media-body{
    font-size: 0.8rem;
    color: #8493A5;
    line-height: 1.2rem;
}

.card .card-header {
    border: none;
    background-color: transparent;
    padding: 0.2rem 0.8rem;
    font-size: 1rem;
    color: rgb(67, 67, 68);
}
.card .card-header .media-body .h6 {
    font-size: 1rem;
    color: rgb(67, 67, 68);
}
.card .card-footer {
    background-color: rgba(53, 56, 59, 0.082);
}
.card.--center-chart .recharts-wrapper {
    margin: auto;
}

.card .info span {
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.card .info  {
    margin-bottom: 0.5rem;
}
.card .info span i {
    font-weight: 500;
    color: #abb0b6;
    margin-left: 0.5rem;
}
.card .info * {
    font-style: normal !important;
}
#card-01 .metric-group {
    margin-bottom: 2rem;
}
#card-01 .metric-group .pt-4 {
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 1.3rem;
    font-weight: 600;
    padding-top: 1rem !important;
}

#card-03 h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    text-align:  center;
}
#card-02 h3 {
    font-size: 1.2rem;
    text-align:  center;
}
#card-04 .stripe-bar-chart {
    margin-bottom: 2.4rem;
}
#card-04 .stripe-bar-chart .details h4 {
    font-size: 1.3rem;
    font-weight: 600;
}

.donut-chart-group {
    display: flex;
    margin-top: 1.4rem;
}

.donut-chart-group .chart-legends label{
    margin-bottom: 0.6rem;
    width: 100%;
    padding-left: 0.6rem;
    text-overflow: ellipsis;
}
.donut-chart-group .chart-legends label span{
    display: block;
}
.donut-chart-group .chart-legends label .value{
    font-size: 1rem;
    font-weight: 600;
}
.donut-chart-group .chart-legends label .lbl{
    color: #abb0b5;
}

.count-chart .icon {
    height: 2rem;
    width: 2.6rem;
    display: inline-block;
}
.count-chart-card {
    height: 7.5rem;
    margin-bottom: 0.4rem;
}
.count-chart-card .count-chart.single  {
    display: flex;
    height: 100%;
}
.count-chart-card .count-chart.single .icon-box{
    width: 5rem;
    border-right: 1px solid rgb(228, 228, 228);
    display: flex;
    justify-content: center;
    align-items: center;
}
.count-chart-card .count-chart.single .details {
    padding: 0rem 1rem
}
.count-chart-card .count-chart.single .details .main-info{
    margin: 0px;
    text-align: left;
}
.count-chart-card .count-chart.single .details .count{
    font-weight: 600;
    font-size: 1.3rem;
    line-height: normal;
}
.count-chart-card .count-chart.single .details .secondary-info{
    color: #8493A5;
}

.count-chart-card .card-header {
    font-size: 0.7rem;
    padding-bottom: 0.5rem;
    color: #494949;
}
.count-chart-card.split .card-body  {
    padding-top: 0rem;
}
.count-chart-card.split .count-chart .details  {
    display: flex;
    justify-content: space-between;
}

.count-chart-card.split .count-chart .details .label {
    position: relative;
    top: 0.3rem;
    display: inline-block;
    left: 0.2rem;
}

.count-chart-card.split .count-chart .icon {
    width: 2rem;
    width: 1.5rem;
}
.count-chart-card.split .bar {
    display: flex;
    border-radius: 0.2rem;
    overflow: hidden;
    height: 1.5rem;
    background-color: #f7f8f8;
}
.count-chart-card.split  .bar > div {
    width: 50%;
    padding: 0rem 0rem;
    color: #FFF;
    font-weight: 600;
    transition: width 0.5s linear;
}
.count-chart-card.split  .bar > div .count{
   margin: 0.5rem;
}
.count-chart-card.split  .bar > div.bar-1 {
    background-color: #3F97EF;
}
.count-chart-card.split  .bar > div.bar-2 {
    background-color: #79B6E8;
    text-align: right;
}

.dashboard-content .chart-list-items .card-body {
    padding-top: 0.2rem;
}

.dashboard-content .chart-list-items .chart-list .chart-item {
    padding: 0.5rem 0rem;
    position: relative;
}

.dashboard-content .chart-list-items .chart-list .chart-item .info-lines {
    padding-top: 0.2rem;
}
.dashboard-content .chart-list-items .chart-item .item-info h4 {
    font-size: 1rem;
}
.dashboard-content .chart-list-items .chart-item .info-lines p .label,
.dashboard-content .chart-list-items .chart-item .info-lines p .value {
    font-size: 0.9rem;
}
.dashboard-content .chart-list-items .chart-item .item-info h4 {
    line-height: 0.9rem;
}

.dashboard-content .chart-list-items .chart-item .pmt-date {
    position: absolute;
    top: 0.1rem;
    right: 0rem;
    font-size: smaller;
    color: #8493A5;
    letter-spacing: -0.05rem;
    font-weight: 500;
}

.chart-list {
    height: 100%;
}
.chart-list  .no-entry {
    display: flex;
    color: rgb(157, 161, 165);
    text-align: center;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.dashboard-content #card-04 .stripe-bar-chart.color-green .inner-bar {
    background-color: #31aa1d;

}
.dashboard-content #card-04 .stripe-bar-chart.color-orange  .inner-bar {
    background-color: #fb8c2c;
}

.dashboard-content .color1 {color: #228B8C}
.dashboard-content .color2 {color: #2BAEAE}
.dashboard-content .color3 {color: #32C8C7}
.dashboard-content .color4 {color: #37DCDD}

.pie-row .pie-wrap > div {
    margin: 0 auto;
}
