@import "./../../assets/styles/mixins/global-mixins";
.client-loader {
    padding: 10% 0rem;
}
.client-list .default-Ly-ct {
    //padding-bottom: 12.5rem;
}
.summay-details {
    margin: 1rem auto 2rem auto;
}
.summay-details .ratio-holder {
    position: relative;
}
.summay-details .ratio-holder .ratio-count{
    display: flex;
    justify-content: space-between;
}
.summay-details .ratio-holder .ratio-count span{
    font-size: 1.5rem;
    font-weight: 600;
}
.summay-details .ratio-holder .ratio-count span i{
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}
.summay-details .ratio-holder .ratio-count .male-count {
    color: #0f9cf2;
}
.summay-details .ratio-holder .ratio-count .female-count {
    color: #ff87b2;/* #f0ca44 */
}
.summay-details .ratio-holder .ratio-line {
    display: flex;
    border-radius: 0.4rem;
    overflow: hidden;
    height: 1.2rem;
    background-color: #f7f7f7;
    
}
.summay-details .ratio-holder .ratio-line .r-line {
    height: 100%;
    width: 50%; 
    transition:  background-color 0.15s linear;
}

.summay-details .ratio-holder .ratio-line .male-line.--visible {
    background-color: #0f9cf2;
}
.summay-details .ratio-holder .ratio-line .female-line.--visible {
    background-color: #ff87b2;
}
.patients-table thead tr th {
    width: auto;
}
.recent-patient {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}
.recent-patient .recent-list {
    display: inline-block;  
    position: relative;
}
.recent-patient .recent-list li {
    display: inline-block; 
    position: relative;
    width: 2.5rem;
}
.recent-patient .recent-list li .wrapper {
    display: block; 
    position: relative;
}
.recent-patient .recent-list li .patient {
    border: 2px solid rgb(252, 252, 252);
    background-color: rgb(250, 250, 250);
    display: block;
    width: 3rem;
    height: 3rem;  
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    top: 0rem;
    right: 0rem;
    box-shadow: -3px 1px 2px #00000015;
    cursor: pointer;
    @include disable-user-select();
}
.recent-patient .recent-list li .patient img {
    max-width: 3rem;
    max-height: 3rem;  
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 50%;
    @include disable-user-select();
}

.recent-patient .recent-list li .patient-holder {
    
}
.recent-patient .recent-list li .patient-holder .name {
    position: absolute;
    line-height: 1rem;
    border-radius: 0.2rem;
    background-color: #3d3d3d;
    color: #f1f1f1;
    bottom: -1.8rem;
    left: 50%;
    display: none;
    font-size: 0.8rem;
    padding: 0.1rem 0.4rem;
    width: max-content;
    font-weight: 500;
    z-index: 3;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.recent-patient .recent-list li .patient-holder:hover .patient {
    transform: scale(1.3);
    z-index: 2;
    box-shadow: 0px 1px 6px #0000001a;
}
.recent-patient .recent-list li .patient-holder:hover  span.name{
    display: block;
    
}

.recent-patient .recent-list li .patient img{
    pointer-events: none;
}
table.patients-table th.th-patient-cell, table.patients-table td.id-patient-cell {
    flex: 0 1 30%;
}
table.patients-table th.th-client-cell, table.patients-table td.id-client-cell {
    flex: 0 1 30%;
}
table.patients-table th.th-details-cell, table.patients-table td.id-details-cell {
    flex: 0 1 40% !important;
}
table.patients-table  tbody td .picture-holder {
    margin-right:0.8rem;
}
table.patients-table  p.gender-info, table.patients-table  p.info {
    font-size: 0.9rem;
    color: #969696;
}

.patients-table .patient-info {
    display: flex;
}
.patients-table tbody h3 {
    font-size: 1.1rem;
    margin-bottom: 0rem;
    color: #525151;
    font-weight: 400;
    text-overflow: ellipsis;
}
.patients-widgets-list {
    margin-top: 2rem
}

.tab-content-area .nav-tabs {
    border-bottom: none;
}
.tab-content-area .nav-tabs .nav-link {
    border: none;
    background-color: transparent;
}

.tab-content-area .nav-tabs .nav-item .nav-link.active {
    border-bottom: 2px solid #0f9cf2;
}
.overview-containter {
    //height: inherit;
    overflow-x: hidden;
}
.patient.section {
    padding-top: 1rem;
}
.overview-containter .row {
    height: inherit;
}
.overview-containter .tab-nav {
    width: 95%;
    margin: 0.3rem auto;
}
.overview-containter .main-content {
    background-color: #fff;
    border-radius: 3rem 0rem 0rem 3rem;
}
.details-sec-container .section {
    margin: 1rem 0rem 1.5rem 2rem;
}
.patient-card, .address-card {
    border-radius: 0.8rem;
    background-color: #fff;
    border: 1px solid #e7e8e9;
}
.patient-card .inner {
    padding: 1rem 2rem;
}
.address-card {
    padding: 1rem;
}
.address-card p {
    color: #a7a7a7;
}
.address-card p span {
    font-weight: 500;
    color: #3a3a3a;
}
.main-info .picture {
    height: 8rem;
    width: 8rem;
    margin: 0.5rem auto;
    overflow: hidden;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    pointer-events: none;
}
.main-info .picture img {
    max-height: 8rem;
    max-width: 8rem;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.main-info  {
    text-align: center;
    margin-bottom: 1rem;
}
.main-info .name  {
    font-size: 1.2rem;
    color: #525151;
}
.main-info  {
    text-align: center;
    margin-bottom: 1rem;
}
.main-info p  {
    color: #0f9cf2;
    font-weight: 500;
}
.sec  {
    border-top: 1px solid rgb(241, 241, 241);
    padding: 0.5rem 0rem;
}
.sec .info-line {
    display: flex;
    margin: 0.2rem 0rem;
}
.sec .info-line label{
    color: #a7a7a7;
    flex: 0 1 40%;
}
.sec .info-line .age-info{
    margin-right: 0.4rem;
}
.sec .info-line .age-info i{
    font-size: 0.8rem;
    font-style: normal;
    margin-left: 0.15rem;
    display: inline-block;
    color: #0f9cf2;
}
.sec .info-line .attr{
    color: #0f9cf2;
}
.address-card address{
    color: #696969;
}
.address-card address p{
    margin-bottom: 0.5rem;
}
.content-pane .section {
    padding: 0rem 2rem !important;
    margin: 1rem auto;
}
.content-pane .section h2 {
    font-size: 1.2rem;
    color: #3a3a3a;
}
.bill-section.section {
    margin-top: 2rem;
}
.ratio-widget {}
.ratio-widget .ratio-line{
    display: flex;
}
.ratio-widget .ratio-count{
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.01rem;
    color: #525151;
}
.ratio-widget .ratio-count .left-count{
    color: #0dc41c;
}
.ratio-widget .ratio-line .line-bar{
    background-color: #e9e9e9;
    width: 100%;
    height: 1.2rem;
    border-radius: 0.4rem;
    overflow: hidden;
    
}
.ratio-widget .ratio-line .inner-bar {
    background-color: #0dc41c;
    width: 0%;
    display: block;
    height: inherit;
    border-right: 1px solid #fff;
    transition: width 1s ease-out;
    -webkit-transition: width 1s ease-out;
    -o-transition: width 1s ease-out;
    -moz-transition: width 1s ease-out;
}

.ratio-widget .bar-label {
    display: flex;
    justify-content: space-between;
    color: #868686;
    font-size: 1rem;
}
.apptmt-list {
    margin: 2rem 0rem;
}
.apptmt-list .apptmt-item {
    position: relative;
}
.apptmt-list .apptmt-item .status-bar {
    width: 0.3rem;
    height: 3.2rem;
    border-radius: 0.5rem;
    background-color: #e7e7e7;
    position: absolute;
    display: block;
    bottom: 0.1rem;
}
.apptmt-list .apptmt-item .apptmt-cont {
    display: flex;
    padding: 1rem 0rem;
    padding-left: 1rem;
    border-bottom: 1px solid rgb(235, 235, 235);
}
.apptmt-list .apptmt-item .apptmt-cont span{
    flex: 0 0 50%;
}
.apptmt-list .apptmt-item .apptmt-cont .status {
    font-size: 1.2rem;
    font-weight: 500;
    color: #696969;
}
.apptmt-list .apptmt-item .apptmt-cont .date {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: #696969;
}
.apptmt-list .apptmt-item-box.--confirm  .status-bar {
    background-color: #0f9cf2;
}
.apptmt-list .apptmt-item-box.--confirm  .status {
    color: #0f9cf2;
}

.rect-rec-list {
    margin: 2rem 0rem;
    border-top: 1px solid rgb(240, 240, 240);
}
.rect-rec-list .record {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 0.3rem;
}
.rect-rec-list .rec-item {
    border-bottom: 1px solid rgb(240, 240, 240);
}
.rect-rec-list .doctor-info {
    flex: 0 0 30%;
    padding-left: 1rem;
}
.rect-rec-list .details {
    flex: 0 0 40%;
}
.rect-rec-list .status {
    flex: 0 0 20%;
}
.picture-holder {
    min-width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background: #f1f1f1;
    display: table;
}
.picture-holder .picture-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-weight: 600;
    color: #bdbdbd;
    font-size: 1.2rem;
}
.client-count {
    width: 22px;
    height: 22px;
    background-color: #e7e7e7;
    border-radius: 50%;
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 0.55rem;
    padding: 0.4rem 0.2rem;
    text-align: center;
    letter-spacing: -0.1rem;
    display: inline-block;
    position: relative;
    top: -0.2rem;
}
.content-section.patient {
    background-color: #fff;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}

.gender-group {
    padding-left: 1.5rem;
}

.section .section-head h3 {
    font-size: 1.3rem;
    font-weight: 400;
    display: inline-block;
    margin: 0rem;
    padding-right: 2rem;
    padding-left: 0.6rem;
    padding-bottom: 0.2rem;
}

.section .section-head {
    border-bottom: 1px solid #f1f1f1;
}
.section .section-head.simple {
    border-bottom: none;
}
.add-patient-holder {
    padding: 1rem 1.4rem;
}
.add-patient-holder .add-address-btn {
    width: 100%;
    padding: 0.8rem;
    background-color: transparent;
    color: #747474 !important;
    border: 2px dashed #dfdfdf !important;
}

.add-patient-holder .add-address-btn:focus, 
.add-patient-holder .add-address-btn:active{
    background-color: #f5f5f5 !important;
}

.add-patient-holder .add-address-btn span.icon {
    display: block;
    opacity: 0.4;
}
.add-patient-holder .add-address-btn span.icon img{
    height: 2rem;
}

.rqt-modal-wrap .rqt-modal-content.patient-modal{
    width: 100%;
    max-width: 50%;
}

.rqt-modal-wrap .rqt-modal-content.patient-modal .title-area .icon img{
    filter: invert(100);
}

.rqt-modal-wrap .rqt-modal-content.patient-modal form {
    width: 100%;
}

.rqt-modal-content.patient-modal .bottom-section {
    padding: 1.5rem 4.5rem;
}