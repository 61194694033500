.dd-wrapper {

  button {
    overflow: visible;
    width: inherit;
    margin: inherit;
    padding: inherit;
    border: none;
    background: inherit;
    font: inherit;
    line-height: normal;
    color: inherit;
    text-align: inherit;
    -webkit-appearance: none; }

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none; }

  p {
    margin: 0;
    padding: 0; }

  * {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility; } }
