

.pymt-Ly-ct {
    padding-right: 0rem;
    overflow-y: scroll;
    height: 100%;
    position: relative;
}

.payment-modal  .slide-pane {
    background-color: #f5f5f5;
}
.payments--overview .view-layout-content {
    padding-bottom: 0rem;
}

.payment-summary-overview  .title {
    margin-top: 1rem;
}
.payment-summary {
    display: flex;
    margin-bottom: 1rem;
}
.payment-summary .activity {
    flex: 0 0 32%;
}

.payment-summary .activity.--payment {
    margin: 0rem 1.7%;
}
.payment-summary .card {
    border: none;
    box-shadow: 0px 1px 2px 0px #0e0f101a;
}
.card .card-top {
     background: #ebf1f3;
    padding: 0.5rem;
}
.card .card-top {
    padding: 0.5rem;
}
.payment-summary-overview .activity.--revenue .card .card-top  {
    background: #0195cd;
}
.payment-summary-overview .activity.--revenue .card .card-top  * {
    color: #fff;
}
.activity .card .card-top * {
    color: #4e6677;
}

.payment-overview .card{
    border-radius: 0rem;
    box-shadow: none;
}
.payment-overview .card-header .title{
    font-weight: 600;
    font-size: 1.6rem;
    margin-top: 1rem;
    color: #313131;
    line-height: 1rem;
}
.payment-overview .card-header .sub-info{
    font-weight: 600;
    font-size: 0.9rem;
    margin-top: 1rem;
    color: #c5c5c5;
    line-height: 1rem;
}
.card .card-top .title  {
    font-size: 0.8rem;
    margin-top: 0rem;
}
.card .card-top .body  {
    padding-top: 0.6rem;
}
.card .card-bottom {
    padding: 0.5rem
}
.card .card-top .body h2,
.card .card-bottom h2 {
    font-size: 1.25rem;
    margin: 0rem;
    font-weight: 500;

}
.card .card-top .body p,
.card .card-bottom p {
    font-size: 0.75rem;
    text-transform: uppercase;
}
.card .card-bottom h2 {
    color: #4e6677;
}
.card .card-bottom p {
    color: #a2a2a2;
    font-weight: 400;
}

.chart-list {
}
.chart-item {
    border-bottom: 1px solid #efefef;
    padding: 0.8rem 0rem
}

.chart-item .item-info h4{
    font-size: 1.1rem;
}

.chart-item .item-info span{
    font-size: 0.8rem;
    font-weight: 500;
    color: #a1a1a1;
}

.chart-item .info-lines{
    padding-top: 0.5rem;
}

.chart-item .info-lines p{
    display: flex;
    justify-content: space-between;
    color: #a1a1a1;
    line-height: 1.2rem
}
.chart-item .info-lines p .value{
    color: #484848;
    font-weight: 600;
}
.chart-item .info-lines p .label{
    color: #484848;
}
.slide-pane .slide-pane__title {
    font-size: 1.4rem;
    font-weight: 500;
}

.insight-panel {
    height: 17rem;
    padding: 1rem 2.5rem;
}
.insight-panel .top-sec .title{
    font-size: 1.1rem;
    font-weight: 600;
}
.insight-panel .content-sec {
    border-bottom: 1px solid rgb(235, 235, 235);
}
.insight-panel .content-sec .inner {
    display: flex;
    padding: 1.3rem 0rem 0.6rem 0rem;
}
.insight-panel .content-sec .is-col {
    flex: 0 0 33.333%;
}
.insight-panel .content-sec .is-col .mc{
    margin: 0rem;
    line-height: 1.5rem;
    font-weight: 700;
    font-size: 1.5rem;
}

.insight-panel .content-sec .is-col.revenue .mc {
    color: #34aa20;
}
.insight-panel .content-sec .is-col.revenue label {
    color: #1b8b08;
    font-weight: 400;
}
.insight-panel .content-sec .is-col.collected .mc {
    color: #F96201;
}
.insight-panel .content-sec .is-col.collected label {
    color: #ce4f00;
    font-weight: 400;
}

.insight-panel .content-sec .is-col.uncollected .mc {
    color: #D80000;
}
.insight-panel .content-sec .is-col.uncollected label {
    color: #ac0c0c;
    font-weight: 400;
}
.insight-panel .bottom-sec {
    padding-top: 0.6rem;
}
.insight-panel .bottom-sec .btm-cont {
    display: flex;
    margin-top: 0.7rem;
}

.insight-panel .bottom-sec .chat  .details h4 {
    font-size: 0.95rem;
    font-weight: 700;
    margin: 0;
}
.stripe-bar-chart .chat  .details span {
    font-size: 0.85rem;
    font-weight: 500;
    color: #a1a1a1;
}

.stripe-bar-chart {
    width:100%;
    margin-right: 0.6rem;
}

.insight-panel .stripe-bar-chart {
    width: 35%;
    margin-right: 0.6rem;
}

.stripe-bar-chart .chat  .chat-bar{
    width: 100%;
    height: 1.5rem;
background-image: linear-gradient(45deg, #ffffff 25%, #e9e9e9 25%, #e9e9e9 50%, #ffffff 50%, #ffffff 75%, #e9e9e9 75%, #e9e9e9 100%);
background-size: 11.31px 11.31px;
    border-radius: 0.3rem;
}

.stripe-bar-chart.lf-chart .chat  .chat-bar {
    background-image: linear-gradient(45deg, #ffffff 33.33%, #31aa1d 33.33%, #31aa1d 50%, #ffffff 50%, #ffffff 83.33%, #31aa1d 83.33%, #31aa1d 100%);
    background-size: 8.49px 8.49px;
}
.stripe-bar-chart.rt-chart .chat  .chat-bar {
    background-image: linear-gradient(45deg, #ffffff 33.33%, #2ab0ad 33.33%, #2ab0ad 50%, #ffffff 50%, #ffffff 83.33%, #2ab0ad 83.33%, #2ab0ad 100%);
    background-size: 8.49px 8.49px;
}
.stripe-bar-chart .chat  .chat-bar .inner-bar{
    width: 0%;
    height: 100%;
    border-radius: 0.2rem 0rem 0rem 0.2rem;
    transition: width 0.8s ease-out;
}
.stripe-bar-chart.lf-chart .chat  .chat-bar .inner-bar{
    background-color:  #31aa1d;
background-size: 56.57px 56.57px;
    background-color: #31aa1d;
}
.stripe-bar-chart.rt-chart .chat  .chat-bar .inner-bar{
    background-color: #2ab0ad;
}

.pay-POS{
    position: relative;
}

.payment__loader-container{
    display: flex;
    align-items: center;
}
.payment__dialog-container{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
}

.payment__dialog-container .prompt-wrap{
    top: 5%;
}