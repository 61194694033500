$placeholder-color: #c7cacc;
form ::-webkit-input-placeholder { /* Edge */
    color: $placeholder-color;
}
form :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholder-color;
}
form ::placeholder {
    color: $placeholder-color;
}

.ip-text-field {
    outline: none;
    border: none;
}
.ip-text-default {
   border-radius: 10px;
   outline: none;
   border: 1px solid rgb(218, 219, 219);
   display: block;
   padding: 0.8rem 1rem;
   font-size: 1.1rem;
   width: 100%;
   color: #353535;
}

.ip-text-default:disabled {
  color: #86868b;
  -webkit-text-fill-color: #86868b;
  background-color: rgba(0,0,0,.02);
  border-color:#eee;
}
.ip-text-default:focus {
  border-color:#2c8fec;
  box-shadow: 0px 0px 0px 4px #339cff34;
}

/* Customize the label (the container) */
.ip-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 1.2rem;
  }
  
  /* Hide the browser's default checkbox */
  .ip-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    background-color: rgb(162, 193, 233);
  }

  .checkmark.round {
    border-radius: 50%;
    width: 22px;
    height: 22px;
}

  /* On mouse-over, add a grey background color */
  .ip-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .ip-checkbox input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  .ip-checkbox input:disabled ~ .checkmark {
    opacity: 0.5;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .ip-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .ip-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-radius: 1px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .ip-checkbox .checkmark.round:after {
    left: 7px;
    top: 3px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-radius: 1px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
/* Customize the label (the container) */
/* The container */
.ip-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .ip-radio input, .ip-radio-pill input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .radioselect {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .ip-radio:hover input ~ .radioselect {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .ip-radio input:checked ~ .radioselect {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radioselect:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .ip-radio input:checked ~ .radioselect:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .ip-radio .radioselect:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }

  .ip-radio-pill {
    display: inline-block;
    position: relative;
    padding: 0rem;
    margin: 0.9rem 1rem 0.9rem 0rem;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: rgb(68, 68, 68);
    padding-top: 0.1rem;
  }

  /* Create a custom radio button */
  .ip-radio-pill .radioselect {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 100%;
    padding: 0rem 1rem;
    background-color: #eee;
    border-radius: 8rem;
    z-index: 0;
  }

  .ip-radio-pill .text{
    position: relative;
    z-index: 1;
    font-weight: 400;
    padding: 0.2rem 1rem;
    color: #696969;
  }

.ip-radio-pill input:checked ~ .radioselect {
  background-color: #2196F3;
}
.ip-radio-pill input:disabled ~ .radioselect {
  opacity: 0.5;
}
.ip-radio-pill input:checked ~ .text {
  color: #fff
}
.switch-group {
  position: relative;
  width: 12rem;
}
.switch-pill.inline {
    position: absolute;
    left: auto;
    right: 0rem;
    display: inline-block;
}
.switch-pill input[type="checkbox"] {
  left: 10px;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 9px;
}
.switch-pill input[type="checkbox"]:checked + label {
  background: #2c89ec;
}
.switch-pill label {
  -webkit-border-radius: 2.5rem;
  -moz-border-radius: 2.5rem;
  border-radius: 2.5rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #e4e4e4;
  color: transparent;
  cursor: pointer;
  display: block;
  position: relative;
  text-indent: 100%;
  width: 3.15rem;
  height: 26px;
  -webkit-transition: all 400ms ease 0s;
  -moz-transition: all 400ms ease 0s;
  transition: all 400ms ease 0s;
}

.switch-pill input[type="checkbox"]:checked + label:after {
  left: 26px;
}
.switch-pill label:after {
  position: absolute;
  top: 2px;
  left: 3px;
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: white;
  display: block;
  width: 22px;
  height: 22px;
  -webkit-transition: left 400ms cubic-bezier(0.68, -0.3, 0.265, 1.3) 0s;
  -moz-transition: left 400ms cubic-bezier(0.68, -0.3, 0.265, 1.3) 0s;
  transition: left 400ms cubic-bezier(0.68, -0.3, 0.265, 1.3) 0s;
}

.switch-group label.inline{
  display: inline-block;
  margin-right: 2rem;
}

.select-container.ip-select-field .select-control  {
  border-radius: 0.6rem;
}
.select-container.ip-select-field  .options--default  {
  border-radius: 0.31rem;
  border: 1px solid #dfdfdf;
}

.select-container.ip-select-field .select-placeholder,
.select-container.ip-select-field .selected-option {
  border-radius: 0.6rem 0rem 0rem 0.6rem;
}
.ip-date.date-field {
  border-radius: 0.6rem;
  border: 1px solid #dadbdb;
  padding: 0.6rem 1rem;
}
.react-datetime-picker__calendar{
  z-index: 2 !important;
}

.switch-pill.--disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.--disabled *, .switch-pill.--disabled label{
  cursor: not-allowed;
}

.form-group {
  position: relative;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #fbfbfb;
}
.errors   {
  display: flex;
  margin: 0rem auto;
  align-items: center;
  justify-content: left;
  text-align: center;
  font-size: 0.95rem;
  margin-bottom: 0.2rem;
}

.errors  p {
  text-align: center;
  color: #d80c05 !important;
  font-size: 0.95rem;
  font-weight: 500;
}
.login-form-box .errors  p  {
  color: #ffa600 !important;
}
.errors  .icon {
  display: inline-block;
  margin-right: 0.4rem;
}
.icon .vline{
  display: block;
  margin: 0rem auto 0.15rem auto;
  width: 2px;
  height: 8px;
  background-color: rgb(255, 255, 255);
  border-radius: 1px;
}
 .icon .vline.lng{
  margin-top: 3px;
}
.icon .vline.dot{
  display: block;
  margin: 0.1rem auto 0rem auto;
  width: 2px;
  height: 2px;
}

.errors  .icon .circle{
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #e60e06;
  border-radius: 50%;
  margin-bottom: 0rem;
  position: relative;
  top: 0.2rem;
}
.login-form-box .errors  .icon .circle{
  background-color: #ffa600;
}

.form-error, .form-warning {
   text-align: left;
    color: #e60e06;
    font-size: 0.9rem;
    margin: 0.1rem 0.1rem;
    font-weight: 500;
    display: block;
}

.form-group  .form-error, .form-group  .form-warning {
  padding: 0.1rem 0.3rem;
  line-height: normal;
  pointer-events: none;
  border-radius: 0.1rem;
  z-index: 2;
}

.form-group.static  .form-error, .form-group.static  .form-warning{
  position: absolute;
  top: 1.2rem;
  left: 2rem;
  background-color: #fff;
}

.form-warning {
  color: #5095ec;
}

.login-form .form-error,
.login-form .form-warning  {
  color: #ffa600 !important;
  position: static;
  top: unset;
  left: unset;
  background-color: unset;
}