@import "./../../assets/styles/mixins/global-mixins";
$placeholder-color: #6ca3da;

.layout--theme--login--blue .layout__content{
    padding: 0rem;
}
.login-form-box ::-webkit-input-placeholder { /* Edge */
    color: $placeholder-color;
}
.login-form-box :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholder-color;
}
.login-form-box ::placeholder {
    color: $placeholder-color;
}
.content-area {
    width: 22rem;
}
.login-form-box * {
    color: #9accf4;
}
.login-form-box {
    border-radius: 0.8rem;
    background-color: #2b80d6;
    border: 1px solid #287cd2;
    box-shadow: 0px 4px 12px #032b491a;
    min-height: 34rem;
    max-height: 36rem;
    overflow: hidden;
    position: relative;
}
.login-form-box .progress-line {
    top: -0.06rem;
    width: 100%;
}
.login-form-box .inner {
    padding: 1rem;
}
.brand-area {
    margin-bottom: 0.5rem !important;
}
.login-form-box .logo {
    width: 5rem;
    height: 5rem;
    display: block;
    margin: 1rem auto 0rem auto;
    pointer-events: none;
    filter: invert(100);
}
.title {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0.6rem !important;
}
.sub-text {
    font-size: 0.9rem;
}
.login-form-box .form-group {
    margin-bottom: 1.5rem;
}
.login-form-box .errors {
    justify-content: center;
}
.login-form .ip-text {
    background-color: #1571cc;
    border-radius: 0.2rem;
    padding: 0.6rem 1rem;
    min-width: 22rem;
    color: #dbefff;
    outline: none;
    border: 1px solid #1571cc;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-out;
    width: 100%;
}
.login-form .ip-text{
    height: 3.5rem;
}

.login-form .ip-text:focus {
    color: #dbefff;
}
.ip-text.password {
    padding-right: 2.5rem;
}
.input-field-wrap {
    position: relative;
}
.input-field-wrap .show-password-icon {
    width: 1.7rem;
    height: 1.7rem;
    right: 0.6rem;
    top: 1rem;
    opacity: 0.5;
    position: absolute;
    cursor: pointer;
    transition: all ease-out 0.2s;
    -webkit-transition: all ease-out 0.2s;
    -o-transition: all ease-out 0.2s;
    -moz-transition: all ease-out 0.2s;
    @include disable-user-select();
}
.input-field-wrap .show-password-icon:active {
    opacity: 0.8;
}
.input-field-wrap .show-password-icon.--visible {
    opacity: 1;
}
.ip-text:focus {
    background-color: #1a76d3;
    border-color: #228be0;
 }
.btn-primary  {
    text-align: center;
    outline: none;
    border: none;
    background-color: #1571cc;
    border-radius: 6px;
    padding: 0.5rem 2rem;
    display: block;
    margin: 2rem auto 1.6rem auto;
}
.btn-primary:focus {
    outline: none;
}

.login-form .btn-primary:disabled {
    color: #ffffff54;
    background-color: #c0d6ec1a;
    border-color: #007bff; 
}

.username-view {
    margin-top: 2rem;
}
.current-user {
    border: 1px solid #1974bf;
    width: 90%;
    margin: 0 auto;
    border-radius: 5rem;
    padding: 0.3rem 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    box-shadow: 0px 2px 6px #0f46742a;
    cursor: pointer;
    position: relative;
}

.current-user:hover {
    background-color: #3286da;
}

.user-icon-wrap {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 2px;
    margin-right: 0.5rem;
    background-color: #1571cc;
}

.current-user .assign-user {
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-right: 1.2rem;
}
.user-icon {
    border: 1px solid #9accf4;
    width: 26px;
    height: 26px;
    overflow: hidden;
    border-radius: 50%;
}

.user-icon .head{
    background-color:  #9accf4;
    width: 10px;
    height: 10px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    margin: 5px auto  2px auto;
}

.user-icon .body{
    background-color:  #9accf4;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 38px;
    position: relative;
    margin: 0px auto  0px auto;
}

.current-user .arrow-indicator {
    border: solid rgb(17, 94, 165);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    position: absolute;
    transform: rotate(45deg);
    -webkit-transform: rotate(
45deg
);
right: 1rem;
top: 0.9rem;
}

.user-list  {
    max-height: 12.3rem;
    overflow-x: hidden;
    overflow-y: scroll;
}
.user-list a {
    text-decoration: none;
}

.user-list .user-item {
    padding: 0.4rem 0.5rem;
    border-radius: 0.4rem;
    border-bottom: 1px solid #3995d37c;
}
.user-list .user-item:last-child {
    //border-bottom: none;
}
.user-list .user-item .user{
    display: flex;
    position: relative;
}
.user-list .user-item .user .user-icon-wrap{
    margin-top: 0.2rem;
}
.user-list .user-item .user h3{
    font-size: 1rem;
    line-height: 1rem;
}
.user-list .remove-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    display: block;
}
.user-list .remove-icon .dash{
    width: 10px;
    height: 3px;
    background-color: #ff0000;
    display: block;
    margin: 7px auto;
}
.user-list .user-item .user p{
    font-size: 0.8rem;
    opacity: 0.7;
}
.btn-wrap {
    width: 100%;
    margin-bottom: 0.4rem;
}
.btn-wrap .btn-chos {
    width: 100%;
    border: 1px solid #1974bf !important;
    border-radius: 0.5rem;
    background-color: transparent;
    text-align: left;
    line-height: normal;
}

.btn-wrap .btn-chos:hover,
.user-list .user-item:hover {
    background-color: #3286da;
}
.btn-wrap .btn-chos:focus {
    background-color: #3286da !important;
}
.btn-wrap .btn-chos:hover {
    box-shadow: 0px 2px 6px #0f46742a !important;
}
.users-group {
    margin-bottom: 0.9rem !important;
}
.btn-wrap .btn-chos .icon {
    display: inline-block;
    position: relative;
    margin-right: 1.5rem;
    
}
.btn-wrap .btn-chos .icon img{
    width: 1.5rem;
    pointer-events: none;
}

.fade-enter{
    opacity: 0;
 }
 .fade-exit{
    opacity: 1;
 }
 .fade-enter-active{
    opacity: 1;
 }
 .fade-exit-active{
    opacity: 0;
 }
 .fade-enter-active,
 .fade-exit-active{
    transition: opacity 500ms;
 }