@import "./variables";
@import "./theme-variants";
@import "./form";
@import "./loading-indicator";


body.background-white {
    background-color: #ffffff;
}
body.background-gray {
    background-color: #f7f7f9 /*#f9f9f9*/;
}
body.diable-scroll {
    overflow-y: hidden;
}

* [contenteditable="true"] {
    white-space: nowrap;
    width: auto;
    overflow: hidden;
} 
* [contenteditable="true"] br {
    display:none !important;

}
* [contenteditable="true"] * {
    display: inline-block;
    white-space:nowrap;
}

// Temporary fix
.layout--theme--home--blue .layout__content,
.layout--theme--login--blue .layout__content {
    margin-top: 0rem;
    height: 100%;
}

.ReactModalPortal .default-Ly-ct{
    padding-bottom: 1rem;
}
.container {
   max-width: 90%;
}
.fullwidth__section--center {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layout {
    display: flex;
    min-height: 100vh;
}
.layout__wrap {
    display: block;
    left: 0;
    min-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}
.layout__content {
  display: flex;
  flex: 1;
  margin-top: 0rem;
  flex-direction: column;
  height: calc(100vh - 7.9rem);
  //padding-top: 9.4rem;
}

.layout__content.layout__clientdashboard {
    display: flex;
    flex: 1;
    margin-top: 0rem;
    flex-direction: column;
    height: calc(100vh - 10.4rem);
    //padding-top: 9.4rem;
  }

.content-layout-wrap {
    display: flex;
    width: 100%;
    overflow-y: auto;
    position: relative;
    height: inherit;
}

.default-Ly-ct{
    overflow-y: scroll;
    height: 100%;
    position: relative;
    padding-bottom: 4rem;
}

.fullwidth {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.fullwidth__section {
    flex: 0 0 auto;
}
.fullwidth__section--center {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout--theme--login--blue {
    @include setup-login-theme((
        'background': $login-background-color
    ));
}
/* Main Navigation */
/* Main Navigation */
@import "./context-menu";
@import "./navigation";


/*Buttons*/
button {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  color: #5a5a5a;
}
button:active {
    background-color: transparent;
    color: initial ;
}
button:focus {
    background-color: transparent;
    color: initial ;
}
.btn:disabled {
    background-color: #ececec;
    color: #5c5c5c;
    cursor: not-allowed;
}
.btn:disabled:hover {
    background-color: #ececec;
    color: #5c5c5c;
}
.default-btn {
   background-color: #0a94f0; 
   color: #ffffff;
   border-radius: 0.8rem;
   padding: 0.5rem 1rem;
}

.default-btn.add-btn .icon img {
    -moz-filter: invert(100%);
    -o-filter: invert(100%);
    filter: invert(100%);
    pointer-events: none;
}

.default-btn:hover {
    background-color: #0786db; 
} 
.default-btn:focus {
    background-color: #0880cf !important; 
} 

.save-btn {
    border-radius: 0.5rem;
    background-color: #0a94f0 !important;
}

.save-btn:active {
    background-color: #0986da !important;
}

.del-btn {
    border-radius: 0.5rem;
    background-color: #f0250a !important;
}
.del-btn:active {
    background-color: #dd1e05 !important;
}

ul {
    list-style: none;
    padding: 0rem;
    margin: 0rem;
}
.nav-tabs {
    border-bottom: 1px solid #f5f5f5;
}
.nav-tabs .nav-item .nav-link {
    color: #5a5a5a;
    padding: 0.7rem 1.1rem;
    position: relative;
}
.nav-tabs .nav-item .nav-link.active {
    color: #0786db;
}
.nav-tabs .nav-item .nav-link .errors {
    position: absolute;
    left: 0.3rem;
    top: 0.9rem;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: #efefef #efefef #fff;
}

/* Global Styles */
.el-right {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

.action-bar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0rem;
    position: sticky;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.959);
    border-bottom: 1px solid rgb(243, 243, 243);
    z-index: 2;
}

.action-bar .bar-title {
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0rem;
}
.el-right {
    display: block;
    flex-direction: row;
    margin-left: auto;
}
.action-bar .action-group {
    display: flex;
    justify-items: center;
    align-items: center;
}
.action-bar .action-group .icon-button {
    margin: 0rem 0.3rem;
    background-color: transparent;
    color: #5a5a5a;
    line-height: 1rem;
    font-size: 0.9rem;
    padding: 0.4rem 0.5rem;
    border-radius: 0.5rem;
}
.action-bar .action-group .icon-button.delete {
    color: #F43825;
}
.action-bar .divider {
    width: 1px;
    height: 1.2rem;
    display: inline-block;
    background-color: #e0e0e0;
    margin: 0rem 0.4rem; 
}
.action-bar .action-group .icon-button:hover  {
    background-color: #eeeeee;
}
.action-bar .action-group .icon-button.delete:hover {
    background-color: #ffe9e7;
}
.action-bar .icon-button .icon {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-right: 0.3rem;
}

/*Workspace Bar*/
.workspace-bar-wrap * {
    margin: 0rem;
    line-height: 0rem;
}

.workspace-bar {
    background-color: #dfeaf4;
    height: 2.5rem;
}
.workspace-bar .inner {
    padding: 0.2rem 1rem;
}
.workspace-bar .workspace-name {
    font-size: 1.4rem;
    font-weight: 600;
}
.workspace-bar .workspace-name label {
    color: #b6c8d6;
    line-height: normal;
    margin-right: 1rem;
}
.workspace-bar .workspace-name .ip-field-name {
    color: #719bb5;
    width: auto !important;
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    background: transparent;
    display: inline;
    cursor: text !important;
    min-width: 0.5rem;
    line-height: normal;
}
.workspace-bar .workspace-name .ip-field-name:focus {
    border-bottom: 2px solid #c4d6e2;
}
.workspace-bar .account-info {
    color: #719bb5;
    text-align: right;
}
.workspace-bar .account-info .account-number {
    letter-spacing: 0.001rem;
    font-size: 1rem;
    font-weight: 600;
    color: #719bb5;
    padding-right: 1rem;
    line-height: 0.8rem;
    margin-bottom: 0.2rem;
}
.workspace-bar .account-info .state {
    position: relative;
    top: -0.2rem;
}

.workspace-bar .account-info .state .text {
    letter-spacing: 0.001rem;
    font-weight: 400;
    margin-right: 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #889fad;
}
.workspace-bar .account-info .state .status {
    font-weight: 600;
}

.workspace-bar .account-info .state span {
    display: inline-block;
}
.workspace-bar .account-info .state .indicator {
    margin: 0.2rem;
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;
    position: relative;
    border-radius: 50%;
    top: 0.25rem;
    background-color: #b8b8b8;
    border: 1px solid #b1b1b1;
}
.workspace-bar .account-info .state .indicator.good {
    background-color: #09ec1b;
    border-color: #08df1a;
}
.workspace-bar .account-info .state .indicator.issue {
    background-color: #ec1809;
    border-color: #db1608;
}
.page-item.active .page-link {
    z-index: 0;
}
.access-deny-view {
    display: flex;
    margin: 3rem auto;  
    height: 100%;
    width: 100%;
    justify-items: center;
    align-items: center;
}
.access-deny-view .text{
    text-align: center;
    padding: 0.4rem;
    color: #797979;
    display: block;
    border-radius: 8px; 
    background-color: #f8f5f5;
    margin: 0rem auto;
}

.nav-tabs .nav-link {
    // border-color: #0000000a;
     border-bottom: none;
}
.nav-tabs .nav-link:hover {
    // border-color: #00000015;
    // border-bottom: none;
}
.unstyled::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
}
::-webkit-calendar-picker-indicator { display: block; opacity: 0; }

/*Table*/
.react-bootstrap-table tbody td {
    border: none;
}
.react-bootstrap-table tbody td.react-bs-table-no-data {
    width: 100%  !important;
}
.react-bootstrap-table tbody td.td-cell {
    padding: 0rem; 
}
.react-bootstrap-table tbody td.td-cell > div:first-child {
    border: none;
    padding: 0.4rem;
    height: 100%;
}
.react-bootstrap-table thead  {
    border: none;
    width: 100%;
    display: block;
}
.react-bootstrap-table thead tr {
    width: 100%;
    display: flex;
    border: none;
}
.react-bootstrap-table thead tr th {
    outline: none;
    width: 100%;
    font-size: 1rem;
    font-weight:300;
    border: none;
}

.react-bootstrap-table tbody tr {
    display: flex;
    position: relative;
    width: 100%;
    padding-bottom: 0px;
    padding-top: 0px;
    box-shadow: inset 0 -1px 0 0 rgba(94, 94, 94, 0.068);
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: relative;
    background: rgba(255, 255, 255, 0.8);
    color: #202124;
    cursor: pointer;
    border-radius: 0px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    
}
.react-bootstrap-table tbody tr:hover {
    box-shadow:  1px 0px 0px #e7e7e7,
     0 -1px 0px 0px rgba(90, 90, 90, 0.089),
     0 1px 2px 0px rgba(43, 43, 43, 0.089),
     0 2px 3px 1px rgba(51, 51, 51, 0.082);
    z-index: 2;
}
.react-bootstrap-table tbody tr:hover td .toolbar  {
    display: block;
}
tbody td .toolbar {
    position: absolute;
    width: 10rem;
    height: 100%;
    top: 0rem;
    right: 0rem;
    background: linear-gradient(to right, rgba(243, 243, 243, 0.068), #f9f9f9, #f9f9f9);
    display: none;
}
tbody td .toolbar .action-menu  {
    display: flex;
    position: absolute;
    left: auto;
    right: 0.8rem;
    top: 0rem;
    bottom: 0rem;
    align-items: center;
}
tbody td .toolbar .action-menu .action-item {
    height: inherit;
    display: flex;
    flex-direction: row;
}
tbody td .toolbar .action-menu .action-item a {
    padding: 0.4rem;
    margin: 0.9rem 0.2rem;
    border-radius: 50%;
}
tbody td .toolbar .action-menu .action-item a:hover {
    background-color: #ececec;
}
tbody td .toolbar .action-menu .action-item .icon{
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    pointer-events: none;
    opacity: 0.8;
}
.react-bs-table-no-data {
    width: 100%;
    text-align: center;
    color: #797979;
}

table thead.detach {
    display: block;
    position: sticky;
    top: -1px;
    background-color: #fff;
    z-index: 4;
    width: inherit;
}
table thead th:not(th:first-child)  {
    padding-left: 0.25rem;
 }
table  thead.detach tr {
    display: flex;
    width: inherit;
    border-bottom: 1px solid #e5e6e7;
}
table .custom-select {
    font-size: 0.82rem;
    line-height: 1rem;
    padding: 0rem 1.6rem 0rem 0.5rem;
    border-radius: 0.4rem;
    color: #6b6d6e;
    border: 2px solid #e8e8e8;
    outline: none;
}
table .custom-select:focus {
    outline: none !important;
    border-color: #e8e8e8;
    box-shadow: none;
}
.table-container {
    position: relative;
}
.table-container .react-bootstrap-table-pagination {
    position: absolute;
    width: 100%;
    top: 0rem;
    border-bottom: 1px solid #eaeaea;
    padding: 0.5rem 0rem;
    margin: 0rem auto;
}
.table-container .table-responsive {
    padding-top: 3rem;
}
.table-container .react-bootstrap-table-pagination .page-controls {
    padding-right: 0.2rem;
}
.vial-wrap .vial .vial-cork.crk--c1 {
    background-color: #7b88e6;
    border-color: #717fe2;
}
.vial-wrap .vial .vial-cork.crk--c2 {
    background-color: #48b57d;
    border-color: #4fa578;
}
.vial-wrap .vial .vial-cork.crk--c3 {
    background-color: #2491de;
    border-color: #2189d2;
}
.vial-wrap .vial .vial-cork.crk--c4 {
    background-color: #ead56d;
    border-color: #e2ce65;
}
.vial-wrap .vial .vial-cork.crk--c5 {
    background-color: #e3e7e8;
    border-color: #dbdfe0;
}

.progress-ring {
    margin: 0rem auto;
    display: block;
}
circle {
    transition: stroke-dashoffset 0.85s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.progress-widget {
    margin: auto;
    display: table;
    position: relative;
}
.progress-widget  .stats {
    position: absolute;
    top: 0;
    display: flex;
    vertical-align: middle;
    height: 100%;
    width: 100%;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: #949494;
}
.progress-widget  .stats span {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
    color: #5c5c5c;
}

.pagination {
    border-radius: unset;
}
.pagination .page-item {
    color: #202124;
    width: 2rem;
    height: 2rem;
    border-radius: unset;
    margin: 0rem 0.14rem;
}
.pagination .page-item .page-link {
    color: #202124;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: 0.5rem;
}
.pagination-sm .page-item:last-child .page-link,
.pagination-sm .page-item:first-child .page-link {
    border-radius: 0.5rem;
}

.page-item.active .page-link {
    color: rgb(128, 128, 128);
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    font-size: 0.9rem;
}
.pagination-page-count{
    color: rgb(92, 92, 92);
    font-size: 0.9rem;
}
#pageDropDown {
    background-color: transparent;
    border-radius: 0.4rem;
    border: 2px solid #eaeaea !important;
    padding: 0.2rem 0.4rem;
    color: grey;
}
#pageDropDown:hover, #pageDropDown[aria-expanded=true] {
    border-color: #acb5bd !important;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu  {
    min-width: 5rem;
    top: 1.6rem;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item  {
    padding: 0.2rem 0.6rem;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a {
    color: #5c5c5c;
    display: block;
    text-decoration: none;
}
.pagination-page-arrows {
    position: static;
}
.line-arrow {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    font-size: 12px; /*set the size for arrow*/
    width: 0.9em;
	height: 0.9em;
    margin-top: -2em;
    top: 50%;
}
$arrow-color: #7a7a7a;
.line-arrow.left {
    border-top: 2px solid $arrow-color;
	  border-left: 2px solid $arrow-color;
    transform: rotate(-54deg) skew(-20deg);
    left: 12px;
}
.line-arrow.right {
  	border-top: 2px solid $arrow-color;
	border-right: 2px solid $arrow-color;
    transform: rotate(54deg) skew(20deg);
    right: 12px;
}

.line-arrow:active,
.line-arrow.active {
    border-width: 3px;
}
.square {
  top: auto;
  bottom: 11px
}
.square.left {
  transform: rotate(-45deg);
}
.square.right {
  transform: rotate(45deg);
}

.delete-modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    justify-content: center;
    align-items: center;
    background: #00000015;
}
.delete-modal.visible {
    display: flex;
}

.delete-modal .delete-dialog {
    width: 350px;
    padding: 0rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 8px #00000013;
    overflow: hidden;
    position: relative;
    bottom: 3rem;
}
.delete-modal .delete-dialog .cont-inner {
    padding: 1rem 2rem;
}
.delete-modal .delete-dialog .cont-inner h4{
   font-size: 1.1rem;
   text-align: center;
}
.delete-modal .delete-dialog .cont-inner p{
    font-size: 1rem;
    text-align: center;
    margin-top: 0.3rem;
    color: #797979;
 }
.delete-modal .delete-dialog .action-wrap {
    display: flex;
    border-top: 1px solid #e9e8e8;
    position: relative;
    text-align: center;
}
.delete-modal .delete-dialog  .action-wrap .act-hldr{
    flex: 0 0 50%;
    height: 100%;
    position: relative;
}

.delete-modal .delete-dialog  .action-wrap button{
    color: #5a5a5a;
    border-radius: unset;
    background-color: transparent;
    font-weight: 500;
    width: 100%;
    height: 100%;
    padding: 0.6rem 1rem;
    border: none !important;
}
.delete-modal .delete-dialog  .action-wrap button:active{
    background-color: #f3f3f3;
}
.delete-modal .delete-dialog  .action-wrap button.cancel-btn {
    color: #2284d4;
}
.delete-modal .delete-dialog  .action-wrap > .act-hldr:first-child::after {
    content: '';
    display: block;
    height: 2.8rem;
    width: 1px;
    top: 0;
    right: 0;
    background: #e9e8e8;
    position: absolute;
}
.delete-modal .delete-dialog  .action-wrap button.delete-btn {
    color: #f34915;
}

//Errors
.error-toast {
    display: block;
    position: fixed;
    top: 1rem;
    left: 0rem;
    right: 0rem;
    width: fit-content;
    margin: 0rem auto;
    padding: 0.24rem 0.7rem;
    background-color: #ffe7a3;
    border-radius: 0.2rem;
    line-height: normal;
    font-size: 0.85rem;
    color: rgb(94, 76, 17);
    font-weight: 500;
    z-index: 9;
}
.error-toast .text {
   position: relative;
   top: -0.1rem;
   font-weight: 700;
}
.warn,
.warn::before,
.warn::after{
  position: relative;
  padding: 0;
  margin: 0;
}

.warn {
  color: transparent;
}

.warn.warning {
  position: relative;
  border-radius: 0.1rem;
  display: inline-block;
overflow: hidden;
margin-right: 0.5rem;
}

.warn.warning::before {
    content: "";
    width: 0;
    height: 0;
    border-width: 0 10px 16px 10px;
    border-color: transparent transparent rgb(250, 176, 17) transparent;
    border-style: solid;
    display: block;
}

.warn.warning::after {
  display: block;
  position: absolute;
  top: 0.26em;
  left: -0.02rem;
  width: 100%;
  padding: 0px;
  text-align: center;
  font-family: sans-serif;
  content: "!";
  font-size: 0.85em;
  font-weight: bold;
  color: rgb(94, 76, 17);
}

.print-bar {
    height: auto;
    background-color: transparent;
}
.print-bar .actions {
    display: flex;
    justify-content: space-between;
}
.print-bar .page-actions {
    padding-left: 1rem;
}
.print-bar .page-actions .nav-link{
    display: inline-block;
    font-size: 0.9rem;
    color: #4382ad;
    padding: 0.5rem 0.4rem;
    font-weight: 500;
}
.print-bar .act-item{
    display: inline-block; 
    margin: 0.3rem 0.3rem;
}
.print-bar .act-item .btn{
    padding: 0.25rem 0.3rem;
    line-height: 0.9rem;
    background-color: transparent;
}
.print-bar .act-item .btn:active{
    background-color: transparent;
    opacity: 0.65;
}
.print-bar .act-item .icon img{
    width: 1.3rem;
    height: 1.3rem;
    pointer-events: none;
}
.print-bar .act-item .icon {
    opacity: 0.7;
}

.build-version {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    pointer-events: none;
    z-index: 99;
    opacity: 0.6;
}

.build-version .beta-badge{
    font-size: 0.7rem;
    font-weight: 700;
    border-radius: 0.5rem;
    border: 2px solid #9b9b9b;
    color: #9b9b9b;
    padding: 0.2rem 0.3rem  0.2rem 0.3rem;
    line-height: 0.7rem;
}

.build-version .build-number{
    font-size: 0.6rem;
    font-weight: 700;
    color: #9b9b9b;
    padding: 0rem 0.3rem;
    line-height: 0.7rem;
    text-align: center;
    display: inline;
}

.no-item-found-wrapper{
    border: 1px solid #f1f2f3;
    border-radius: 0.3rem;
    margin-bottom: 0.5rem;
    background-color: #f9f9f9;
    position: relative;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9e9e9e;
}
.no-item-found-wrapper:hover {
    box-shadow: 0px 2px 6px #00000007;
}
.item-not-found--xs{
    padding: 1rem;
}