#receipt-POS, #pay-POS{
  margin: 2rem auto 1rem  auto;
  width: 32rem;

  $triangle-size: 8px;
  $stop1: $triangle-size * 1.42;
  $stop2: $triangle-size * 0.7;
  $stop1r: $stop1 + 0.01;
  $stop2r: $stop2 + 0.01;
  background: 
    /*linear-gradient(135deg, transparent #{$stop1}, white #{$stop1r}) top left,
    linear-gradient(45deg, white #{$stop2}, transparent #{$stop2r}) top left,*/
    linear-gradient(135deg, white #{$stop2}, transparent #{$stop2r}) bottom left,
    linear-gradient(45deg, transparent #{$stop1}, white #{$stop1r}) bottom left;
  background-repeat: repeat-x;
  background-size: ($triangle-size * 2) $triangle-size;
  padding: $triangle-size 0;

  #inner {
     background: #fff;
     padding: 2rem;
     border: 1px solid #fff;
     border-bottom: none;
  }

  .date-bar {
      display: flex;
      justify-content: space-between;
      color: #828282;
      font-weight: 400;
      font-size: 0.9rem;
      letter-spacing: -0.01rem;
  }
  .date-bar .right {
    text-align: right;
  }
  .date-bar .right .state {
    font-weight: 500;
    color: #222;
  }
h1{
  font-size: 1.5em;
  color: #222;
}
h2{font-size: 1em;}
h3{
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
p{
  font-size: 1em;
  color: #929292;
  line-height: 1.2em;
}
 
.perforation {
  height: 1px;
    background-image: repeating-linear-gradient(
90deg
,#eaeaea 0,#eaeaea 4px,transparent 0,transparent 8px);
    position: relative;
}

.perforation:before, .perforation:after {
  display: block;
  content: "";
  position: absolute;
  top: -13px;
  width: 13px;
  height: 26px;
  border: 1px solid #fff;
  background-color: #f5f5f5;
}
.perforation:before {
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    border-left: 0;
    left: -33px; 
}
.perforation::after {
  border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    border-right: 0;
    right: -33px; 
}

#top{min-height: 100px;}
#mid{min-height: 140px;} 
#bot{ min-height: 50px;}

#mid {
  padding: 1.5rem 0rem;
}

#top .logo{
  //float: left;
	height: 80px;
	width: 80px;
  opacity: 0.9;
  margin-bottom: 0.5rem;
}

.info{
  display: block;
  //float:left;
  margin-left: 0;
}
.customer.info p, .payment-type p{
  margin-bottom: 0.5rem;
}
.payment-type  {
  padding-top: 0.5rem;
}
.payment-type p {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}
.title{
  float: right;
}
.title p{text-align: right;} 
table {
  width: 100%;
  border-collapse: collapse;
}
td, th {
  padding: 10px  5px;
  color: #929292;
  font-size: 1rem;
  //border: 1px solid #EEE
}
.tabletitle{
  //padding: 5px;
  font-size: 1em;
}

td.item , th.item {
  //padding: 5px;
  width: 42%;
} 
td.amount p{
  //padding: 5px;
  color: #424242;
  font-weight: 500;
} 
.service{border-bottom: 1px dashed #EEE;}
.item{width: 24mm;}
.itemtext{font-size: 1em;}
.service .amount ,.tabletitle .amount {
  text-align: right;
}
#total {
  text-align: right;
  margin-top: 1rem;
}

#total .payment{
  font-size: 1.6rem;
  font-weight: 600;
}

#total .label {
  color: #929292;
}

#legalcopy{
  margin: 2rem 0rem;
  font-size: 0.8rem
}
  
}

#pay-POS {
  margin: 2rem auto 1rem  auto;
  width: 40rem;
  background: #fff;
  border-radius: 0.4rem;
  padding: 1.4rem 2rem;
  position: relative;

  .header-section {
    margin-bottom: 1rem;
  }
  
  .payment * {
     text-align: right;
  }

  .pay-amt {
     font-size: 1.8rem;
     padding: 0.2rem 0.5rem;
    line-height: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #efefef;
    margin: 0rem 0rem 0rem auto;
  }

  .acc-balance {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .payment {
    color: #11a700;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  input{
    box-shadow: none;
  }

  input:hover{
    border-color: #2c8fec;
  }

  .template-content .patient-info {
    flex:  0 0 50% !important;
    font-size: 0.95rem !important;
  }

  .template-content .patient-details {
    font-size: 0.95rem !important;
  }

  .patient-opt .patinet-info, .patient-opt .regist-no {
    font-size: 1rem !important;
  }

  .ip-ff-select .select-placeholder {
    padding: 1rem 0rem !important;
  }

  .cheque-fields .ip-text-default  {
    padding: 0.4rem 1rem;
    border-radius: 0.5rem;
  }

  .cheque-fields .form-group label {
    font-size: 0.8rem;
    color: #797979;
  }
}


.actions-sec .btn-group .pay-btn {
  background-color: #8cc63f;
  padding: 0.6rem 1.1rem;
  margin-right: 0rem;
  font-weight: 500;
  color: #fff;
}

#pay-POS .btn-group > .loader-wrap,
#receipt-POS .btn-group > .loader-wrap{
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  top: -0.625rem;
}

#pay-POS .actions-sec .btn-group .lds-roller,
#receipt-POS .actions-sec .btn-group .lds-roller{
  width: 36px;
  height: 36px;
}

