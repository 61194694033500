@import "./../../../assets/styles/mixins/global-mixins";


.create-btn {
    margin: 1rem 0rem 1rem auto;
    border-radius: 0.7rem;
    background: #eeeded;
    border: 1px solid #c9c9c9;
    color: rgb(85, 85, 85);
    font-weight: 600;
}

.create-btn .icon {
    margin-right: 0.3rem;
    position: relative;
    top: -0.1rem;
}

.create-btn .icon img {
    width: 1rem;
    opacity: 0.7;
    pointer-events: none;
}

.create-btn:hover {
    background: #e9e7e7;
    color: rgb(85, 85, 85) !important;
}

.create-btn:focus {
    background: #dbdbdb !important;
    color: rgb(85, 85, 85) !important;
}

.rqt-modal-wrap {
    position: fixed;
    display: flex;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.055);
    align-items: center;
    justify-content: center;
}

.rqt-modal-wrap .rqt-modal-content {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    width: 80%;
    height: 90%;
    border-radius: 0.8rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 18px #00000011;
    overflow-y: auto;
}

.rqt-modal-content .head-section {
    padding: 0.5rem 1.5rem;
    position: sticky;
    width: 100%;
    z-index: 9;
    top: 0;
    background-color: #fff;
}

.rqt-modal-content .client-sec,
.rqt-modal-content .items-sec {
    padding: 1.5rem;
}

.rqt-modal-content .slide-pane__close {
    position: absolute;
    right: 0rem;
    top: 0rem;
}

.rqt-modal-content .ip-date .react-datetime-picker__wrapper {
    width: 14rem;
}

.requisition__loader-container,
.modal__loader-container {
    display: flex;
    align-items: center;
    height: 80%;
}

.rqt-item .rqt-entry {
    display: flex;
}

.rqt-item.lead.head {
    border-radius: .4rem;
    background-color: #f7f7f7;
}

.req__link-items-container .rqt-item.lead.head {
    border-radius: 0.4rem !important;
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
}

.rqt-item.lead.head .rqt-entry div {
    padding: 0.4rem 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #4d4d4d;
}

.rqt-item .rqt-entry div {
    flex: 25%;
    padding: 0rem 0.1rem;
}

.rqt-entry.rqt-line div.form-type,
.rqt-item.lead.head div.form-type {
    flex: 30%;
}

.items-count {
    margin-top: 2rem;
    font-size: 1.8rem;
    width: 12rem;
    border: 1px solid #f7f7f7;
    border-radius: 0.3rem;
    background-color: #f7f7f7;
    padding: 0.5rem;
    display: flex;
}

.items-count .count {
    font-size: 1.5rem;
    color: #4d4d4d;
    text-align: center;
    line-height: normal;
    margin-bottom: 0rem;
    width: 5rem;
    margin-right: 0.5rem;
    padding: 0rem 0.2rem;
    height: 2.6rem;

}

.date-receive {
    width: 90%;
}

.date-receive .ip-date.date-field {
    padding: 0.2rem 1rem;
    border-radius: 0.2rem;
}

.lst-head {
    display: flex;
    justify-content: space-between;
}

.lst-head .prtn-cmple-btn {
    margin-right: 1rem;
    text-decoration: none;
    color: #4d4d4d;
    opacity: 0.8;

}

.lst-head .prtn-cmple-btn:hover {
    color: #2b2a2a;
    opacity: 1;
}

.lst-head .prtn-cmple-btn .icon {
    display: inline;
    position: relative;
    top: -0.2rem;
    margin-right: 0.2rem;
}

.lst-head .prtn-cmple-btn .icon img {
    width: 1.2rem;
}

.rqt-item .rqt-entry.rqt-line {
    border-bottom: 1px solid #e9e9e9;
    border-radius: 0.2rem;
    padding: 0.2rem 0rem;
}

.rqt-entry.rqt-line .ip-radio-pill {
    margin: 0.5rem 0.3rem;
    font-weight: 500;
}

.rqt-item,
.rqt-item {
    position: relative;
}

.rqt-entry.rqt-line:hover .quickaction {
    display: block;
}

.rqt-item .rqt-entry.rqt-line .completed-icon {
    width: 1.4rem;
    height: 1.5rem;
    position: absolute;
    right: 1.2rem;
    top: 0.6rem;
    pointer-events: none;
}

.rqt-item .rqt-entry.rqt-line .sr-link {
    display: inline-block;
    position: absolute;
    right: 2.5rem;
    top: 0rem;
}

.rqt-item .rqt-entry.rqt-line .sr-link span {
    font-size: 0.8rem;
    border-bottom: 1px dotted;
}

.rqt-item .rqt-entry.rqt-line .sr-link .icon {
    width: 1rem;
    display: inline;
    margin-right: 0.3rem;
    pointer-events: none;
}

.rqt-entry.rqt-line .ip-radio-pill .radioselect {
    height: 25px;
}

.rqt-entry.rqt-line .ip-text {
    border: none;
    margin: 0rem;
    background-color: transparent !important;
}

.rqt-entry.rqt-line .ip-text:focus {
    background-color: transparent;
    box-shadow: none;
}

.rqt-entry.rqt-line .ip-checkbox {
    margin-top: 0.5rem;
}

.rqt-entry.rqt-line:hover {
    z-index: 9;
    box-shadow: 0px 2px 2px #00000010,
        0 -1px 1px #00000010;
}

.rqt-modal-content .total-section {
    padding: 0rem 1.5rem
}

.rqt-modal-content .total-section .total-box {
    min-height: 4.5rem;
    border: none;
    background: none;
}

.rqt-modal-content .bottom-section {
    padding: 1.5rem;
    display: flex;
    justify-content: flex-end;
}

.rqt-modal-content .save-btn {
    border-radius: 0.5rem;
    background-color: #0a94f0 !important;
}

.rqt-modal-content .save-btn:active {
    background-color: #0986da !important;
}

.rqt-modal-content .del-btn {
    border-radius: 0.5rem;
    background-color: #f0250a !important;
}

.rqt-modal-content .del-btn:active {
    background-color: #dd1e05 !important;
}

.loader-wrap.--inline {
    height: 2rem;
    margin: -0.3rem 0rem 0rem auto;
}

.input-amount {
    border: 0px;
    width: 100%;
    margin-top: -8px;
    font-weight: 600;
    background-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.print-req {
    margin-left: 10px;
}

.rqt-modal-content .form-group .form-error,
.rqt-modal-content .form-group .form-warning {
    position: relative;
    top: unset;
    left: 0px;
    background-color: transparent;
}

.no-folder-msg {
    text-align: center;
    color: #bebebe;
    margin: 2rem auto;
}

.req__link-items-container {
    margin-top: 1.5rem;
}

.req__link-item {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr;
    padding: 0.6rem 0.9rem;
    align-items: center;
    border-radius: 0.5rem;
    background-color: #f6f6f6;
    margin: 0.2rem 0rem;
    border: 1px solid rgb(245, 245, 245);
    cursor: pointer;
}

.req__link-item.--selected{
    background: #ebf5fd;
    border: 1px solid rgb(118, 186, 246);
}

.req__link-item .ip-checkbox {
    top: -7px;
}

.req__link-item:not(:last-child) {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.07); */
}

.req__link-item .not-urgent {
    border-color: rgb(162, 160, 160);
    color: rgb(162, 160, 160);
}

.req__link-item .urgent-badge {
    font-size: 0.8rem;
    font-weight: 600;
}

.req__link-item-list {
    /* border: 1px solid rgba(0, 0, 0, 0.07); */
    border-top: none;
    max-height: 13rem;
    overflow: auto;
}

.req__link-items-container .req__link-head {
    font-size: 1rem;
    font-weight: bold;
    color: #7d7b7b;
    padding: 0.6rem;
}

.req__link-check {
    padding: .5rem;
    width: 1rem;
    height: 1rem;
}

.req__link-empty-list {
    display: flex;
    justify-content: center;
    color: #b3b1b1;
    font-style: italic;
    border-radius: 0.5rem !important;
    border-color: #f3f3f3;
}

.req__link-no-reqs {
    height: 12rem;
    align-items: center;
}