.wrapper {
    background-color: #398de2;
    width:  100vw;
    height: 100vh;
}

.wrapper .layout-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    width: inherit;
}

.wrapper .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.wrapper .content-box h1 {
    display: block;
    color: #fff;
}

.wrapper .content-box p {
    color: #fff;
    opacity: 0.8;
}

.wrapper .content-box .logo-img {
    width: 15rem;
    margin: 0 auto 2rem;
    pointer-events: none;
}

.wrapper .content-box .logo-icon img {
    width: inherit;
    filter: invert(100);
}

.wrapper .content-box .to-login-btn {
    margin: 1rem auto;
    border-radius: 0.8rem;
    padding: 1rem 3rem;
    background-color: #2d81d6;
    font-weight: 600;
}
.wrapper .content-box .to-login-btn:active {
    background-color: #3086db !important;
}