@import "./../../assets/styles/mixins/global-mixins";

.urgent-badge {
    display: inline-block;
    border-radius: 0.3rem;
    padding: 0.1rem 0.5rem;
    color: #ff6b02;
    line-height: 1.2rem;
    border: 2px solid #ff6b02;
    position: absolute;
    right: 0;
    top: 0;
}
.sptm-cre .urgent-bar{
    position: relative;
    padding: 0.6rem;
}
.sptm-cre .urgent-bar .urgent-badge{
    left: 7%;
    right: unset;
    position: relative;
}
.spmt-Ly-ct{
    padding-right: 3rem;
    //Fixed scroll
    overflow-y: scroll;
    height: 100%;
    position: relative;
    padding-bottom: 4rem;
}
.form-submit-bar {
    padding: 0rem 1rem;
    overflow: hidden;
    margin-top: 1rem;
}
.form-submit-bar .inner {
    height: auto;
    float: right;
    display: block;
}
.form-submit-bar  .submit-buttom-holder {
    display: flex;
    align-items: center;
}
.form-submit-bar  .submit-buttom-holder .form-group,
.form-submit-bar  .ip-checkbox {
    margin-bottom: 0rem !important;
}
.form-submit-bar  .submit-buttom-holder .submit-btn {
    margin: 0rem 1rem;
    border-radius: 0.7rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    background-color: #f7931e;
    color: #fff;
}
.form-submit-bar  .checkmark.round {
    margin-bottom: 2px;
}
.form-submit-bar  .submit-buttom-holder .submit-btn .icon {
    width: 1.4rem;
    display: inline-block;
    margin-right: 0.2rem;
    filter: invert(100);
    pointer-events: none;
}
.form-submit-bar  .submit-buttom-holder .form-group {
    color: #ee8c1b;
    font-weight: 600;
}
.form-submit-bar  .submit-buttom-holder .submit-btn:focus {
    background-color: #f7931e;
}
.form-submit-bar  .submit-buttom-holder .submit-btn:active {
    background-color: #ec8712;
}
.submit-buttom-holder .ip-checkbox input:checked ~ .checkmark {
    background-color: #f7931e;
}
.content-layout-wrap .sidebar{
    flex: 0 0 280px;
    width: 280px;
    padding: 2rem 0.5rem 1rem 0.5rem;
    position: relative;
}
.content-layout-wrap .sidebar .add-btn {
    margin: 0rem auto 0rem 1.2rem;
    font-weight: 600;
    font-size: 1.1rem;
}
.content-layout-wrap .sidebar  .add-btn .icon{
    width: 1.4rem;
    display: inline-block;
    pointer-events: none;
    margin-right: 5px;
}
.content-layout-wrap .sidebar  .nav{
    margin-top: 1rem;
    margin-left: 1rem;
}
.content-layout-wrap .sidebar  .nav .nav-item{
    display: block;
    width: 100%;
}
.content-layout-wrap .sidebar .nav .nav-item .nav-link{
    border-radius: 0.6rem;
    color: #474747;
    padding: 0.5rem 1rem;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.content-layout-wrap .sidebar .nav .nav-item .nav-link .icon{
    display: inline-block;
    position: relative;
    height: 1.4rem;
    width: 1.4rem;
    margin-right: 0.5rem;
    opacity: 0.7;
    top: -1px;
    pointer-events: none;
}
.content-layout-wrap .sidebar  .nav .nav-item .nav-link .icon img{
    height: 100%;
    width: 100%;
}
.content-layout-wrap .sidebar  .nav .nav-item .nav-link:hover{
    background-color: #f4f6f8;
}
.content-layout-wrap .sidebar  .nav .nav-item .nav-link.active{
    background-color: #eff1f3;
    font-weight: 600;
}
.v-line {
    flex: 0 0 auto;
    height: 100%;
    display: block;
    position: relative;
}
.view-layout-content  {
    width: 100%;
    position: relative;
    //Fixed bar
    padding-right: 0rem;
    height: 100%;
}
.view-layout-content  {
    //padding-bottom:  9.25rem;
}
.view-layout-content.--with-act-bar  {
    //padding-bottom:  12.25rem;
}
.view-layout-content .spmt-Ly-ct-inner {
    width: 100%;
    padding: 0rem 1rem;
    position: relative;
}
.rc-content-wid {
    padding-top: 0.8rem;
}
.view-layout-content .spmt-v-lay-ct {
    display: flex;
}
.view-layout-content .spmt-title-d {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f1f1f1;
}
.view-layout-content .spmt-title-d .spmt-title{
    font-size: 1.5rem;
    color: #2b2b2b;
    font-weight: 400;
}
.view-layout-content .content-well {
    width: 100%;
    padding: 0rem 1rem;
    position: relative;
}

.recent-sec-l .rc-title *, .sec-title *{
    font-weight: 400;
    padding: 0.4rem 0rem;
    color: #777777;
    font-size: 1.2rem;
}

.rc-content-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    margin: -5px;
    overflow: hidden;
}

.rc-content-list .sp-item-rec {
    flex: 1 1 250px;
    min-width: 250px;
    max-width: 280px;
}

.rc-content-list .sp-item-rec .item-spc {
    background-color: #fff;
    border: 1px solid #eceeef;
    -webkit-border-radius: 6px;
    border-radius: 12px;
    margin: 7px;
    overflow: hidden;
    position: relative;
    display: flex;
    -webkit-transition: box-shadow 200ms cubic-bezier(0.4,0.0,0.2,1);
    transition: box-shadow 200ms cubic-bezier(0.4,0.0,0.2,1);
    padding: 0.5rem;
    cursor: pointer;
    height: 12rem;
}

.rc-content-list .sp-item-rec .item-spc .urgent-indicator {
    display: block;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 0.6rem;
    font-weight: 600;
    color: #ff8a49;
    pointer-events: none;
    letter-spacing: -0.01rem;
}

.rc-content-list .sp-item-rec .item-spc:hover {
    box-shadow:  0px 1px 8px #14141417;
}

.sp-item-rec .item-spc .it-vial-well {
    background-color: rgb(247, 247, 247);
    border: 2px dashed #e8e8e8;
    border-radius: 0.4rem;
    padding: 0.4rem 0.3rem 0.4rem 0.3rem;
    flex: 0 0 25%;
}
.sp-item-rec .item-spc .it-vial-well.vial {
    border-radius: 1rem 1rem 5rem 5rem;
}

.vial-wrap {
    display: block;
}
.vial-wrap .vial{
    width: 4rem;
}
.vial-wrap .vial .vial-cork{
    height: 1.5rem;
    border-radius: 0.2rem;
    background-color: #b8c4d0;
    border: 2px solid #afbac3;
    margin-bottom: -1px; 
    position: relative;
    z-index: 2;
}
.vial-wrap .vial .vial-cork:before, .vial-wrap .vial .vial-cork::after{
    height: 1.1rem;
    width: 2px;
    position: absolute;
    content: "";
    top: 2px;
    left: 1rem;
    display: block;
    opacity: 0.4;
    background-color: #fff;
}
.vial-wrap .vial .vial-cork::after{
    left: unset;
    right: 1rem;
}
.vial-wrap .vial .vial-bottle {
    border: 2px solid #ecececa8;
    border-radius: 0rem 0rem 5rem 5rem;
    width: 90%;
    margin: 0rem auto;
    position: relative;
    overflow: hidden;
    background-color: #efefefd9;
    @include disable-user-select();
}
.vial-item.--selected .vial-wrap .vial .vial-bottle{
    /*box-shadow: 0px 0px 0px 2px rgba(72, 172, 255, 0.26);*/
}

.slide-item.--selected .cell-slide {
    /*box-shadow: 2px 2px 2px #00000015,
    0px 0px 0px 2px rgba(126, 197, 255, 0.26);*/
}

.vial-item.--selected  .vial-wrap .vial .vial-cork {
   /*box-shadow: 0px -1px 0px 2px rgba(72, 172, 255, 0.26); */
}
.vial-wrap .vial .vial-bottle .sheen{
    position: absolute;
    display: block;
    width: 9px;
    height: 92%;
    top: 0.2rem;
    left: 8px;
    z-index: 2;
    border-radius: 8px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0.7) 100%);
    opacity: 0.3;
}
.vial-wrap .vial .vial-bottle .v-inner .vial-label{
    font-size: 0.4rem;
    text-align: center;
    text-overflow: ellipsis;
    line-height: 0.65rem;
    font-weight: 600;
    color: #474747;
    padding: 0.5rem 0.1rem 0rem 0.1rem;
    line-height: 0.5rem;
}
.sptm-frm-smpl .vial-wrap .vial .vial-bottle .v-inner .vial-label {
    font-size: 0.6rem;
}
.vial-wrap .vial .vial-bottle .v-inner .vial-label .blood-grp-text {
    letter-spacing: -0.02rem;
    line-height: 0.7rem;
}
.item-spc .vial .vial-bottle .v-inner .vial-label .blood-grp-text {
    line-height: 0.4rem;
}
.vial-wrap .vial .vial-bottle .blood .blood-wave svg{
    position: relative;
    bottom: -0.3rem;
    fill: #e0d8b8;
}
.vial-wrap .vial .vial-bottle .blood *{
    color: #63615e;
    text-align: center;
    font-weight: 700;
    text-overflow: ellipsis;
}
.vial-wrap .vial .vial-bottle .blood .blood-body{
    height: 5.5rem;
    border-radius: 0rem 0rem 5rem 5rem;
    background-color: #e0d8b8;
}
.vial-wrap .vial .vial-bottle .blood .blood-grp-text {
    padding: 0.2rem 0rem;
    font-size: 1.4rem;
    max-width: 2.9rem;
    margin: 0rem auto 0rem auto;
    position: relative;
    top: 1rem;
    display: block;
    text-transform: uppercase;
}
.vial-wrap .vial .vial-bottle .blood .blood-grp-text:focus {
    outline: none;
    box-shadow: 0px 0px 0px 2px #3e83dd;
    border-radius: 0.2rem;
}

.sp-item-rec .item-spc .it-vial-well .cell-slide{
 width: 4rem;
 height: 10rem;
}
.sp-item-rec .item-spc .it-vial-well .cell-slide .cell-lyr{
    height: 7.5rem;
    top: -0.7rem;
}

.sp-item-rec .item-spc .it-vial-well .cell-slide .cell-label{
    height: 2.5rem;
}
.sp-item-rec .item-spc .it-vial-well .cell-slide .cell-label .cell-text{
    font-size: 0.5rem;
    font-weight: 600;
    line-height: 0.6rem;
    margin: 0.6rem auto 0.2rem auto;
    
}

[contenteditable="true"].single-line {
    white-space: nowrap;
    width:200px;
    overflow: hidden;
} 
[contenteditable="true"].single-line br {
    display:none;

}
[contenteditable="true"].single-line * {
    display:inline;
    white-space:nowrap;
}
.rc-content-wid .vial-wrap .vial {
    width: 3rem;
}
.rc-content-wid .vial-wrap .vial .vial-bottle .blood .blood-grp-text {
    font-size: 1rem;
    white-space: nowrap;
}
.rc-content-wid .vial-wrap .vial .vial-bottle .blood .blood-wave svg {
    bottom: -0.39rem;
}
.rc-content-wid .vial-wrap .vial .vial-cork:before {
    left: 0.6rem;
}
.rc-content-wid .vial-wrap .vial .vial-cork:after {
    right: 0.6rem;
}
.item-spc .it-details {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}
.item-spc .it-details .pt-picture {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.2rem auto;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f3f3f3;
}
.item-spc .it-details .pt-picture img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}
.item-spc .it-details .ct-inner, h4, p, h3 {
    padding: 0rem;
    margin: 0rem;
}
.item-spc .it-details .ct-inner {
    padding: 0.2rem 0.4rem;
    width: 100%;
}
.item-spc .it-details .doc-pt-details h4 {
    text-align: center;
    font-size: 1rem;
    color: #494949;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: pre;
    white-space: nowrap;
}
.item-spc .it-details .doc-pt-details p {
    text-align: center;
    font-size: 0.9rem;
    color: #757575;
}
.item-spc .sp-rc-details {
    border-top: 1px solid #edeef0;
    padding-top: 0.4rem;
    margin-top: 0.4rem;
    padding-left: 0.5rem;
}
.item-spc .sp-rc-details p {
    text-align: left;
    font-size: 0.86rem;
    color: #565656;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.item-spc .sp-rc-details p span {
    color: #8d8d8d;
    margin-right: 0.4rem;
    font-weight: 400;
}

table.table-responsive.specimens th {
    width: auto;
}

table.table-responsive.specimens {
    margin-top: 1rem;
}

table.table-responsive.specimens td {
    width: auto;
}

table.table-responsive.specimens td.id-details-cell {
    width: auto;
    flex: unset;
}

table.table-responsive.specimens th.th-icon-cell,
table.table-responsive.specimens td.id-icon-cell {
    width: 50px;
}
table.table-responsive.specimens th.th-patient-cell,
table.table-responsive.specimens td.id-patient-cell{
    flex: 0 1 28%;
}

table.table-responsive.specimens th.th-client-cell,
table.table-responsive.specimens td.id-client-cell{
    flex: 0 1 25%;
}
table.table-responsive.specimens th.th-details-cell,
table.table-responsive.specimens td.id-details-cell{
    flex: 0 1 25%;
}
table.table-responsive.specimens th.th-status-cell,
table.table-responsive.specimens td.id-status-cell
 {
    flex: 0 1 18%;
}
td.id-icon-cell .cell-icon .cell-icon-holder {
    width: 35px;
    height: 35px;
    opacity: 0.25;
    margin:  auto;
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
}
td.id-icon-cell .cell-icon .cell-icon-holder.--urgent {
    opacity: 0.8;
}
td.id-icon-cell .cell-icon .cell-icon-holder.--urgent svg{
    fill: #ff6b02;    
}
.cell-icon { 
    height: 100%;
    display: table; 
}
td.id-icon-cell .cell-icon .cell-icon-holder .icon{
    margin: auto auto;
    display: block;
}
td.id-icon-cell .cell-icon .cell-icon-holder .icon.--result-sheet{
    width: 100%;
}
td.id-icon-cell .cell-icon .cell-icon-holder .icon svg{
    width: 35px;
    margin: auto;
    display: block;
}
td.id-icon-cell .cell-icon .cell-icon-holder .icon.--result-sheet svg{
    width: 25px;
}
td.id-patient-cell .patient-info-holder {
    display: flex;
}
td.id-patient-cell .patient-info-holder .user-picture {
    margin-right: 0.3rem
}
td.id-patient-cell .patient-data {
     width: 100%;
     padding: 0rem 0.4rem;
}

td.id-patient-cell .patient-data h4, td.id-client-cell h4 {
    font-size: 1rem;
    margin: 0rem;
    color: #494949;
}

td.id-patient-cell .patient-data p, td.id-client-cell p {
    font-size: 0.8rem;
    color: #969696;
}

td.id-details-cell .spmt-details {
    width: 100%;
    margin: 0rem;
}

td.id-details-cell .spmt-details .sample-info  {
    font-weight: 500;
    color: #494949;
    text-transform: uppercase;
}
td.id-details-cell .spmt-details .sample-info.info  {
    color: #9e9e9e;
    font-size: 0.9rem;
}
td.id-details-cell .spmt-details .sample-info i {
    margin-right: 0.4rem;
    color: #b1b1b1;
    font-style: normal;
}

td.id-details-cell .spmt-details .blood-type {
    color: #b1b1b1;
}
td.id-details-cell .spmt-details .blood-type span{
    font-weight: 600;
    color: #d40000;
    padding: 0rem 0.4rem;
}
.status-indicator {
    display: inline-block;
    padding: .2rem 0.5rem;
    border-radius: 0.4rem;
    background-color: #eceef1;
    color: #777777;
    line-height: normal;
    font-size: 0.9rem;
    font-weight: 500;
    min-width: 5.5rem;
    text-align: center;
    pointer-events: none;
    @include disable-user-select();
}

.await-approval {
    display: inline-block;
    margin-left: 1rem;
    font-size: 1rem;
    pointer-events: none;
    @include disable-user-select();
}

.requisition-link {
    display: inline-block;
    padding: .2rem 0.4rem .2rem .5rem;
    color: #777777;
    line-height: normal;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    pointer-events: none;
    position: relative;
    top: -0.2rem;
    @include disable-user-select();
}
.requisition-link img{
    width: 1.5rem;
    @include disable-user-select();
}
td.id-status-cell .date{
    margin-top: 0.3rem;
    color: #777777;
    font-size: 0.8rem;
    font-weight: 500;
}
.status-indicator.--processing {
    background-color: #ffdeb6;
    color: #96551a;
}
.status-indicator.--completed, .status-indicator.--resulted {
    background-color: #b6deff;
    color: #0d6dad;
}
.status-indicator.--approved {
    background-color: #99f7c7;
    color: #328c5c;
}
/*Create Update Styles*/
.sptm-cre-frm-lay {
    display: flex;
    width: 100%;
}
.sptm-frm-cont {
    width: 80%;
    flex: 0 0 80%;
}
.sptm-frm-smpl {
    width: 20%;
    flex: 0 0 20%;
}
.sptm-cre-frm-lay {
    padding: 1rem 0rem;
}
.sptm-cre-frm-lay .title-bar .title {
    font-size: 2.2rem;
    color:#516577;
    cursor: help;
    margin-bottom: 1rem !important;
}
.sptm-cre-frm-lay .title-bar .title.tool-tip .tooltip-right {
    top: 10px;
}
.sptm-cre-frm-lay .title-bar .title .status-indicator {
    position: relative;
    left: 0.5rem;
    bottom: 0.2rem;
}
.sptm-cre-frm-lay .frm-sec-title {
    font-size: 1.1rem;
    color: #494949;
    margin-bottom: 1.3rem;
}
.add-btn-hldr {
    text-align: center;
}
.add-btn-hldr .add-vial {
    width: 4rem;
    height: 4rem;
    margin: 0.5rem auto 2rem auto;
    border-radius: 50%;
    background-color: #e8e8e8;
}
.add-btn-hldr .add-patient {
    width: 5rem;
    height: 5.4rem;
    margin: 0rem auto 0rem 0.2rem;
    border-radius: 1rem;
    background-color: #eeeeee;
}

.add-btn-hldr .add-vial:hover {
    background-color: #e2e4e5;
}
.add-btn-hldr .add-vial:active {
    background-color: #d4d4d4 !important;
}
.add-btn-hldr .add-vial:blur {
    background-color: #dddedf !important;
}
.add-btn-hldr .add-vial .icon{
    opacity: 0.3;
    pointer-events: none;
}
.add-btn-hldr .add-vial .icon img{
    width: 2.5rem;
}
.vial-sec.section .vial-well {
    border: 3px dashed #dadce0;
    border-radius: 0.5rem;
    margin: 0rem auto;
    width: 8rem;
    min-height: 25rem;
    padding: 1.5rem 0rem 1.5rem 0rem;
}
.vial-sec.section .vial-well.vials {
    border-radius: 2.4rem;
}

.vial-sec.section .vial-well.--error {
    border-color: #e60e06;
}
.vial-sec.section {
    margin-top: 3rem;
}
.vial-sec.section .vial-well ul.vail-list {
    border: 1px solid black;
}
.vial-item .vial-hldr {
    margin: 0rem auto 1rem auto;
    position: relative;
}
.slide-item .slide-hldr {
    margin: 0rem auto 1rem auto;
    position: relative;
    @include disable-user-select
}
.vial-item.--focus .vial-wrap .vial .vial-cork,
.vial-item.--focus .vial-wrap .vial .vial-bottle{
    border-color: #67b0f534;
    box-shadow: 0 0 9px #84c3f7d8;
}
.no-vial {
    bottom: 1px solid black;
    text-align: center;
    padding: 5rem 0.5rem;
    //background-color: #f8f8f8;
    margin: 0rem 1rem;
    color: #949494;
    border-radius: 8px 8px 3rem 3rem;
}
.no-vial strong {
    font-size: 1.8rem;
    line-height: normal;
    top: 2px;
}
.vial-sec.section .vial-wrap .vial {
    margin: auto;
}
.vial-hldr .rmv-btn,
.slide-hldr .rmv-btn {
    width: 24px;
    height: 24px;
    border: 1px solid #d80606;
    padding: 1px 0rem;
    margin: 0rem;
    border-radius: 50%;
    position: absolute;
    display: block;
    z-index: 9;
    right: 0rem;
    top: -0.6rem;
    text-align: center;
    background-color: #d80606;
    line-height: normal;
    box-shadow: 0px 4px 6px #0e0e0e67;
    display: none;
}
.vial-hldr:hover .rmv-btn,
.slide-hldr:hover .rmv-btn {
    display: block;
}
.vial-hldr .rmv-btn img,
.slide-hldr .rmv-btn img{
    width: 1rem;
    height: 1rem;
    margin-top: -0.2rem;
    transform: rotate(45deg);
    filter: invert(100);
    pointer-events: none;
    opacity: 0.9;
}
.vial-sec.section .vial-wrap .vial .vial-bottle .blood .blood-body {
    height: 7rem;
}
.vial-sec.section .frm-sec-title {
    text-align: center;
    margin: 1rem;
}
/*Cell Slide */

.cell-slide-wrap {
    position: relative;
    @include disable-user-select   
}
.cell-slide {
    border: 1px solid rgb(236, 236, 236);
    margin: 0rem auto;
    position: relative; 
    height: 18rem;
    width: 7rem; 
    opacity: 1;
    border-radius: 0.2rem;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f9f9f9+0,f2f2f2+31,fcfcfc+59,ededed+100 */
    background: #f9f9f9; /* Old browsers */
    background: -moz-linear-gradient(45deg,  #f9f9f9 0%, #f2f2f2 31%, #fcfcfc 59%, #ededed 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  #f9f9f9 0%,#f2f2f2 31%,#fcfcfc 59%,#ededed 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  #f9f9f9 0%,#f2f2f2 31%,#fcfcfc 59%,#ededed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ededed',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    box-shadow: 2px 2px 2px #00000015;
    
}

.cell-slide .lyr-1 {
    border-left: 2px solid rgba(255, 255, 255, 0.856);
    border-bottom: 3px solid rgba(12, 12, 12, 0.11);
    width: 98%;
    height: 99.7%;
    margin: 0 auto;
    z-index: 2;
    border-radius: 0.2rem;
    position: absolute;
    bottom: -1px;
    left: 1px;
}

.cell-slide .lyr-2 {
    border-right: 2px solid rgba(0, 0, 0, 0.158);
    border-top: 1px solid rgba(0, 0, 0, 0.089);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    right: -1px;
    z-index: 1;
    border-radius: 0.2rem;
    box-shadow: inset -2px -5px 5px  #ffffff8e;
}

.cell-slide .lyr-2::after {
    content: '';
    height: 98%;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.301);
    position: absolute;
    right: 1px;
    top: 2px;
    bottom: 2px;
    border-radius: 0.2rem;
}

.cell-slide .cell-label {
    width: 94%;
    height: 4.5rem;
    background-color: rgb(255, 255, 255);
    border: 1px solid #f1f1f0;
    margin: 0.2rem auto;
    border-radius: 0.3rem;
    box-shadow: 1px 0px 1px #00000041;
    z-index: 3;
    right: 0.05rem;
    position: relative;
}

.cell-slide .cell-label .cell-text {
    text-transform: uppercase;
    font-size: 0.9rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0.2rem auto;
    line-height: 0.9rem;
    font-weight: 500;
    color: rgb(82, 80, 80);
}

.cell-slide .cell-label .cell-lab-no {
    font-size: 0.7rem;
    text-align: center;
    text-transform: uppercase;
    display: block;
    font-weight: 500;
    color: rgb(105, 105, 105);
    letter-spacing: -0.01rem;
}

.cell-slide .cell-lyr {
   opacity: 0.9;
   top: -1rem;
   position: relative;
   z-index: 1;
   height: 77%;
   overflow: hidden;
}

.cell-slide .cell-lyr img {
   height: 100%;
   width: auto;
 }

.sptm-frm-cont .inner-cont{
    width: 80%;
    margin: 0rem auto;
}
/*Patient Select*/
.select-wrapper .form-error,
.select-wrapper .form-warning {
    position: absolute;
    z-index: 1;
    top: -1.2rem;
    background: #fff;
    padding: 0rem 0.5rem;
    height: 24px;
    pointer-events: none;
}
.select-wrapper {
    margin: 1rem auto;
    position: relative;
    display: flex;
} 
.select-wrapper .select-well {
    border: 2px dashed rgb(206, 206, 206);
    margin: 0rem auto;
    padding: 0.5rem;
    border-radius: 1rem;
    width: 100%;
} 
.ip-ff-select {
    display: block;
    position: relative;
} 
.ip-ff-select  .patient-option {
    margin: 0rem 0.1rem;
}
.ip-ff-select .select-control  {
    display: flex;
    line-height: normal;
    border: none;
}
.ip-ff-select .select-placeholder  {
    background-color: #f8f8f8;
    border-radius: 1rem;
    padding: 1.1rem 0rem;
    width: 100%;
}
.select-container:hover  .select-placeholder , .select-container:hover .selected-option  {
    background-color: #f5f6f7 !important;
}

.ip-ff-select .select-container  .ip-select-controls {
    display: flex;
}

.ip-ff-select  .ip-chosen-option {
    width: 100%;
    margin: 0rem;
    border-radius: 0.8rem;
    background-color: #f1f1f1;
}
.ip-ff-select  .selected-option.template-content {
    width: 100%;
    margin: 0rem;
    border-radius: 0.8rem;
    background-color: #f1f1f1;
}
.ip-ff-select .ip-select-indicator {
    width: 4rem;
    height: 4rem;
    flex: 0 0 4rem;
    margin-right: 0.5rem;
    opacity: 0.6;
}
.select-wrapper .select-control span.icon {
    margin-right: 0rem;
    padding: 0rem;
}
.ip-select-indicator.icon.--focus {
    opacity: 0.9;
}
.ip-select-indicator.icon.--focus svg{
    fill: #2e8fe9;
}
.ip-select-indicator .icon svg {
    display: block;
    height: 3rem;
    width: 100%;
    margin: 0.5rem;
    transform: scale(0.7);
}
.ip-select-indicator svg {
    display: block;
    height: 3rem;
    width: 100%;
    margin: 0.5rem;
    transform: scale(0.7);
}
.option-template .template-content {
    display: flex;
    align-items: center;
}
.template-content .patient-picture {
    color: #fff;
    width:3rem;
    height: 3rem;
    border-radius: 50%;
    margin: 0.5rem 0.8rem;
    text-align: center;
    padding: 0.7rem 0.4rem;
    font-weight: 500;
    background-color: #15a2f3;
}
.template-content .patient-info {
    flex: 0 2 55%;
    font-size: 1.1rem;
}
.template-content .patient-details {
    font-size: 1.1rem;
}
.template-content .patient-info .regist-no , .template-content .info{
    color: rgb(158, 158, 158);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.template-content .patient-info .regist-no span, .template-content .info span {
    color: #15a2f3;
    margin-left: 0.3rem;
    padding-right: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.sptm-frm-grp .ip-radio-pill  {
    font-size: 1.5rem;
    margin: 0.8rem 0.4rem;
}
.sptm-frm-grp .ip-radio-pill .radioselect {
    height: 40px;  
    font-weight: 600;
}
.sptm-frm-grp .ip-radio-pill .radioselect i {
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;  
    top: 0px;
    opacity: 0.6;
}
.sptm-frm-grp .ip-radio-pill input:checked + .radioselect i {
    filter: invert(100%);
    opacity: 1;
}
.sptm-frm-grp .ip-radio-pill .text {
    padding: 0rem 1.5rem 0rem 2.2rem;
}
.sptm-frm-grp .ip-text-spc {
    border-radius: 0.6rem;
    border: 1px solid #dadce0;
    height: 3rem;
    background: transparent;
}
.sptm-frm-grp .ip-text-spc[name="labNumber"] {
    text-transform: uppercase;
}
/*Specimen Date Time*/
.react-datetime-picker--disabled    {
    background-color: transparent !important;
}
.date-field-group {
    position: relative;
}
.date-field-group .ip-date{
    border: 1px solid #dadce0;
    border-radius: 0.8rem;
    display: flex;
    padding: 0.5rem;
    background-color: transparent;
    min-height: 3.4rem;
    height: 3.4rem;
}
.ip-date label{
    border-right: 1px solid rgb(221, 221, 221);
    display: inline-block;
    width: 14rem;
    font-size: 1.2rem;
    padding: 0.2rem 0.4rem 0.2rem 1rem;
    margin-right: 0.5rem;
    color: rgb(139, 139, 139);
    margin-bottom: 0;
    text-overflow: ellipsis;
}
.ip-date span.icon{
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    right: 1rem;
    top: 0.73rem;
    bottom: 0.8rem;
    display: block;
    z-index: 0;
    opacity: 0.6;
    pointer-events: none;
}
.ip-date input.date{
    border: none;
    display: inline-block;
    height: 100%;
    font-size: 1.3rem;
    outline: none;
    z-index: 1;
    background: transparent;
    box-shadow: none;
    text-align: center;
}
.ip-date input.date:focus{
    outline: none !important;
    background: transparent;
    box-shadow: none;
    border: none;
}
.ip-date .date-picker {
    margin: auto;
    width: 20rem;
    display: block;
    border: none;
}
.ip-date .react-datetime-picker__wrapper {
    border: none;
}
.react-calendar__month-view__days__day--weekend {
    color: #c5d4df !important;
}
.ip-date .date-picker input {
    text-align: center;
    width: 2.2rem !important;
    font-size: 1.4rem;
    color: #585858;
    padding: 0.1rem 0.2rem;
}

.ip-date .date-picker input:last-child {
    text-align: center;
    width: 4rem !important;
    font-size: 1.4rem;
    color: #585858;
    padding: 0.1rem 0.2rem;
}

.react-datetime-picker__inputGroup__divider {
    position: relative;
    top: -0.2rem;  
}
.react-datetime-picker__inputGroup__leadingZero {
    font-size: 1.42rem !important;
    color: #585858 !important;
}
.ip-date .date-picker input:focus {
    border-color: #76c2ee;
    outline-color: #76c2ee;
    color: #313131;
}
.ip-date .date-picker .react-calendar {
    border-radius: 0.5rem;
    border: 1px solid #e9e9e9;
    box-shadow: 0 2px 6px #00000010;
}
.ip-date .date-picker .react-calendar abbr[title], .ip-date .date-picker .react-calendar abbr[data-original-title] {
    text-decoration: none !important;
}
.ip-date .date-picker .react-calendar button {
    border-radius: 0.2rem;
}
.ip-date .date-picker .react-calendar__tile--now, .ip-date .date-picker .react-calendar__tile--now:hover {
    background: #c1e3ff;
}
.clinical-features {
    margin-top: 2rem;
    display: block;
    position: relative;
}

.clinical-features .feature-grp {
    display: inline-block;
    border: 1px solid rgb(219, 219, 219);
    padding: 0.5rem 0.5rem;
    border-radius: 0.8rem;
    margin: 1rem 1rem;
    overflow: hidden;
}
.clinical-features .feature-grp * {
    display: block;
    float: left;
}

.clinical-features .feature-grp span.label {
   margin-right: 0.4rem;
   padding-right: 0.4rem;
   color: #818181;
   border-right: 1px solid rgb(228, 228, 228);
}

.clinical-features .feature-grp.yrn .ip-checkbox{
    margin-top: 1px;
    margin-left: 2px;
}
.feature-grp.yrn .ip-checkbox  .checkmark {
    
}
.clinical-features .feature-grp.txt input{
    border: none;
    outline: none;
    max-width: auto;
    width: auto;
    background-color: transparent;
}
.thpy-feilds-grp {
    margin-top: 2rem;
}
.thpy-feilds-grp .form-group {
    margin-bottom: 2rem;
}
.thpy-feilds-grp .ip-text-line {
    position: relative;
    display: flex;
    margin-bottom: 0.6rem;
    
}
.thpy-feilds-grp .ip-text-line label{
    position: relative;
    display: block;
    float: left;
    width: auto;
    padding: 0px;
    margin-bottom: 0rem;
    line-height: normal;
    margin-right: 1rem;
    top: 0.8rem;
    color: #9e9e9e;
}

.thpy-feilds-grp .ip-text-line input[type=text] {
    border: none;
    border-bottom: 2px solid #9e9e9e;
    flex: 1;
    outline: none;
    box-shadow: none;
    background-color: transparent;
}
.thpy-feilds-grp .ip-text-line input[type=text]:focus {
    border-color:  #459ae9;
}
.thpy-feilds-grp .ip-text-line input:focus {
    outline: none;
    box-shadow: none;

}
/*Select Options*/
.sptm-frm-grp .select_options {
    width: 90.4%;
    display: block;
}

.select_options {
    display: block;
    border: 1px solid rgb(243, 243, 243);
    background-color: #fff;
    border-radius: 8px;
    margin-top: 1px;
    position: absolute;
    bottom: -20.1rem;
    left: 0;
    z-index: 2;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.034);
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

.ip-ff-select .picture-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-weight: 600;
    color: #bdbdbd;
    font-size: 1.2rem;
    overflow: hidden;
    margin: 0.5rem 0.8rem;
    text-transform: uppercase;
    display: inline-block;
    width: 2.1rem;
    height: 2.1rem;
}

.ip-ff-select .picture-content .picture{
    width: 2.2rem;
    height: 2.2rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 50%;
    pointer-events: none;
    border: 1px solid #f8f8f8;
    background-color: #dadada;
}
.ip-ff-select .selected-option .picture-content,
.ip-ff-select .selected-option .picture{
    width: 3.1rem !important;
    height: 3.1rem !important;
}

.ip-ff-select .options--default {
    background-color: #f5f5f5;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.054);
    margin-top: 2px;
    width: calc(100% - 65px);
}
.select_options .options {
    padding-bottom: 0.1rem;
}
.select_options.--gray {
    background-color: #f5f5f5;
    border: 1px solid rgb(230, 230, 230);
}

.options--default .search-component {
    background: linear-gradient(to top, rgba(243, 243, 243, 0), #f9f9f9b2, #f9f9f9);
}
.select_options .search-component .search-filter-field, .options--default .search-filter-field  {
    border: 1px solid rgb(235, 235, 235) !important;
    padding: 0.4rem 1rem;
    outline: none;
    width: 100%;
    border-radius: 0.6rem;
}
.template-option {
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    display: block;
    margin: 0 0.2rem;
    border-radius: 0.2rem;
}
.template-option:hover {
    background-color: #e9e9e9;
}
.template-option.--selected {
    background-color: #e9e9e9;
}
.patient-opt {
    display: flex;
    line-height: normal;
    align-items: baseline;
    cursor: pointer;
    @include disable-user-select();
}
.patient-opt .patinet-info, .patient-opt .regist-no {
    font-size: 1.1rem;
}
.patient-opt .patinet-info label   {
    cursor: pointer;
}

.patient-opt .patient-picture {
    color: #fff;
    width: 2rem;
    height: 2rem;
    display: inline-block;
    border-radius: 50%;
    margin: 0.5rem 0.8rem;
    text-align: center;
    padding: 0.45rem 0.25rem;
    font-weight: 600;
    font-size: 0.9rem;
    background-color: #15a2f3;
}
.ip-ff-select .template-option   {
    padding: 0rem !important;
    cursor: pointer;
    @include disable-user-select();
}
.patient-opt .regist-no {
    flex: 2 auto;
    text-align: right;
    color: #2b2b2b;
    padding-right: 1rem;
}
.patient-opt .regist-no p {
    padding: 0.5rem;
    color: rgb(104, 104, 104);
}
.patient-opt .regist-no span {
    color: #459ae9;
    margin-left: 0.2rem;
}

.spmt-form .role-chooser .roles-type-selection li label{
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.atch-sec {
    border-top: 1px solid #ececec;
    margin-top: 4rem;
}
.atch-sec h3 {
    margin: 1rem 0rem;
    font-size: 1.3rem;
    color: #6d6d6d;
}
.atch-sec h3 .icon {
    margin-right: 0.5rem;
    position: relative;
    top: -5px;
}
.atch-sec h3 .icon img {
    width: 0.8rem;
}
.fPvew {
    display: block;
    float: left;
    margin: 0 0 16px 16px;
    height: 120px;
    width: 180px;
    position: relative;
    border-radius: 2px;
    border: 1px solid #eeeeee;
}
.fPvew .aLnk {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    text-decoration: none;
}
.fPvew .aLnk:hover {
    background-color: #f0f0f0;
}

.fPvew .fNme, .action-sec .fNme{
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.fPvew .ext, .action-sec .ext {
    margin-right: 0.3rem;
    text-align: center;
    display: inline-block;
    padding: 4px 3px;
    font-size: 0.5rem;
    font-weight: 900;
    letter-spacing: -0.03rem;
    border-radius: 2px;
    color: #f1f1f1;
    background-color: #429add;
}
.fPvew .name, .action-sec .name {
    font-size: 0.9rem;
    color: #858585;
    font-weight: 700;
}
.action-sec .name {
    font-size: 0.9rem;
    color: #e0e0e0;
    font-weight: 700;
}


.aSI {
    position: absolute;
    bottom: -1px;
    right: -1px;
    z-index: 3;
    height: 20px;
    width: 20px;
    border-right: solid 20px #ffffff;
}

.aSI .aSJ {
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: -20px;
    border-width: 10px;
    border-style: solid;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #007bff transparent transparent transparent;

    z-index: 3;
}

.aSI:after {
    content: "";
    font-size: 0;
    position: absolute;
    bottom: 0;
    right: 0px;
    z-index: 4;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 20px 20px;
    border-color: transparent transparent #adadadbb transparent;
}

.viewer-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: block;
}

.viewer-modal .viewer-layout-wrap {
    background: rgb(233, 233, 233);//rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
}

.viewer-modal .viewer-layout-wrap .nav-bar {
    display: block;
    height: 3rem;
    z-index: 2;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.699));
}
.viewer-modal .action-sec {
    display: flex;
}
.back-button {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background: none;
    border: 1px solid red;
    margin: 6px 2rem;
}
.back-button:hover {
    background-color:rgba(207, 207, 207, 0.247);
}

.back-button:focus {
    background-color: transparent !important;
}
.back-button:active {
    background-color: rgba(207, 207, 207, 0.61) !important;
}

.arrow-left {
    display: block;
    margin: 0px auto;
    width: 14px;
    height: 14px;
    border-top: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    transform: rotate(315deg);
    position: relative;
    left: -3px;
}
.arrow-left::after {
    content: "";
    display: block;
    width: 2px;
    height: 20px;
    background-color: #ffffff;
    transform: rotate(-45deg) translate(10px, 3px);
    position: relative;
    left: -4px;
    top: 1px;
}
.viewer-modal .viewer-layout-wrap .content-area {
    width: 100%;
    height: 100%;
    display: table;
    position: fixed;
    z-index: 1;
    position: absolute;
    top: 0;
}
.viewer-modal .viewer-layout-wrap .img-view {
    width: 100%;
    height: calc(100vh);
    position: relative;
    left: 0px;
    top: 0px;
    display: block !important;
}

.viewer-modal .loader-wrap {
    margin: 1rem auto;
    display: block !important;
}

.viewer-modal .loader-wrap .lds-roller {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -o-transform: scale(0.6);
    -moz-transform: scale(0.6);
}

.viewer-modal .viewer-layout-wrap  .content-area  * {
    display: table-cell;
    vertical-align: middle;
}

.viewer-modal .viewer-layout-wrap .img-view .image{
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    text-align: center;
    margin: 0px auto;
    display: block;
    vertical-align: middle;
    outline: 0;
    position: relative;
    border-radius: 1px;
    box-shadow: 0 0 6px #0000002b;
}

@media (prefers-dark-interface) { 
    .viewer-modal .viewer-layout-wrap {
        background: rgb(52, 52, 53);//rgba(0, 0, 0, 0.85);
    }
    .viewer-modal .viewer-layout-wrap .img-view .image{
        box-shadow: 0 0 4px #00000073;
    }
}

@media (prefers-color-scheme: dark) {
    .viewer-modal .viewer-layout-wrap {
        background: rgb(52, 52, 53);//rgba(0, 0, 0, 0.85);
    }
    .viewer-modal .viewer-layout-wrap .img-view .image{
        box-shadow: 0 0 6px #00000073;
    }
}

.specimen-list .loader-wrap.--fixed{
    margin: 0.5rem auto;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    right: 0rem
}


.tool-tip {
    position: relative;
    display: inline-block;
}

.tool-tip .tooltiptext {
    visibility: visible;
    position: absolute;
    width: max-content;
    display: block;
    background-color: #f3f4f5;
    color: rgb(128, 130, 131);
    text-align: center;
    padding: 5px 12px;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    font-size: 1.1rem;
    transition: opacity .6s;
}

.tool-tip:hover .tooltiptext {
    opacity: 1;
}

.tooltip-bottom {
    top: 135%;
    left: 50%;
    margin-left: -60px;
}

.tooltip-right {
    top: -5px;
    left: 107%;
}
.tooltip-right::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #f3f4f5 transparent transparent;
}

.tooltip-bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #64686b transparent;
}

.cabinet-contents {
    margin-top: 2rem;
    border-top: 1px solid #e6e6e6;
    padding-top: 1rem;
    width: 100%;
}
.cabinet-contents h3{
    font-size: 1.2rem;
    color: #616161;
    margin: 0rem auto 0rem 1rem;
}
.cabinet-contents .add-cabinet-btn {
    width: 1.3rem;
    background-color: transparent;
    color: #686868 !important;
    margin: 1rem 0rem;
    border: 1px solid;
    width: 100%;
    padding-left: 0.8rem;
    text-align: left;
}
.cabinet-contents .add-cabinet-btn:active {
    background-color: #e9e9e900 !important;
    opacity: 0.7;
}

.cabinet-contents .add-cabinet-btn .icon  {
    display: inline;
    margin-right: 0.5rem;
}
.cabinet-contents .add-cabinet-btn .icon img {
    width: 1.3rem;
    opacity: 0.6;
}

.folder-content-list {
    border: 2px dashed rgb(238, 238, 238);
    border-radius: 0.5rem;
    padding: 0.3rem;
    min-height: 5rem;
    max-height: 25rem;
    overflow-y: auto;
    margin-bottom: 2rem;
}