@import "./../../assets/styles/mixins/global-mixins";
/* Chrome, Safari, Edge, Opera */
.payroll-wizard input::-webkit-outer-spin-button,
.payroll-wizard input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Firefox */
.payroll-wizard  input[type=number] {
  -moz-appearance: textfield;
}

.payroll-overview {
    width: 75%;
    margin: 0rem auto;
}

.payroll-view .title {
    margin: 1rem auto 2rem auto;
    font-size: 2rem;
}

.start-payroll  {
    margin-bottom: 2rem;
}
.start-payroll .icon  {
    pointer-events: none;
    @include disable-user-select();
    margin-bottom: 1rem;
}
.start-payroll .icon img {
    width: 5rem;
    margin: 0rem auto;
    display: block;
    opacity: 1;
    pointer-events: none;
}
.start-payroll h1 {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    font-weight: 600;
}
.start-payroll p {
    color: #6f7375;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 2rem;
}
.start-payroll .run-btn {
    margin: 0rem auto 1rem auto;
    background-color: #2885bd;
    padding: 0.6rem 2.5rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    font-weight: 600;
}
.start-payroll {
    text-align: center;
}
.title-box  {
    margin: 1rem 0rem;
}
.title-box .title {
    font-size: 2rem;
}
.payroll-detail-view .title-box .title {
    font-weight: 600;
}
.lastest-section  .title{
    font-size: 1.2 !important;
}
.payroll-view .section {
    margin-bottom: 2rem;
}
.payroll-view .section .label {
    font-size: 1.2rem;
    color: rgb(73, 73, 73);
}
.lastest-section .jouranl-item{
    border: 2px solid #2685bd; 
    border-left: 4px solid #2685bd; 
    border-radius: 0.2rem;
    display: flex;
    padding: 1rem;  
    width: 100%;
    justify-content: space-between;
    box-shadow: 0px 2px 3px #00000025;
    @include disable-user-select();
}
.lastest-section .jouranl-item:hover{
    box-shadow: 0px 4px 6px #0000001e;
}
.lastest-section .jouranl-item:hover {
    background-color: #f7fdff;
    border-color: #147cb8;
}
.lastest-section .jouranl-item .smry-btn {
    border: 2px solid #b8b8b8 !important;
    background-color: transparent;
    color: #b4b4b4;
    font-weight: 500;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 1.5rem;
}
.lastest-section .jouranl-item h2{
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #535353;
}
.lastest-section .jouranl-item h3{
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #333333;
}
.lastest-section .jouranl-item p{
    color: #7e7e7e;
    font-size: 1.1rem;
}

.jouranl-item a {
    font-size: 1.2rem;
    .arrow-indicator {
        top: 0.5rem !important;
    }
}

.payroll-steps-nav-wrap {
    margin-bottom: 1rem;
}
.nav-steps {
    border: none;
    position: relative;
}

/*
*Payroll View
*-------------------------------------------
*/
table.payroll-table tbody td {
    min-height: 5.3rem;
    cursor: normal;
}
table.payroll-table thead tr th{
    width: auto;
}
table.payroll-table tbody tr{
    overflow: hidden;
}
table.payroll-table .th-employee-cell , table.payroll-table tbody td.id-employee-cell{
    width: 30%;
}
table.payroll-table .th-taxes-cell , table.payroll-table tbody td.id-taxes-cell{
    width: 20%;
}
table.payroll-table .th-date-cell , table.payroll-table tbody td.id-date-cell{
    width: 22.5%;
}
table.payroll-table .th-gross-pay-cell , table.payroll-table tbody td.id-gross-paycell{
    width: 25%;
}
table.payroll-table .id-employee-cell .employee-info {
    display:  flex;
}

table.payroll-table tbody tr:nth-of-type(odd) {
    background-color: rgba(241, 241, 241, 0.20);
}
table.payroll-table tbody tr:nth-of-type(odd) {
    border-left: 4px solid #dbdbdb;
}
table.payroll-table tbody tr:nth-of-type(even) {
    border-left: 4px solid #cfcfcf5e;
}
.payee-list {
    display: block;
    width: 100%;
}
.payee-list .payee {
    border: 1px solid #dcf0ff;
    background-color: #e8f4ff;
    display: inline-block;
    border-radius: 0.3rem;
    list-style: none;
    padding: 0.1rem 0.5rem;
    margin: 0.3rem;
    font-size: 0.72rem;
    color: #696f73;
    font-weight: 500;
}
table.payroll-table .taxes-info {
    font-weight: 600;
}
table.payroll-table .date-info {
    font-weight: 500;
    color: #4e4e4e;
}
table.payroll-table .gross-pay-content .gross-pay {
    font-weight: 600;
}


table.payroll-table .status {
    background-color: #edf0f1;
    padding: 0.2em 0.5rem;
    font-size: 0.7rem;
    text-transform: uppercase;
    border-radius: 0.4rem;
    font-weight: 500;
    display: inline-block;
    width: 5rem;
    text-align: center;
    color: #7e8586;
}

table.payroll-table .status.--approved {
    background-color: #c8ffe6;
    color: #16bd6f;
}
/*End of Payroll*/

body {
    /* Set "my-sec-counter" to 0 */
    counter-reset: progress-bar-counter;
  }
.nav-steps .steps  {
    border: none;
    width: 24.9%;
    position: relative;
    margin-right: 0rem;
}

.nav-steps .steps .cut-out {
    border: none;
    width: 2.4rem;
    height: 1.6rem;
    content: '';
    position: absolute;
    top: -4px;
    left: -31px;
    z-index: 1;
    background-color: #fff;
    border-radius: 0rem 3rem 3rem 0rem;
}
.nav-steps .steps:first-child .cut-out,
.nav-steps .steps:first-child .nav-link::after {
    display: none;
}

.nav-steps .steps .nav-link {
    border: none;
    padding: 0rem 2rem !important;
    background-color: rgb(241, 241, 241);
    border-radius: 0rem 3rem 3rem 0rem;
    width: 100%;
    z-index: 3;
    height: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: normal;
}

.nav-steps .steps .nav-link::before  {
    /* Increment "my-sec-counter" by 1 */
    counter-increment: progress-bar-counter;
    content: counter(progress-bar-counter,decimal) ". ";
    position: absolute;
    left: 1rem;
}

.nav-steps .steps:first-child .nav-link {
    border-radius:  3rem ;
}
.nav-steps .steps .nav-link::after {
    border: none;
    width: 0.8rem;
    height: 1.5rem;
    content: '';
    position: absolute;
    top: -3px;
    left: -8px;
    bottom: 0rem;
    background-color: #ffffff !important;
    border-radius: 0rem 3rem 3rem 0rem;
    z-index: 3;
}

.nav-steps .steps .nav-link.active,
.nav-steps .steps .nav-link.active::after {
    background-color: #5EB5FC;
    color: #fff;
    font-weight: 600;
}

.nav-steps .steps.--previous .nav-link,
.nav-steps .steps.--previous .nav-link::after {
    background-color: #5EB5FC;
    color: #fff;
}
.nav-steps .steps.--disabled .nav-link,
.nav-steps .steps.--disabled .nav-link::after {
    cursor: default;
    pointer-events: none; 
}
.nav-steps .steps.--disabled .nav-link {
    color: rgb(160, 160, 160);
}
.nav-steps .steps.--final .nav-link,
.nav-steps .steps.--final .nav-link::after{
    background-color: #15c402
}
/*Button Sections*/
.actions-section {
    margin-top: 2rem;
    text-align: right;
}
.actions-section button {
    margin-left: 0.5rem;
    border-radius: 0.5rem;
    background-color: #ececec;
    color: #5c5c5c;
    font-weight: 500;
    padding: 0.5rem 2rem;
}
.actions-section button:hover {
    background-color: #dadada;
    color: #5c5c5c;
}
.actions-section button:focus {
    background-color: #d3d3d3 !important;
    color: #5c5c5c !important;
}
.actions-section .submit-btn {
    color: #fff !important;
    background-color: #8cc63f !important;
}

.actions-section .submit-btn:focus {
    color: #fff !important;
    background-color: #80b936 !important;
}

/*Earnings Pane*/
.earnings-pane {
    position: relative;
}
.earnings-pane .ip-date label {
    width: 10rem;
}
.date-wrap {
    width: 30rem;
    margin: 2rem auto;
}
.earnings-pane .ip-date{
    border-radius: 0.6rem;
}
.earnings-pane  .react-datetime-picker__calendar {
    z-index: 5 !important;
}
.emp-count {
    font-size: 1.1rem;
    color: #4d4e4e;
    min-height: 1.5rem;
    font-weight: 400;
}
table.earnings-table tbody td {
    min-height: 8.5rem;
    cursor: normal;
}
table.earnings-table thead tr th{
    width: auto;
}
table.earnings-table .th-employee-cell , table.earnings-table tbody td.id-employee-cell{
    width: 30%;
}
table.earnings-table .th-rate-hours-cell , table.earnings-table tbody td.id-rate-hours-cell{
    width: 20%;
}
table.earnings-table .th-added-earnings-cell , table.earnings-table tbody td.id-added-earnings-cell{
    width: 22.5%;
}
table.earnings-table .th-gross-pay-cell , table.earnings-table tbody td.id-gross-pay-cell{
    width: 25%;
}
table.earnings-table .id-employee-cell .employee-info {
    display:  flex;
}
table.earnings-table .id-employee-cell .employee-info .picture-holder {
    margin-right: 0.8rem;
}
table.earnings-table tbody tr {
    cursor: initial !important;
}
table.earnings-table .employee-info-holder h3 {
    font-size: 1.2rem;
    color: #2c2c2c;
}
table.earnings-table .employee-info-holder p.emp-info {
    font-size: 0.85rem;
    color: #9e9e9e;
    line-height: normal;
    font-weight: 500;
    margin: 0rem 0rem 0.4rem 0rem;
}
table.earnings-table .employee-info-holder p.emp-info.highlight {
    color: #414141;
    font-weight: 500;
    margin-top: 0.1rem;
}
table.earnings-table tbody tr:nth-of-type(odd) {
    background-color: rgba(241, 241, 241, 0.25);
}
table.earnings-table .add-item{
    line-height: normal;
    margin: 0.4rem 0rem 1.2rem 0rem;
    display: block;
    text-decoration: none;
    color: #338ad1;
    font-weight: 500;
    padding: 0;
}
table.earnings-table .add-item:hover{
    color: #2b9dfa;
}
table.earnings-table .add-item:hover .plus-icon{
    border-color: #2b9dfa;
}
table.earnings-table .add-item .plus-icon{
    font-size: 14px;
    top: -1px;
    display: inline-block;
    position: relative;
    margin-right: 0.3rem;
    border: 2px solid #4c95d1;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: 0rem;
    text-align: center;
    font-weight: 500;
    line-height: 10px;
}

table.earnings-table tbody td .fields-group .field{
    display: flex;
    width: auto;
    margin: 0rem 0rem 0.5rem 0rem;
    color: #414141;
}
table.earnings-table tbody td .fields-group  label{
    font-size: 0.8rem;
    display: block;
    margin-bottom: 0rem;
    font-weight: 500;
    color: #5c5c5c;
}
table.earnings-table tbody td .fields-group .field input{
    font-size: 1.1rem;
    border-radius: 0.1rem;
    background-color: transparent;
    border: 2px solid transparent;
    font-weight: 500;
}
table.earnings-table tbody td.id-rate-hours-cell .fields-group .field input#hours{
    padding-left: 0.1rem;
}
table.earnings-table tbody td .fields-group .field input:hover{
    border-color: #5ca9f1;
}
table.earnings-table tbody td .fields-group .field .symbol{
    position: relative;
    top: 0.2rem;
    margin-right: 0px;
}
table.earnings-table tbody td .fields-group .field .symbol.f{
    font-size: 1.1rem;
    left: 0.1rem;
    z-index: 2;
}
table.earnings-table tbody td .fields-group .field .symbol.b,
table.earnings-table tbody td .symbol.info{
    color: #777777;
}
table.earnings-table tbody td .fields-group .field .hours{
    display: flex;
    width: 4rem;
}
table.earnings-table .gross-pay-content p.gross-pay {
    font-size: 1.1rem;
    font-weight: 500;
    color: #414141;
}
table.earnings-table tbody tr.--selected {
    background-color: #f4faff;
}

table.earnings-table tbody td.id-gross-pay-cell .field *{
    color: #ee7e22 !important;
}

/*Taxes Pane*/
.taxes-pane {
    width: 80%;
    margin: 2rem auto;
}
.taxes-pane  p{
    font-size: 1.2rem;
} 
.taxes-pane .taxes-list {
    margin-top: 1rem;
}
.taxes-pane .taxes-list .titles{
    display: flex;
    justify-content: space-between;
    color: #6f7375;
}
.taxes-pane .taxes-list .titles div{
    padding: 0.2rem 1rem;
}
.taxes-pane .taxes-list .titles div.percent{
    padding-right: 3rem;
}
.taxes-pane .tax-item {
    border: 1px solid #ebedee;
    display: block;
    border-radius: 0.3rem;
    margin-bottom: 0.5rem;
    background-color: #f9f9f9;
    position: relative;
}
.taxes-pane .tax-item:hover {
    box-shadow: 0px 2px 6px #0000000c;
}
.taxes-pane .tax-item .field-group{
   display: flex;
   justify-content: space-between;
   padding: 0.5rem 1rem;
}
.taxes-pane .tax-item .field-group input{
    font-size: 1.6rem;
    outline: none;
    padding: 0.1rem 0.5rem;
    border: none;
    font-weight: 500;
    background-color: transparent;
    color: #424242;
    text-transform: uppercase;
    border-bottom: 2px solid transparent !important;
}
.taxes-pane .tax-item .field-group .field.percent {
    margin-right: 2rem;
}
.taxes-pane .tax-item .field-group .symbol{
    position: relative;
    top: 0rem;
    display: inline-block;
    font-size: 1.5rem;
    color: #949494;
    cursor: pointer !important;
}
.taxes-pane .tax-item .field-group .field.percent input{
    width: 5rem;
    text-align: right;
}
.taxes-pane .tax-item .field-group input:hover{
    border-color: #d4d4d4 !important;
}
.remove-icon {
    width: 20px;
    height: 20px;
    border: 2px solid #ff0000;
    position: absolute;
    right: 0.8rem;
    display: none;
    border-radius: 50%;
}
.taxes-pane .remove-icon {
    top: 1.5rem;
}
.taxes-pane .tax-item:hover .remove-icon   {
    display: block;
}

.taxes-list .no-tax-wrapper{
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9e9e9e;
}

.remove-icon .dash {
    width: 10px;
    height: 3px;
    background-color: #ff0000;
    display: block;
    margin: 7px auto;
}
.taxes-pane .tax-btn-holder {
    margin-top: 2rem;
}
.ip-basic-select  {
    border: 1px solid transparent;
    outline: none !important;
    background-color: transparent;
    font-weight: 500;
    font-size: 1.3rem;
    color: #535353;
    padding: 0.2rem 0.6rem;
}
.ip-basic-select:focus  {
    border: 1px solid transparent;
    outline: none !important;
    box-shadow: none;
    background-color: #f7f7f7;
}

.taxes-pane .form-group {
    margin: 0.4rem 0rem;
}

.taxes-pane .add-tax-btn,
.taxes-pane .add-tax-btn:visited {
    width: 100%;
    padding: 0.9rem;
    background-color: transparent;
    color: #747474 !important;
    border: 2px dashed #dfdfdf !important;
    font-size: 1.2rem;
}
.taxes-pane .add-tax-btn:hover, 
.taxes-pane .add-tax-btn:active{
    background-color: #e3f5ff !important;
    border: 2px dashed #39aaff !important;
}
.taxes-pane .add-tax-btn  span.icon {
    margin-right: 0.5rem;
    opacity: 0.4;
}
.taxes-pane .add-tax-btn  span.icon img {
    height: 1.5rem;
}
/*Rewiew Payroll*/
.review-pane .title-info {
    margin: 2rem 0rem;
}
.review-pane .title-info h2 {
   font-size: 1.5rem;
   margin-bottom: 0;
}
.review-pane .title-info p {
    color: #636363 !important;
}
.review-pane a {
    font-size: 1.2;
    font-weight: 600;
}

/*Review Payroll*/
.payroll-summary {
    margin-bottom: 2rem;
}
.payroll-summary .summary-box{
    border-radius: 0.4rem;
    border: 1px solid #edf3fb;
    background-color: #f5fbff;
    padding: 2rem  2rem;
    display: flex;
    justify-content: space-between;
}
.payroll-summary .summary-box h3{
    text-transform: uppercase;
    font-size: 1rem;
    color: #7a7a7a;
    font-weight: 500;
    letter-spacing: 0.02rem;
}
.payroll-summary .summary-box h1{
    color: #313131;
    font-size: 2rem;
    margin-bottom: 0rem;
}
.payroll-summary .summary-box .date-details h1{
    color: #313131;
    font-size: 1.7rem;
}
.payroll-summary .summary-box .payout-details .amount{
    font-weight: 600;
    color: #131313;
}
.payroll-summary .summary-box p{
    color: #87888a;
}
.earnings-tables {
    margin-top: 2rem;
}
.smry-table {
    margin-top: 1rem;
    width: 100%;
    margin-bottom: 1rem;
}
.smry-table.--odd-rows tr:nth-child(even) {
    background: rgba(244, 245, 245, 0.4);
} 

.smry-table tr td:last-child,
.smry-table tr th:last-child
{
    text-align: right;
} 
a.arrow-link{
    position: relative;
    color: #1479cc !important;
    padding: 0;
    padding-left: 1rem;
    text-decoration: none;
    font-weight: 500;
}
a.arrow-link .arrow-indicator {
    border: solid #1479cc;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    position: absolute;
    transform: rotate(0deg);
    -webkit-transform: rotate(-50deg);
    left: 0rem;
    top: 7px;
}

a.arrow-link .arrow-indicator.--show {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 0.15rem;
}

.smry-table thead tr {
    border-top: 1px solid rgb(244, 245, 247);
    background-color: #f7f7f7;
    font-size: 1rem !important;
}
.smry-table tbody tr {
    border-top: 1px solid rgb(244, 245, 247);
    border-bottom: 1px solid rgb(244, 245, 247);
    font-size: 1rem !important;
}
.smry-table thead tr th {
    font-size: 1rem !important;
    padding: 0.5rem 0.5rem;
    color: #494949;
    font-weight: 600;
}
.smry-table tbody tr td  {
    font-size: 1rem !important;
    padding: 0.6rem 0.4rem;
    color: #666666;
    font-weight: 500;
}
.smry-table .totals-row td {
    padding: 1rem 0.5rem;
    background-color: rgb(255, 255, 255) !important;
    font-weight: 600;
    color: #292d30;
}
.smry-table .totals-row td:last-child {
    border-radius: 0rem 0.2rem 0.2rem 0rem;
}
.smry-table .totals-row td:first-child {
    border-radius: 0.2rem 0rem 0rem 0.2rem;
}

/* review-pane approve */
.review-pane.approve .title-info.sucess h2,
.review-pane.approve .payroll-summary .payout-details h1,
.review-pane.approve .payroll-summary .payout-details h1 .amount{
    color: #14c404;
} 
.review-pane.approve .payroll-summary .summary-box{
    border: 1px solid #d7fcd7;
    background-color: #e9ffe9;
}
.action-links {
    flex: 0 0 80%;
}

.action-links a {
    display: block;
    margin-bottom: 1rem;
    margin-left: 1rem;
    font-weight: 600;
    display: inline-block;
}
.action-links a .arrow-indicator {
    top: 8px;
}
.action-links .summary-details {
    margin-left: 1rem;
}
.payroll-meta-data {
    display: flex;
    justify-content: space-between;
}
.approve-info {
    position: relative;
}

.approve-info .apprv-hldr{
    bottom: 0;
    top: unset;
}

.payroll-meta-data .approve-info h2 {
    color: #292d30;
    font-size: 1.2rem;
    margin-bottom: 0rem;
}
.payroll-meta-data .approve-info label {
    color:#797979;
    font-size: 0.8rem;
    margin-bottom: 0rem;
}
.payroll-meta-data .approve-info p {
    color:#797979;
}

.earnings-pane .loader-wrap  {
    position: relative;
    bottom: 0rem;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-items: center;
    margin: 0rem auto;
}
/*Detals view*/
.payroll-detail-view {
    padding-bottom: 0rem;
}
.payroll-detail-view .default-Ly-ct {
    
}
.payroll-detail-view .title-box{
    border-bottom: 2px solid #2685bd;
    margin-top: 2rem;
    
}
.payroll-detail-view .sumry-title {
    margin: 1.6rem 0rem 3.5rem 0rem;
}
.payroll-detail-view .sumry-title  h3{
    font-size: 1.5rem;
    font-weight: 600;
}
.payroll-detail-view .receipt-box {
    border: 1px solid #e9e9e9;
}

.payroll-detail-view .receipt-box .section{
    padding: 1rem 2rem;
}

.payroll-detail-view  .perforation {
    height: 1px;
    background-image: repeating-linear-gradient(90deg, #e6e6e6 0, #dfdfdf 4px, transparent 0, transparent 8px);
    position: relative;
}

.payroll-detail-view  .perforation:before, .payroll-detail-view .perforation:after {
    display: block;
    content: "";
    position: absolute;
    top: -13px;
    width: 13px;
    height: 26px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
}

.payroll-detail-view .perforation::after{
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    border-right: 0;
    right: -33px;
}

.payroll-detail-view .perforation:before {
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    border-left: 0;
    left: -33px;
}

.payroll-receipt .title-flex {
    padding: 0.5rem 0rem 1.5rem 0rem;
    display: flex;
    justify-content: space-between;
}

.payroll-receipt .meta-data-details .meta-data-grp{
    margin: .5rem 0rem 1.5rem 0rem;
    font-weight: 500;
    color: #666666;
}
.payroll-receipt .meta-data-details .meta-data-line span.label {
    margin-right: 0.6rem;
    display: inline-block;
}

.payroll-receipt  .payroll-totals {
    border: 1px solid #e6e6e6;
    border-left: none;
    border-right: none;
}

.payroll-receipt  .payroll-totals .total {
    display: flex;
    justify-content: space-between;
    color: #474747;
}

.payroll-receipt  .payroll-totals .total.lrg {
    font-weight: 600;
    font-size: 1.4rem;
}
.payroll-receipt .info-text {
    margin-bottom: 1.4rem;
    font-size: 1rem;
    font-weight: 500;
}
.cards-sec .meta-data-card {
    margin-bottom: 1.5rem;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    background-color: #f9f9f9;
}
.cards-sec .meta-data-card .inner-content {
    padding: 0.6rem 1rem;
}

.cards-sec .meta-data-card .title {
    font-weight: 600;
    font-size: 1.3rem;
    color: #242424;
}

.cards-sec .meta-data-card .meta-data {
    margin-bottom: 0.7rem;
    font-weight: 500;
    color: #5c5c5c;
}
.cards-sec .meta-data-card .meta-data .label {
    font-size: 0.9rem;
}
.cards-sec .meta-data-card .meta-data p {
    line-height: 1.4rem;
    font-weight: 500;
    color: #474747;
}
.cards-sec .meta-data-card .meta-data p.compromise {
    color:#d42929;
}
.cards-sec .meta-data-card .meta-data  p.verified {
    color:#3ab813;
}

.payroll-meta-details  {
    margin-top: 2rem;
}

.payroll-detail-view .action-links a {
    display: inline-block;
    font-weight: 600;
}

.smry-table .arrow-link  {
    border-bottom: 1px dotted transparent;
}
.smry-table tbody tr:hover .arrow-link  {
    border-bottom: 1px dotted;
}

/* Payslip */
.payroll-detail-view.payslip .title-box {
    border: none;
}
.pay-period h3{
    font-size: 1.1rem;
    color: #444444;
}

.payslip-wrap {
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.payslip-wrap .employee-name{
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #737373;
    padding: 0rem 0.2rem;
}

.payslip-wrap .payslip {
    border: 1px solid rgb(226, 226, 226);
    border-top: 2px solid #2685bd;
    height: auto;
    min-height: 22rem;
}

.payslip-wrap .payslip .compy-sec, 
.payslip-wrap .payslip .emple-sec {
    border-bottom: 1px solid #f5f5f5;
    height: 2rem;
    display: flex;
    padding: 0.1rem 0.6rem;
}
.erngs-deduc-sec .earnings {
    border-right: 2px solid #bebebe;
}
.payslip-wrap .payslip .item-grp .label {
    font-weight: 500;
    margin-right: 0.5rem;
    color:#3b3b3b;
}
.payslip-wrap .payslip .item-grp .value {
    font-weight: 500;
    color:#707070;
}
.payslip-wrap .payslip .compy-sec .col-split.c-1 {
    flex: 0 0 50%;
} 
.payslip-wrap .payslip .compy-sec .col-split.c-2 {
    flex: 0 0 30%;
}   
.payslip-wrap .payslip .compy-sec .col-split.c-3 {
    flex: 0 0 20%;
} 

.payslip-wrap .payslip .emple-sec .col-split.c-1 {
    flex: 0 0 35%;
} 
.payslip-wrap .payslip .emple-sec .col-split.c-2 {
    flex: 0 0 20%;
}   
.payslip-wrap .payslip .emple-sec .col-split.c-3 {
    flex: 0 0 20%;
}
.payslip-wrap .payslip .emple-sec .col-split.c-4 {
    flex: 0 0 25%;
}

.payslip-wrap .payslip .erngs-deduc-sec {
    display: flex;
}

.payslip-wrap .payslip .erngs-deduc-sec .earnings,
.payslip-wrap .payslip .erngs-deduc-sec .deductions {
    flex: 0 0 50%;
}

.payslip-wrap .payslip table th,
.payslip-wrap .payslip table td {
    padding: 0.3rem 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    text-align: right;
    
}
.payslip-wrap .payslip table tr.headings th {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700 !important;
}
.erngs-deduc-sec table tr {
    display: table-row; 
    height: 29px;
}
.payslip-wrap .payslip table tr:last-child td {
    border-bottom: 1px solid #b9b9b9;
}
.payslip-wrap .payslip table tr.blank td {
    height: 2rem;
    text-align: right;
    background-color: #fff !important;
}
.payslip-wrap .payslip table  td:first-child {
    color: #147cb8;
    font-weight: 400;
}
.payslip-wrap .payslip table  td:first-child a {
    border-bottom: 1px dotted #acacac;
    font-size: 0.82rem;
    font-weight: 500;
}
.payslip-wrap .payslip table td.left-text,
.payslip-wrap .payslip table th.left-text  {
    text-align: left;
}
.payslip-wrap .payslip table td  {
    font-size: 0.82rem;
}
.payslip-wrap .payslip table  {
    width: auto;
    min-width: 100%;
    border-collapse:collapse;
    border-spacing: 0;
    margin: 0;
}

.payslip-wrap .payslip table th.table-heading {
    color: #919191;
    padding: 0.2rem 0.5rem;
    width: 100%;
    background-color: #f7f7f7;
    text-align: left;
}

.payslip-wrap .payslip td+td, .payslip-wrap .payslip th+th {
    border-left: 1px solid #e4e4e4!important;
}

.payslip-wrap .payslip table tr:nth-child(2n+3) {
    background-color: #fafafa;
}
.totals-sec.section {
    border-bottom: 1px solid #dfdfdf;
}
.totals-sec.section .inner{
    display: flex;
    padding: 0rem 0.5rem;
}
.totals-sec.section .inner .split {
    flex: 0 0 50%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
}
.totals-sec.section .inner .split span{
    margin: 0rem 0.6rem;
    color: #494949;
}
.totals-sec.section .inner .split span.amount {
    font-weight: 600;
}
.payslip-summary {
    display: flex;
}
.payslip-summary  .smry-table-col {
    flex: 0 0 70%;
}
.payslip-summary .summary-tbl td.left-center{
    text-align: center !important;
}
.payslip-summary .summary-tbl td label{
    margin-bottom: 0rem;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 600;
    color: #4b4b4b !important;
}
.payslip-summary .summary-tbl td p{
    line-height: normal;
    color: #6d6d6d !important;
    font-size: 0.8rem;
    line-height: 0.9rem;
}
.payslip-summary .summary-tbl td{
    color: #6d6d6d !important;
    padding: 0.15rem 0.5rem !important;
}
.payslip-summary .summary-tbl tr:last-child td {
    border-bottom: none !important;
    border-top: 1px solid #eeeeee;
}

.payslip-summary  .yr-to-date-col {
    flex: 0 0 30%;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
}
.payslip-summary  .yr-to-date-col .label {
    margin-right: 0.5rem;
    font-weight: 600;
    color: #818181;
}

.net-pay-sec   {
    border-bottom: 1px solid #ebebeb; 
}
.net-pay-sec .inner  {
    display: flex;
}
.net-pay-sec .netpay-label {
    flex: 0 0 60%;
    display: flex;
    align-items: center;
    justify-content:flex-end;
}
.net-pay-sec .netpay-amount {
    flex: 0 0 40%;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
}
.net-pay-sec .netpay-label .label {
    margin-right: 0.5rem;
    font-weight: 500;
    color: #5a5a5a;
    padding: 0.1rem 0rem;
}
.net-pay-sec  .netpay-amount .amount {
    font-weight: 700;
    padding: 0.1rem 0rem;
}

.payroll-detail-view.payslip .loader-wrap {
    margin: 1rem auto;
    display: block;
}
.payroll-detail-view.payslip .loader-wrap .lds-roller {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    display: block;
    height: 60px;
    width: 60px;
    margin:0rem auto;
}
.payslip .print-bar {
    margin: 0rem 0.4rem;
}

@media print {
    
    .printable-area {
        padding: 2rem ;
    }
    .payslip-wrap {
        margin-bottom: 1.5rem;
    }
    .payslip-wrap .payslip {
        border-top: 2px solid #babcbd;
    }
    .payslip-wrap .payslip table td:first-child {
        color: #147cb8;
        font-weight: 400;
    }
    .payslip-wrap .payslip {
        border: 1px solid #cfcfcf;
    }
    .payslip-wrap .payslip td + td, .payslip-wrap .payslip th + th {
        border-left: 1px solid #cfcfcf !important;
    }
    .payslip-wrap .payslip .compy-sec, .payslip-wrap .payslip .emple-sec {
        border-bottom: 1px solid #efeff0;
    }
    
}