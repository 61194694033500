@import "./variables";
@import  '/node_modules/react-grid-layout/css/styles.css';
@import '/node_modules/react-resizable/css/styles.css';

.float-grid-parent {
    margin: 0 auto;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;

    &.float-grid-parent__static {    
        @media (min-width: breakpoint-min('sm', $grid-breakpoints)) {
            width: map-get($container-max-widths, 'sm');
        }
        @media (min-width: breakpoint-min('md', $grid-breakpoints)) {
            width: map-get($container-max-widths, 'md');
        }
        @media (min-width: breakpoint-min('lg', $grid-breakpoints)) {
            width: map-get($container-max-widths, 'lg');
        }
        @media (min-width: breakpoint-min('xl', $grid-breakpoints)) {
            width: map-get($container-max-widths, 'xl');
        }
    }
}

.float-col {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
}


.float-grid-parent {
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}
.react-grid-item.cssTransforms {
    transition-property: transform;
}
.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}
.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}
.float-column {
    display: flex;
    flex-direction: column;
}


/* React Grid Layout Overrides */
/* Avatar Variants Definitions */
.react-grid-layout {
    margin: 0 -15px; }
    .react-grid-layout .react-grid-item.react-grid-placeholder {
      background: none;
      z-index: 2;
      transition-duration: 100ms;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      position: relative;
      opacity: 0.7; }
      .react-grid-layout .react-grid-item.react-grid-placeholder:after {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        content: " ";
        background: rgba(30, 183, 255, 0.3);
        border-radius: 4px;
        border: 2px dashed #1EB7FF;
        height: calc(100% - 15px);
        width: calc(100% - 30px); }
  
  .float-column {
    display: flex;
    flex-direction: column; }
    .float-column > .card {
      flex: 1 1 auto;
      margin-bottom: 15px; }
      .float-column > .card + .react-resizable-handle {
        bottom: 15px;
        right: 15px; }
      .float-column > .card > .card-header {
        cursor: move;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        /*
              &:before {
                  position: absolute;
                  font: normal normal normal 14px/1 FontAwesome;
                  content: "\f142";
                  top: 50%;
                  left: 1rem;
                  transform: translateY(-50%);
                  color: $text-muted;
              }
              */ }
  
.float-column [class^="col-"] {
    flex: 0 0 100%;
    max-width: 100%;
}
  
.float-column--size-lg .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
.float-column > .card + .react-resizable-handle {
      bottom: 15px;
      right: 15px;
  }
    .react-grid-item > .react-resizable-handle {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      right: 0;
      cursor: se-resize;
  }
.react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

