@import "./../../assets/styles/mixins/global-mixins";

.profile-page{
  margin-bottom: 2rem;
}

.profile-pane{
  margin: 0 3rem;
}

.profile__pane-title{
  font-weight: 400;
  margin-top: 2.6rem;
}

.pane-title--margin{
  margin-bottom: 2.75rem;
}

.profile-pane .detail-section{
  display: flex;
  padding: 1.5rem 1.3rem;
  padding-right: 0;
  border-top: 2px solid #BCC8CE41;
  position: relative;
}

.profile-pane .detail-section:last-child{
  border-bottom: 2px solid #BCC8CE41;
}

.profile-pane .detail-section__title-container{
  width: 14.68rem;
}

.profile-pane .detail-section__lines-container{
  width: 100%;
}

.profile-pane .detail-section__lines-container-actions{
  width: 100%;
}

.profile-pane .detail-section .detail-edit {
  position: absolute;
  right: 0rem;
  top: 0rem;
  font-weight: 400;
}

.profile-pane .detail-line__container{
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0.8rem;
  border-bottom: 0px solid #BCC8CE41;
}

.profile-pane .detail-line__container .line-value{
  font-weight: 500;
  color: #3f3f3f;
}

.profile-pane .detail-line__container:first-child{
  padding-top: 0;
}

.profile-pane .detail-line__container:last-child{
  border: none;
}

.profile-pane .detail-line__title-container{
  display: flex;
  flex-direction: column;
}

.profile-pane .detail-section__title{
  font-size: 1rem;
}

.detail-line__label--grey{
  font-size: 0.875rem;
  color: #808080;
}

.detail-line__container .nav-link{
  font-size: 1rem;
  font-weight: 500;
  padding: 0;
}

.detail-line__cancel-edit{
  align-self: flex-end;
  color: #808080;
  background-color: #F2F2F2;
  margin-top: 1rem;
  border-radius: 0.5rem;
}

.detail-line.default-btn {
  align-self: flex-end;
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 0.375rem 0.75rem;
  margin-right: 1rem;
}


.profile-pane .detail-line__editable-field{
  margin-top: 0.5rem;
  border-radius: 0.5rem;
}
.profile-pane .image-thumbnail-box.profile-pic  div{
  width: 8.5rem;
  height: 8.5rem;
}
.profile-pane .profile-pic__container{
  margin: 1.875rem 0 4.625rem 2.3rem;
  display: flex;
  align-items: center;
}

.profile-pane .image-text{
  font-size: 2.1rem;
  color: #ececec;
  position: relative;
  top: 1rem;
  pointer-events: none;
  @include disable-user-select()
}

.profile-pane .user--text-gender {
  margin-left: 2rem;
  color: #3f3f3f;
  margin-top: 0.5rem;
  font-weight: 500;
}

.profile-pane .user--text-gender .gender {
  margin-top: 0.8rem;
  margin-left: 0.5rem;
  color: #575757;
}

.password-asterisk__container{
  margin-top: 0.5rem;
}

.password-asterisk{
  width: 0.31rem;
  height: 0.31rem;
  margin: 0 0.2rem;
}

.password__edit-block{
  padding-right: 8rem;
}

.password__edit-block > input {
  margin-bottom: 0.5rem;
}

.password-nvm{
  margin-right: 8rem;
}

.detail-sec-action {
  display: flex;
  justify-content: flex-end;
}

.detail-line-loader .loader-wrap.--inline{
  margin-top: 0.8rem;
  margin-right: 0.5rem;
}