@import "./../../assets/styles/mixins/global-mixins";

.messages-list .view-layout-content .spmt-title-d {
    border: none;
}
.messages-list   .view-layout-content  {
    width: calc(100% - 250px);
}

.table-responsive.messages tbody td.id-picture-cell {
    width: 2.7rem;
}
.react-bootstrap-table tbody td.id-details-cell .message-details {
    width: 100%;
    height: 100%;
    position: relative;
}
.table-responsive.messages tbody .selection-cell {
    padding: 0.5rem 0.4rem;
}
.table-responsive.messages tbody td.id-user-cell {
    width: 25%;
}
.table-responsive.messages tbody td.id-details-cell {
    width: 50%;
}
.table-responsive.messages tbody td.id-date-cell {
    width: 17%;
}
.table-responsive.messages tbody td .picture-holder{
    width: 2rem;
    height: 2rem;
    min-width: unset;
}
.table-responsive.messages tbody td .picture-holder .picture-content {
    font-size: 0.8rem;
    font-weight: 600;
    min-width: unset;
}

.react-bootstrap-table .messages tbody td .picture-holder .picture-content {
    min-width: unset;
    width: 2rem;
    height: 1.5rem;
}
.table-responsive.messages tbody td .date-details {
    font-size: 0.8rem;
    font-weight: 500;
    color: #6a6d6e;
    letter-spacing: .01rem;
}
.table-responsive.messages tbody td .date-details .date-details-content{
    padding: 0.3rem 0rem;
}
.msg-prv, .prv-txt {
    white-space: nowrap;
    overflow: hidden;
}
.prv-txt {
    display: flex;
}
.prv-txt .sub-txt{
    padding-right: 0.3rem;
}
.prv-txt .msg-txt{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    flex-shrink: 1;
    text-align: left;
    color: #5a5a5a;
}

.new-msg {
    position: absolute;
    display: block;
    top: 12px;
    right: 0.8rem;
    background-color: #fd9f34;
    border-radius: 0.2rem;
    line-height: .75rem;
    padding: 0.1rem 0.3rem;
    font-size: 0.7rem;
    color: #fff;
    font-weight: 600;
}
.--unread .new-msg{
    -webkit-animation: hideAnimation ease-out 3s forwards; 
    animation: hideAnimation  ease-out 3s forwards;
}
@keyframes hideAnimation {
    0%   {opacity: 1;}
    70%  {opacity: 0.8;}
    100% {opacity: 0;}
}
@-webkit-keyframes hideAnimation {
    0%   {opacity: 1;}
    70%  {opacity: 0.8;}
    100% {opacity: 0;}
}
.user-info.--unread .user-info-content h3 {
    font-weight: 500;
    color: #202124;
}
.table-responsive.messages tbody td .user-info-content h3 {
    font-size: 1rem;
    padding: 0.3rem 0rem;
}
.message-details-holder.--unread .sub-txt,
.table-responsive.messages .date-details.--unread{
    font-weight: 500;
    color: #202124;
}
.message-details-holder.--unread .msg-txt{
    font-size: 0.92rem;
    padding: 0.15rem 0rem;
}
/* Message Thread*/
.message-thread .view-layout-content{
    background-color: #f5f5f500;
}
.msg-sub-title-d {
    display: flex;
    padding-top: 1rem;
    position: relative;
}

.message-thread .arrow-left {
    border-top: 2px solid #6b6b6b;
    border-left: 2px solid #6b6b6b;
}
.message-thread .arrow-left::after {
    background-color:  #6b6b6b;
}
.msg-sub-title-d .msg-sub-title{
    font-weight: 400;
    font-size: 1.6rem;
    color: #6b6b6b;
    padding: 0.3rem 0rem;
}
.msg-sub-title-d .msg-sub-title .subj {
    margin-right: 1rem;
    font-weight: 300;
}
.msg-sub-title-d .msg-sub-title .subj-text {
    font-weight: 500;
}
.del-all-btn {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.1rem;
    line-height: 0rem;
    margin-left: 1rem;
    background-color: transparent !important;
    position: absolute;
    right: 4rem;
    opacity: 0.7;
    @include disable-user-select
}
.del-all-btn .icon {
    pointer-events: none;
}
.del-all-btn:hover {
    opacity: 1;
}
.del-all-btn:active {
    opacity: 0.5;
}

.msg-thread {
    padding: 1rem 2rem 5rem 2rem;
}
.msg-thread .thread-cnt {
    display: flex;
    flex-direction: column;
}
.msg-thread .thread-cnt .message-wrp  {
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0.3rem 0rem;
}
.msg-thread .thread-cnt .message-bx  {
    width: 70%;
    padding-left: 1%;
    padding-right: 1%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.msg-thread .thread-cnt .message-bx .msg-inner {
    position: relative;
}
.msg-thread .thread-cnt .message-bx .msg-actions {
    display: none;
}
.msg-thread .thread-cnt .message-bx:hover .msg-actions {
    display: block;
}
.msg-thread .thread-cnt .message-wrp.inbound-msg {
    align-items: flex-start;
}
.msg-thread .thread-cnt .message-wrp.outbound-msg {
    align-items: flex-end;
}
.msg-thread .thread-cnt .message-wrp .msg-cont {
    background: #fff;
    border-radius: 1rem;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
}
.msg-thread .thread-cnt .message-wrp .msg-action-br {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    height: 1.5rem;
    padding: 0rem 0.8rem;
}
.msg-thread .thread-cnt .message-wrp .msg-action-br  .msg-date{
    font-size: 0.85rem;
    font-weight: 500;
    color: #989898;
    position: relative;
    text-transform: uppercase;
}
.msg-thread .thread-cnt .message-bx .read-receipt {
    position: absolute;
    bottom: -1.5rem;
    right: 0rem;
    pointer-events: none;
}
.msg-thread .thread-cnt .message-bx .read-receipt-indicator {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    width: 1.3rem;
    opacity: 0.6;
    pointer-events: none;
}
.msg-thread .thread-cnt .message-bx .read-date {
    opacity: 0.6;
    color: #c5c5c5;
    font-size: 0.7rem;
    font-weight: 500;
    margin-right: 1.5rem;
}
.msg-action-br .arrow-btn  {
    position: relative;
    padding: 0rem;
    width: 1.1rem;
    height: 1.1rem;
    line-height: 0.8px;
    margin-right: 0.2rem;
    top: -3px;
    background-color: transparent;
}

.msg-action-br .arrow-btn:hover {
    background-color: #e6e6e6 !important;
}
.msg-action-br .arrow-btn:active {
    background-color: #d4d3d3 !important;
}

.msg-action-br .arrow-btn .arrow-indicator {
    border: solid #a8a8a8;
    border-width: 0 2px 2px 0;
    display: block;
    width: 8px;
    height: 8px;
    margin: 0px auto;
    position: relative;
    top: -1.5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.msg-action-br .msg-meta-data {
    border: 1px solid #eeeeee;
    max-width: 50%;
    min-width: 300px;
    position: absolute;
    left: 0rem;
    z-index: 9;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 2px 8px #00000011;
    display: none;
}
.msg-action-br .arrow-btn:active + .msg-meta-data  {
    display: block;
}
.msg-action-br .wrap {
    padding: 0.5rem;
    display: flex;
    text-transform: initial;
}
.msg-action-br .wrap .l-side{
    width: 4rem;
}
.msg-action-br .wrap .meta-label{
    display: block;
    text-align: right;
}
.msg-action-br .wrap .r-side .meta-value {
    padding-left: 0.5rem;
    color: #484848;
}
.msg-action-br .wrap .r-side .meta-value .rpt{
   display: inline-block;
   margin-right: 0.2rem;
   border: 1px solid #dddddd;
   padding: 1px 6px;
   line-height: 0.9rem;
   border-radius: 16px;
   padding-bottom: 2px;
}


.msg-thread .thread-cnt .message-wrp .msg-actions button{
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.1rem;
    line-height: 0rem;
    margin-left: 1rem;
    background-color: transparent;
}
.msg-thread .thread-cnt .message-wrp .msg-actions button .icon{
    opacity: 0.8;
}
.msg-thread .thread-cnt .message-wrp .msg-sender{
    display: flex;
    margin-bottom: 1rem;
    align-content: center;
}
.msg-thread .thread-cnt .message-wrp .msg-sender .picture-holder{
    width: 2.5rem;
    height: 2.5rem;
    min-width: unset;
    margin-right: 0.6rem;
}
.msg-thread .thread-cnt .message-wrp .msg-sender .picture-content{
    font-size: 1rem;
}
.msg-thread .thread-cnt .message-wrp .msg-sender .sender{
    font-size: 1rem;
    font-weight: 500;
    padding: 0.3rem 0.2rem;
}
.msg-thread .thread-cnt .message-wrp .rply-tag{
    position: absolute;
    top: 0.5rem;
    right: 0.8rem;
    display: flex;
    font-size: 0.8rem;
    opacity: 0.4;
}
.msg-thread .thread-cnt .message-wrp .rply-tag .icon{
    width: 1.2rem;
    display: inline-block;
    margin-right: 0.4rem;
}

.msg-thread .thread-cnt .message-wrp .msg-body p{
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
}

.msg-thread .thread-cnt .message-wrp .msg-body{
    position: relative;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.cmpe-lyr {
    border: 1px solid black;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9;
}

.cmpe-iL {
    position: absolute;
    bottom: 0;
    right: 0;
}
.cmpe-bL {
    float: right;
}

.cmpe-wL {
    width: 605px;
    height: 30.5rem;
    order: 1;
    position: relative;
}

.fL { }
.cmpe-oN {
    visibility: hidden;
}

.cmpe-oN.show {
    visibility: visible;
}

.cmpe-ED {
    width: 95%;
    bottom: 0;
    position: absolute;
}
.CBx{
    background: #fff;
    border: 1px solid rgb(222, 223, 224);
    overflow: hidden;
    width: 100%;
    min-height: 30rem;
    box-shadow: 0 -4px 10px 1px rgba(0, 0, 0, 0.10);
    border-radius: 1rem;
}
.cmpe-bL.--minimize .cmpe-wL {
    height: 3rem;
    width: 300px;
}
.cmpe-bL.--minimize .CBx {
    min-height: 3rem;
}
.cmpe-bL.--minimize .cCt-inner{
    height: 2.6rem;
}

.Ac-bar {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.6rem;
}
.Ac-bar  button {
    padding: 0rem;
    margin-left: 0.2rem;
    color: #5a5a5a;
    background-color: transparent;
    width: 1.7rem;
    height: 1.7rem;
}
.Ac-bar  button .icon {
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
}

.Ac-bar  button .dash.icon {
    margin: auto;
    width: 8px;
    height: 3px;
    background-color: #979797;
}
.Ac-bar  button .xIcon {
    position: relative;
    margin: 0.4rem auto;
}
.Ac-bar  button .HLine {
    position: absolute;
    width: 13px;
    height: 3px;
    background-color: #979797;
    top: 5px;
    left: 0px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}
.Ac-bar  button .VLine {
    position: absolute;
    width: 3px;
    height: 13px;
    left: 5px;
    top: 0px;
    background-color: #979797;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.Ac-bar  button:hover, .Ac-bar  button:focus  {
    background-color: #e6e6e6 !important;
    color: #5a5a5a  !important;
}

.Ac-bar .Wi-Nme {
    font-weight: 500;
    color: #979797;
    padding-left: 0.6rem;
}

.sender-El{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -2px 0!important;
    width: 100%;
}

.eL-inner {
    width: 100%;
    padding: 0rem 1rem;
}
.eL-inner  table{
    width: 100%;
}
.eL-inner table .tr-El {
    border-bottom: 1px solid #ebecee;
    width: 100%;
    display: flex;
}
.eL-inner table .tr-El td {
    padding: 0.4rem 0rem;
    color: #999999;
    width: 100%;
}
.eL-inner table .tr-El td:first-child {
    width: 4rem;
}
.eL-inner table .tr-El.to td:first-child{
    width: 1.3rem;
    display: flex;
    margin: 0;
}
.eL-inner table .tr-El.to td:last-child{
    width: 98%;
}
.sender-Itm {
    border: 1px solid rgb(227, 228, 230);
    border-radius: 2rem;
    display: flex;
    padding: 0.1rem 0.2rem;
    height: 1.5rem;
    line-height: 1.2rem;
    color: rgb(95, 95, 95);
    cursor: default;
    margin: 0.15rem 0.14rem;
    font-weight: 500;
}
.sender-Itm:hover {
    background-color: #f8f9fa;
    box-shadow: 0px 2px 2px #20222413;
}
.sender-Itm span {
    font-size: 0.9rem;
    font-weight: 500;
    padding-left: 0.4rem;
    line-height: 1.1rem;
}
.sender-Itm button {
    padding: 0.1rem;
    font-size: 0.8rem;
    width: 18px;
    height: 18px;
    line-height: 0.8rem;
    border-radius: 50%;
    background: transparent;
    color: #a0a0a0;
    margin-top: 1px;
    font-weight: 500;
    margin-left: 0.1rem;
}
.sender-El textarea {
    height: 2rem;
    outline: none;
    border: none;
    margin-bottom: 0.2rem;
    margin-left: 0.3rem;
    resize: none;
    flex: 1 0 auto;
    color: #484848;
    font-weight: 500;
    letter-spacing: 0.02rem;
}
.subj-El #subj-field {
    width: 100%;
    outline: none;
    border: none;
    color:#484848;
}
.body-El {
    padding-top: 0.1rem;
}
.msg-body {
    margin-bottom: 1rem;
    max-height: 20rem;
}
.body-text {
    min-height: 18rem;
    height: fit-content;
    max-height: 20rem;
    overflow-y: scroll;
    border: none;
    outline: none;
}

.fotr-actions .act-wrap span {
    flex: 0 0 0%;
}
.fotr-actions .act-wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
}
.fotr-actions .act-wrap .send-btn {
    width: auto;
    display: block;
    border-radius: 0.6rem;
    background: #a7a7a7;
    padding: 0.6rem 1rem;
    font-weight: 600;
    line-height: normal;
}
.fotr-actions .act-wrap .send-btn:hover, 
.fotr-actions .act-wrap .send-btn:active  {
    background: #2c8ddd;
}

.fotr-actions .act-wrap .send-btn .icon {
    width: 1.1rem;
    display: inline-block;
    margin-right: 0.2rem;
}
.fotr-actions .act-wrap .send-btn .icon img {
    filter: invert(100);
}
.discard-btn {
    width: 1.8rem;
    height: 1.8rem;
    padding: 0.2rem;
    line-height: normal;
    background: transparent;
    margin-top: 1rem;
}
.fotr-actions  .discard-btn:hover, .fotr-actions  .discard-btn:focus  {
    background-color: #e6e6e6 !important;
    color: #5a5a5a  !important;
}

.fotr-actions .act-wrap .discard-btn .icon img {
    width: 1rem;
}

.iLne-cmpe {
    margin: 2rem 2%;
}

.iLne-cmpe .CBx{
    border: 1px solid #f5f5f5;
    box-shadow: unset;
    min-height: 8rem;
}
.iLne-cmpe .CBx:hover{
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.062);
}
.iLne-cmpe .body-text {
    min-height: 8rem;
}
.iLne-cmpe .reply-to{
    display: flex;
}
.iLne-cmpe .reply-to .icon{
    width: 1rem;
    margin-right: 0.5rem;
    opacity: 0.4;
}
.iLne-cmpe .eL-inner table .tr-El{
    border: none;
}
.iLne-cmpe .eL-inner table .tr-El td{
    width: 100%;
}
.iLne-cmpe .reply-to .recipient{
    display: flex;
}
.iLne-cmpe .reply-to .recipient .rpt-text {
    color: #7a7a7a;
    font-weight: 500;
}
.iLne-cmpe .reply-to .recipient .picture-holder {
    width: 1.8rem;
    height: 1.8rem;
    min-width: unset;
    margin-right: 0.5rem;
}
.iLne-cmpe .reply-to .recipient .picture-holder .picture-content {
    font-size: 0.7rem;
    font-weight: 600;
    color: #707070;
}
.msg-thread .reply-btn {
    display: block;
    width: auto;
    border: 1px solid #dddddd !important;
    background: transparent;
    color: #5a5a5a !important;
    line-height: normal;
    border-radius: 0.5rem;
    padding: 0.52rem 1rem;
    margin: 0rem 0rem 0rem 0rem;
}
.msg-thread .reply-btn:focus{
    background: transparent!important;
}
.msg-thread .reply-btn:active{
    background: #dddddd !important;
}
.msg-thread .reply-btn .icon {
    position: relative;
    bottom: 0.1rem;
    margin-right: 0.6rem;
}
.msg-thread .reply-btn .icon img {
    width: 1rem;
    opacity: 0.6;
}

.tox-tinymce * {
    border: none !important;
}
.tox-tinymce {
    border: none !important;
    height: 20rem !important;
}
.tox-notification { display: none !important }

.body-inner div:first-child {
    border: none !important;
    position: relative;
    display: flex;
}
.body-inner div:first-child > div:first-child{
    position: relative;
    top: 0;
    margin: 0.1rem auto;
    width: 100%;
    background-color: #ffffff !important;
    padding: 2px 4px;
    border-radius: 3px;
    align-items: center !important;
    vertical-align: middle !important;
    box-shadow: 0 1px 4px #20212400;
    border: 1px solid #ffffff !important;
    z-index: 5;
}
.body-inner div:first-child div:first-child button{
    width: 32px !important;
    height: 30px !important;
    color: #b4b4b4 !important;
    border-radius: 3px;
    font-size: 1.2rem;
    transition: color 0.1s linear;
}
.body-inner div:first-child div:first-child:hover button{
    color: #616161 !important;
}
.body-inner div:first-child div:first-child select{
    border: none !important;
    color: #818181 !important;
}
.body-inner #editor{
   height: fit-content;
   max-height: 18rem;
   min-height: 18rem;
   width: 100%;
   direction: ltr;
   overflow-y: overlay;
   color: rgb(82, 82, 82);
}
.body-inner #editor  ul{
    list-style: disc !important;
 }
 .body-inner #editor  ul li{
    list-style-position: inside;
    margin-left: 1rem;
 }
.iLne-cmpe .body-inner #editor {
    max-height: 12rem;
    min-height: 12rem;
}