//Form Setting Styles

.add-sec.section  {
    margin-top: 2rem;
}

.add-sec.section button {
    margin: 0rem auto 1rem auto;
    background-color: #2885bd;
    padding: 0.6rem 2.5rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    font-weight: 600;
}
.add-sec.section button[disabled] {
    background-color: #d6d6d6;
}
.add-sec.section button[disabled] .icon img {
    filter: invert(40%);
}

.add-sec.section button .icon {
    position: relative;
    top: -0.15rem;
    margin-right: 0.2rem;
}
.add-sec.section button .icon img {
    width: 1.2rem;
    filter: invert(100%);
}
.add-sec.section p {
    color: #6f7375;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 2rem;
 }
.form-itm-sec.section {
    margin-top: 2rem;
}
.form-itm-sec.section h1 {
    font-size: 1.5rem;
    font-weight: 600;
}
.form-itm-sec.section .inner-cont {
    margin-top: 2rem;
}
.form-itm-sec .sec-title {
    color: #2e2f30;
}
.form-item {
    margin: 0.5rem;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-top: 3px solid #2885bd;
    border-radius: 0.2rem;
}
.form-item .form-wrp {
    padding: 2rem;
}

.form-wrp .frm-name h2{
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.form-wrp .frm-cont .ftre-count {
    font-size: 3rem;
    font-weight: 600;
    margin: 1rem 0rem;
}
.form-wrp .frm-cont .ftre-count span {
    font-size: 1.4rem;
    font-weight: 400;
}
.form-wrp .frm-cont  p {
    color: #5d5e5f;
    font-weight: 500;
    font-size: 1rem;
 }
.form-wrp .frm-cont .frm-typ {
    font-size: 1.2rem;
    margin: 0.5rem 0rem 2rem 0rem;
    color: #2885bd;
}
.form-wrp .frm-actions   {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
}
.form-wrp .frm-actions button  {
    display: inline-block;
    background-color: transparent;
    border: none;
    color: rgb(92, 92, 92) !important;
    font-size: 0.9rem;
    padding: 0.2rem 0.4rem;
    margin: 0rem 0.8rem;
}
.form-wrp .frm-actions button:focus,
.form-wrp .frm-actions button:active  {
    background-color: rgb(233, 233, 233) !important;
}

.form-wrp .frm-actions button img {
    width: 1rem;
    position: relative;
    top: -0.1rem;
}

.form-settings__tab{
    position: sticky;
    top: 0%;
    background-color: #f7f7f9;
    z-index: 2;
    border-bottom: 1px solid rgb(224, 224, 224);
}

.pad-top{
    padding-top: 1.5rem;
}

.form-group-item{
    padding-bottom: 1.5rem;
}

.sle-f .checkbox--resize{
    height: 50px;
    display: flex;
    align-items: center;
}

.checkbox--resize .checkmark{
    top: -5px;
}

//Form Create Edit Styles
.crte-edt-wrap {
    width: 100%;
    margin: 2rem auto 4rem auto;
}

.crte-edt-wrap .fields-list {
    margin: 1rem 0rem;
}
.crte-edt-wrap .fields-list .ftre-item{
    background-color: #fff;
    margin-bottom: 0.2rem;
    border: 1px solid #f5f5f5;
    border-radius: 0.2rem;
    position: relative;
}
.crte-edt-wrap .comps-form.--editing {
   box-shadow: 0 0 0 2px #54a4ffaf; 
   margin-bottom: 0.4rem;
}
.crte-edt-wrap .fields-list .ftre-item .typ-col {
    flex: 0 0 8rem;
}
.crte-edt-wrap .fields-list .ftre-item  .cl {
    color: #a5a9aa;
}
.crte-edt-wrap .fields-list .ftre-item  .fk {
    color: #646768;
    font-size: 0.8rem;
}
.crte-edt-wrap .fields-list .ftre-item .typ-col .ficon  {
    opacity: 0.5;
}
.crte-edt-wrap .fields-list .ftre-item .cont{
   padding: 0.5rem 0.8rem 0.7rem 0.8rem;
}
.crte-edt-wrap .fields-list .ftre-item .inner-cont {
    display: flex;
    justify-content: space-between;
}
.crte-edt-wrap .fields-list .ftre-item .inner-cont .flbl{
    font-size: 1.3rem;
    font-weight: 500;
    color: #545657;
}
.crte-edt-wrap .fields-list .ftre-item .ficon img{
    width: 3.5rem;
    height: 1.8rem;
    pointer-events: none;
}
.crte-edt-wrap .fields-list .ftre-item .ftr-actions {
    position: absolute;
    bottom: 0.1rem;
    left: 0rem;
    display: none;
}
.crte-edt-wrap .fields-list .ftre-item:hover .ftr-actions,
.crte-edt-wrap .fields-list .ftre-item.--editing .ftr-actions {
    display: block;
}

.crte-edt-wrap .fields-list .ftre-item .ftr-actions button{
    padding: 0.3rem;
    margin: 0rem 1rem 0rem 0.3rem;
    background-color: transparent;
}

.crte-edt-wrap .fields-list .ftre-item .ftr-actions img{
    width: 1.1rem;
    opacity: 1;
}
.crte-edt-wrap .fields-list .ftre-item .ftr-actions button:active img{
    opacity: 0.6;
}
.comps-form {
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 0.2rem;
}
.comps-form .inner {
    padding: 0.5rem 0.8rem 1.2rem 0.8rem;
}
.comps-form .tle {
    font-weight: 500;
}
.comps-form .frm-fields {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.8rem;
}
.crte-edt-wrap .name-field{
    font-size: 2rem;
    color: #6e6e6e;
    width: auto !important;
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    background: transparent;
    display: inline-block;
    cursor: text !important;
    min-width: 0.5rem;
}
.crte-edt-wrap .name-field.error{
    color: #df1414;
    border-bottom: 2px solid #df1414;
}

.crte-edt-wrap .name-field:focus {
    border-bottom: 2px solid #54a4ff;
    color: #494949;
}
.comps-form .frm-fields > div {
    margin: 0rem 0.2rem;
}
.comps-form .frm-fields .sle-f{
    flex: 0 0 30%
}
.comps-form .frm-fields .ip-f{
    flex: 0 0 45%
}
.comps-form .frm-fields .act-f{
    flex: 0 0 20%
}
.comps-form .frm-fields .act-f .delete-btn {
    background: transparent;
    margin: 0rem 0.5rem;
    color: #F43825 !important;
}
.comps-form .frm-fields .act-f .delete-btn img {
    width: 1.6rem;
}
.comps-form .frm-fields .sle-f .select-container .select-control {
    border-radius: 0.3rem;
}
.comps-form .frm-fields .sle-f .select-container.ip-select-field .selected-option {
    border-radius: 0.3em 0rem 0rem 0.3rem;
}
.comps-form .frm-fields .sle-f  .selected-option.--default {
    padding: 0.1rem  0.6rem;
}
.comps-form .frm-fields .sle-f  .select-container .select-placeholder-title {
    padding: 0.1rem  0.5rem;
    margin: 0rem;
}
.comps-form .frm-fields .select_options--default .template-option{
    padding: 0.3rem  0.6rem;
}
.comps-form .frm-fields .ip-f input{
    padding: 0.5rem 1rem;
    height: auto;
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;
}
.comps-form .frm-fields  .act-f button{
    background-color: #e9e9e9;
    color: rgb(110, 110, 110) !important;
    padding: 0.55rem 1rem;
}
.comps-form .frm-fields  .act-f button:active {
    background-color: #dadbdb !important;
}

.crte-edt-wrap .add-new-wrap {
    display: block;
    width: 100%;
    margin: 1rem auto;
}
.crte-edt-wrap .add-new-wrap button {
    border: 1px solid rgb(224, 224, 224) !important;
    display: block;
    width: 100%;
    padding: 2.2rem 1rem;
    background-color: transparent;
    font-weight: 500;
    color: rgb(80, 80, 80) !important;
}
.crte-edt-wrap .add-new-wrap button:hover{
    background-color: #f5fcff;
    border: 1px solid #48c0f3 !important;
}
.crte-edt-wrap .add-new-wrap button:active {
    border: 1px solid #48c0f3 !important;
    background-color: #e7f4fa !important;
}
.crte-edt-wrap .add-new-wrap button img {
    width: 1.5rem;
    height: 1.5rem;
   opacity: 0.4;
    margin-right: 0.7rem;
}

/*Settings-Main*/
body {

}
.settings-main {
    --side-bar-with: 32rem;
}
.settings-main .setting-opt-list {
    width: var(--side-bar-with);
    height: calc(100vh - 7.5rem);
    background: #f6f7f9;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    justify-content: space-between;
}

.settings-main .setting-opt-list .title{
    opacity: 0.6;
}
.settings-main .setting-pane-hldr {
    flex: 0 0 100%;
    margin-left:  var(--side-bar-with);
}
.settings-main .setting-items .nav-item {
    width: 100%;
}
.settings-main .setting-items .nav-item .nav-link{
    width: 100%;
    border-radius: 0.4rem;
    padding: 0.3rem 0.5rem;
    margin: 0.05rem 0rem;
    color: #0c679c;
    font-weight: 400;
}
.settings-main .setting-items .nav-item .nav-link:hover{
    background-color: #eff1f3;
}
.settings-main .setting-items .nav-item .nav-link.active{
    background-color: #ebeef1;
    font-weight: 600;
}
.settings-main .setting-grp {
    margin-bottom: 2rem;
}
.settings-main .settings-pane {
    margin-top: 2rem;
}
.settings-main .title-box {
    display: flex;
    justify-content: space-between;
}
.settings-main .settings-pane .title-box .col-l{
    display: flex;
    align-items: center;
}
.settings-main .settings-pane .title-box .col-l .loader-wrap {
    margin-right: 0.6rem;
    height: 1.8rem;
}
.settings-main .settings-pane .title-box .col-l .loader-wrap .lds-roller {
    top: -1rem;
}
.settings-main .title-box .save-sttg-btn{
    padding: 0.2rem 1.1rem !important;
    line-height: 0.9rem !important;
    font-size: 1.2rem;
    height: 2.3rem !important;
    border-radius: 0.5rem;
}

.settings-main .setting-grp .grp-title {
    font-size: 1.2rem;
    color: #475f6e;
}
.setting-pane-hldr {
    border: 1px solid transparent;
}
.settings-main .add-btn-item {
    font-size: 1.2rem;
    opacity: 1;
    color: #5d5e5f !important;
}
.settings-main .add-btn-item:active {
    opacity: 0.7;
}
.settings-main input[type="text"],
.settings-main input[type="number"]{
    border: 1px solid rgb(219, 219, 219) !important;
}
.settings-main input {
   background-color: transparent;
}
.settings-main .setting-info  {
    margin: 1rem 0.5rem;
}
.settings-main .setting-info p {
    font-size: 0.9rem;
    color: #5d5e5f;
    margin-bottom: 0.6rem;
}
.settings-main .section {
    margin: 1.5rem auto;
}
.settings-main .loader-wrap {
    display: inline-block;
}
.settings-main .grp-sec.section  {
    padding: 1rem 0rem;
   
}
.settings-main .grp-sec.section  .section-cont{
    margin-top: 1rem;
}

.settings-main .grp-sec.section  .section-cont .switch-group{
    width: 6rem;
    display: inline-block;
}
.settings-main .grp-sec.section  .section-cont .field-w .form-text{
    display: inline-block;
}
.settings-main .grp-sec.section  .section-cont .switch-group{
    width: 6rem;
    display: inline-block;
}
.settings-main .grp-sec.section  .title-wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eff0f1;
}
.settings-main .grp-sec.section  .title-wrap  .edit-tgr  {
    border-radius: 0.3rem;
    line-height: normal;
    padding: 0.1rem 0.3rem;
}
.settings-main .grp-sec.section  .title-wrap  .edit-tgr img {
    width: 1rem;
    opacity: 0.8;
    
}
.settings-main .grp-sec.section .sec-title{
    font-size: 1rem;
    text-transform: capitalize;
    color: rgb(80, 83, 85);
    font-weight: 600;
    padding-bottom: 0.5rem;
   
}
.settings-main .grp-sec.section  .section-cont .field-set{
    display: flex;
    margin: 0.1rem 0rem;
    padding: 0.5rem 0.4rem;
    border-bottom: 1px solid #f8f8f8;
}
.settings-main .grp-sec.section  .section-cont .field-set .label-w{
    width: 13rem;
    font-size: 1rem;
    color: #4b4b4b;
}
.settings-main .grp-sec.section  .section-cont .field-set .field-w{
    padding: 0rem 1.5rem 0rem 0.8rem;
    width: 100%;
}
.settings-main .switch-pill.inline{
    left: auto;
    right: auto;
}

.settings-main .field-set  input[type="text"],
.settings-main .field-set  input[type="number"] {
    border: 1px solid transparent !important;
}

.settings-main .field-set:hover  input[type="text"],
.settings-main .field-set:hover  input[type="number"] {
    border: 1px solid rgb(219, 219, 219) !important;
}
.settings-main  input:focus {
    background: rgba(255, 255, 255, 0.685) !important;
}
//Labcode Settings 
.lab-code-pane .lab-code-list .lab-code-item{
    display: inline-block;
    margin: 0.8rem 0.5rem;
    max-width: 13rem;
    position: relative;
}
.department-pane .departments-list .department-item {
    width: 20rem;
}
.lab-code-pane .lab-code-list .lab-code-item .lab-code,
.department-pane .departments-list .department-item .department {
    display: block;
    border-radius: 0.5rem;
    padding: 0.6rem 0.8rem;
    background-color: #f5f5f5;
    position: relative;
}
.lab-code-pane .lab-code-list .lab-code-item.--new .lab-code,
.department-pane .departments-list .department-item.--new .department {
    background-color: #f2f9fd;
}

.lab-code-pane .lab-code-list .lab-code-item .form-group,
.department-pane .departments-list .department-item .form-group {
    margin-bottom: 0.5rem;
}
.lab-code-pane .lab-code-list .lab-code-item label,
.department-pane .departments-list .department-item label {
    font-size: 0.9rem;
    color: #2e2f30;
}
.lab-code-pane .lab-code-list .lab-code-item input,
.department-pane .departments-list .department-item input {
    background: transparent;
    border-color: transparent !important;
    font-weight: 500;
    text-transform: capitalize;
}
.lab-code-pane .lab-code-list .lab-code-item input:hover,
.lab-code-pane .departments-list .department-item input:hover {
    background: transparent;
    border-color: rgb(219, 219, 219) !important;
}
.lab-code-pane .lab-code-list .lab-code-item .lab-code-ft,
.department-pane .departments-list .department-item .department-ft {
    margin-top: 0.3rem;
}

.settings-main a.remove .icon {
    display: inline-block;
    width: 1rem;
    position: relative;
    top: -2px;
}
.settings-main a.remove{
    color: #494949;
}

//Code Sheet Pane

.code-sheet-pane .code-sheet-item {
    margin-bottom: 1rem;
}
.code-sheet-pane .code-sheet {
    padding: 0.5rem 1.2rem;
    border: 1px solid #f7f7f7;
    background-color: #f9f9f9;
    border-radius: 0.3rem;
    position: relative;
}

.code-sheet-pane .code-sheet .ip-group {
    display: flex;
}
.code-sheet-pane .code-sheet .ip-group .form-group  {
    margin: 0rem 0.2rem;
}
.code-sheet-pane .code-sheet .form-group {
    margin-bottom: 0.4rem;
}
.code-sheet-pane .code-sheet .form-group label {
    color: #8a8b8b;
}
.code-sheet-pane .code-sheet .form-group input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0.1rem;
    height: 1.8rem;
}
.code-sheet-pane .code-sheet .form-group textarea {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0.1rem;
    background-color: transparent;
    height: fit-content;
}
.code-sheet-pane .code-sheet .remove {
    position: absolute;
    top: 0.5rem;
    right: 0.8rem;
    display: none;
}
.code-sheet-pane .code-sheet:hover .remove {
    display: block;
}

//Taxes Pane
.tax-setting-pane .tax-code-item {
    display: inline-block;
    border: 1px solid rgb(234, 234, 235);
    max-width: 15rem;
    margin: 0.4rem;
    border-top: 2px solid #eaedf0;
    padding: 0.5rem 0.8rem;
    border-radius: 0.3rem;
    position: relative;
}
.tax-setting-pane .tax-code-item .form-group {
    position: relative;
}

.tax-setting-pane .tax-code-item .ip-checkbox {
    position: absolute;
    bottom: 1px;
    right: 12px;
}
.tax-setting-pane .tax-code-item[type='PY'] {
    border-top: 2px solid #5c7c8f;
}
.tax-setting-pane .tax-code-item[type='GS'] {
    border-top: 2px solid #0895f0;
}
.tax-setting-pane .tax-code-item .tax  input{
    border: 1px solid transparent !important;
}
.tax-setting-pane .tax-code-item .tax  input[name='percent']{
    font-size: 1.2rem;
    font-weight: 500;
}
.tax-setting-pane .tax-code-item .tax  input[disabled] {
    background-color: transparent;
}
.tax-setting-pane .tax-code-item .tax:hover input{
    border: 1px solid #ecedee !important;
}
.tax-setting-pane .tax-code-item .tax:hover input[disabled]{
    border: 1px solid transparent !important;
}
.tax-setting-pane .tax-code-item .threshold-details{
    position: absolute;
    left: 0.5rem;
    bottom: -1.2rem;
    color: #727272;
    font-size: 0.9rem;
}
.tax-setting-pane .sec-title {
    margin: 1rem;
    font-size: 1.2rem;
    margin-left: 1rem;
}
.tax-setting-pane .tax-code-item .tax-type {
    font-size: 1rem;
    font-weight: 400;
}
.tax-setting-pane .tax-code-item:hover .tax-type {
    border: 1px solid #ecedee !important;
}
.tax-setting-pane .tax-code-item:hover {
    box-shadow: 0 1px 5px #2e2f3018;
}
.tax-setting-pane .tax-code-item .tax-ft {
    opacity: 0;
}
.tax-setting-pane .tax-code-item:hover .tax-ft {
    opacity: 1;
}


//Services Pane
.services-setting-pane .services-item {
    border: 1px solid rgb(234, 234, 235);
    border-radius: 0.3rem;
    height: 100%;
    margin-bottom: 0.1rem;
}
.services-setting-pane .services-item .service {
    display: flex;
    height: auto;
}
.services-setting-pane .services-item .service .col-l{
    flex: 0 0 10rem;
    justify-content: center;
    align-items: center;
    background-color: #f8f9faad;
}
.services-setting-pane .services-item .service .col-r{
    padding: 0.4rem 1.5rem;
    width: 100%;
}
.services-setting-pane .services-item .service .col-l .ser-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.services-setting-pane .services-item .service .col-l .ser-icon img{
    opacity: 0.2;
    width: 4rem;
    display: block;
    margin: 0rem auto;
}
.services-setting-pane .services-item:hover {
    box-shadow: 0 1px 5px #2e2f3018;
}
.services-setting-pane .services-item .service .form-group {
    margin-bottom: 0.4rem;
}
.services-setting-pane .services-item .service label {
    font-size: 0.8rem;
    margin: 0rem;
}
.services-setting-pane .services-item .service  input{
    border: 1px solid transparent !important;
    height: 2rem;
    padding: 0rem 0.5rem;
}
.services-setting-pane .services-item .service  input[name="price"]{
    font-size: 1.3rem;
}
.services-setting-pane .services-item .service:hover  input{
    border: 1px solid #ecedee !important;
}
.services-setting-pane .services-item .ser-ft {
    text-align: right;
    opacity: 0;
}
.services-setting-pane .services-item:hover .ser-ft {
    opacity: 1;
}