.client-loader {
    padding: 10% 0rem;
}
table.clients-table thead tr th{
    width: auto;
}
table.clients-table .th-userinfo-cell , table.clients-table tbody td.id-userinfo-cell{
    width: 30%;
}
table.clients-table .th-clientinfo-cell , table.clients-table tbody td.id-clientinfo-cell{
    width: 25%;
}
table.clients-table .th-labcode-cell , table.clients-table tbody td.id-labcode-cell{
    width: 10%;
}
table.clients-table .th-details-cell , table.clients-table tbody td.id-details-cell{
    width: 35%;
}
table.clients-table .id-userinfo-cell .user-info {
    display:  flex;
}
table.clients-table .id-userinfo-cell .user-info .picture-holder {
    margin-right: 0.8rem;
}
table.clients-table tbody td.id-details-cell .auth-snippet.role {
    width: auto;
    height: auto;
    margin-right: 0.4rem;
}
table.clients-table tbody td.id-clientinfo-cell .info {
    font-size: 0.9rem;
    color: #a0a0a0;
}

.title-d .title{
    color: #2b2b2b;
    font-size: 1.5rem;
}
.top-client-section {
    margin: 1rem auto;
}
.top-clients-wrap { }
.client-list {
    display: flex;
}
.client-list .client-item{
    flex: 0 1 20%;
    display: inline-block;
    margin-right: 0.5rem;
    max-width: 14rem;
}
.client-list .client-widget{
    text-align: center;
    padding: 1rem;
    height: auto;
    min-height: 11rem;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 0.6rem;
    cursor: pointer;
}
.client-list .client-widget:hover{
    box-shadow: 0px 2px 6px #00000013;
}
.client-widget .client-picture {
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    margin: 0rem auto 0.5rem auto;
    pointer-events: none;
    border: 1px solid #f3f3f3;
}
.client-widget .client-picture img{
    text-align: center;
    display: block;
    max-width: 4rem;
    max-height: 4rem;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.client-widget .client-details h3 {
    font-size: 0.9rem;
    color: #3b3b3b;
}
.client-widget .client-details .type {
    font-weight: 500;
    color: #2793f8;
}
.client-widget .client-details .actno {
    font-size: 0.85rem;
    color: #a0a0a0;
    font-weight: 500;
}
.account-balc {
    display: flex;
}
.account-balc .ratio-widget.balc .ratio-line.orange .inner-bar {
    background-color: #f7931e;
}
.account-balc .ratio-widget.balc .left-count {
    color: #f7931e;
}
.account-balc .ratio-widget .ratio-count {
    font-size: 1.2rem;
}
.account-balc  .ratio-widget {
    flex: 0 0 45%;
    margin: 0rem 1%;
}

.account-balc  .ratio-widget .ratio-holder{
    border: 1px solid #f0f0f0;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem 1rem 1rem;
}
.account-balc  .ratio-widget .ratio-holder label{
    text-transform: uppercase;
    font-size: 0.9rem;
    color: #3b3b3b;
}
.acnt-section, .wkspc-section {
    margin-top: 2rem !important;
}
.sec-content .info-line {
    font-size: 1.3rem;
    color: #a0a0a0;
}
.sec-content .info-line {
    font-size: 1.3rem;
    color: #a0a0a0;
    display: flex;
    margin-top: 0.6rem;
    margin-left: 1rem;
}
.sec-content .info-line .label {
    flex: 0 0 25%;
}
.sec-content .info-line .value {
    color: #353535;
}

.sumry-list {
    
}
.sumry-list  .sumry-item {
    display: inline-block;
    text-align: center;
    margin: 1rem 2rem;
    color: #3b3b3b;
}
.sumry-list  .sumry-item .count {
    font-size: 1.7rem;
    font-weight: 700;
    color: #2793f8;
    letter-spacing: -0.01rem;
}
.sumry-list  .sumry-item h4{
    font-size: 1rem ;
    font-weight: 400;
}

.client-group {
    padding-left: 1.5rem;
}

.client-list .act-section .sec-content {
    margin-top: 1.5rem;
}