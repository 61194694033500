.slim {
    height: 0.3rem;
}

.progress {
    display: flex;
    height: 2rem !important;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #f3f4f5 !important;
    border-radius: 0rem !important;
}
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    height: inherit !important;
    border-right: 2px solid #fff !important;
    background-color: #007bff;
    transition: width 0.6s ease;
}